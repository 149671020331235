import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ActivityLogInformationListWithCount = {
  __typename?: 'ActivityLogInformationListWithCount';
  count: Scalars['Int']['output'];
  records: Array<User>;
};

export type AddStudentInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
};

export type AddStudentType = Node & {
  __typename?: 'AddStudentType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type Address = Node & {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Country;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  postCode: Scalars['String']['output'];
  state?: Maybe<State>;
  stateId?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AddressCreateInput = {
  city: Scalars['String']['input'];
  countryId: Scalars['ID']['input'];
  postCode: Scalars['String']['input'];
  stateId?: InputMaybe<Scalars['ID']['input']>;
  street: Scalars['String']['input'];
};

export type AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type Assignments = Node & {
  __typename?: 'Assignments';
  answer?: Maybe<Scalars['String']['output']>;
  answerExplanation?: Maybe<Scalars['String']['output']>;
  assignments?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  homeworkStatus: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isChecked?: Maybe<Scalars['Boolean']['output']>;
  responseType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type BluebookStudentAnalyticstWithCount = {
  __typename?: 'BluebookStudentAnalyticstWithCount';
  count: Scalars['Int']['output'];
  records: Array<UserTestScore>;
};

export type Classes = Node & {
  __typename?: 'Classes';
  classCreatedAt?: Maybe<Scalars['String']['output']>;
  classStudents?: Maybe<Array<Tutor>>;
  classTutors?: Maybe<Array<Tutor>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  homework_status: Scalars['String']['output'];
  homeworks?: Maybe<Array<Homework>>;
  id: Scalars['ID']['output'];
  status: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  tutorId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userCreatedBy?: Maybe<User>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ClassesFiltersArg = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ClassesWithCount = {
  __typename?: 'ClassesWithCount';
  count: Scalars['Int']['output'];
  records: Array<Classes>;
};

export type CompleteTestAndSectionScoreCount = {
  __typename?: 'CompleteTestAndSectionScoreCount';
  records: Array<UserTestScore>;
};

export type Country = Node & {
  __typename?: 'Country';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  hasStates: Scalars['Boolean']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  percentOff?: Maybe<Scalars['Int']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type Course = Node & {
  __typename?: 'Course';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  introLesson?: Maybe<Lesson>;
  introLessonId?: Maybe<Scalars['ID']['output']>;
  isTrialAccess: Scalars['Boolean']['output'];
  latestLessons: Array<Lesson>;
  subjects: Array<Subject>;
  testBooks?: Maybe<Array<TestBook>>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type CriteriaManuallyEntryUpdateInput = {
  answerKey?: InputMaybe<Scalars['String']['input']>;
  dsatMaxScore?: InputMaybe<Scalars['Int']['input']>;
  isQuestionFor?: InputMaybe<Scalars['String']['input']>;
  maxScore?: InputMaybe<Scalars['Int']['input']>;
  questionNumber?: InputMaybe<Scalars['Int']['input']>;
  sectionQuestion?: InputMaybe<Scalars['String']['input']>;
  testBookId: Scalars['Int']['input'];
  testId: Scalars['Int']['input'];
};

export type DigitalSat = Node & {
  __typename?: 'DigitalSAT';
  course_id: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  user_gets_book_on_subscription: Scalars['Boolean']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type DigitalSatAnnotationInput = {
  annotation: Scalars['String']['input'];
  testAnswerId: Scalars['ID']['input'];
  userTestId: Scalars['ID']['input'];
};

export type DigitalSatCreateInput = {
  title: Scalars['String']['input'];
};

export type DigitalSatFeedback = Node & {
  __typename?: 'DigitalSatFeedback';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  email: Scalars['Int']['output'];
  examId: Scalars['Int']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
  test?: Maybe<UserTestScore>;
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type DigitalSatFeedbackCount = {
  __typename?: 'DigitalSatFeedbackCount';
  count: Scalars['Int']['output'];
  records: Array<DigitalSatFeedback>;
};

export type DigitalSatFeedbackInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  userTestId?: InputMaybe<Scalars['Int']['input']>;
};

export type DigitalSatInputScoreInput = {
  examDate?: InputMaybe<Scalars['String']['input']>;
  homeworkId?: InputMaybe<Scalars['ID']['input']>;
  inputScore?: InputMaybe<Scalars['String']['input']>;
  mathScore?: InputMaybe<Scalars['Int']['input']>;
  readingWritingScore?: InputMaybe<Scalars['Int']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};

export type DigitalSatQuestionStatusUpdateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DigitalSatStartExamUpdateInput = {
  examId?: InputMaybe<Scalars['String']['input']>;
  isDirectionViewed?: InputMaybe<Scalars['Boolean']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  testId: Scalars['ID']['input'];
  userTestId: Scalars['ID']['input'];
};

export type DigitalSatTestSection = Node & {
  __typename?: 'DigitalSatTestSection';
  courseId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isTrialAccess?: Maybe<Scalars['Boolean']['output']>;
  subjectId: Scalars['ID']['output'];
  subjectTitle: Scalars['String']['output'];
  testBookId: Scalars['ID']['output'];
  testBookTitle: Scalars['String']['output'];
  testId: Scalars['ID']['output'];
  testTitle: Scalars['String']['output'];
  tutorTest: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type DigitalSatTestsScoreCount = {
  __typename?: 'DigitalSatTestsScoreCount';
  count: Scalars['Int']['output'];
  records: Array<UserTestScore>;
};

export type DigitalSatUserTestAnswerUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  isUpdateStrike?: InputMaybe<Scalars['Boolean']['input']>;
  sectionId: Scalars['ID']['input'];
  strike: Scalars['String']['input'];
  testAnswerId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
  userTestId: Scalars['ID']['input'];
};

export type DigitalSatUserTestResultsCreateInput = {
  accomodation?: InputMaybe<Scalars['String']['input']>;
  subjectId: Scalars['ID']['input'];
  testBookId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
};

export type DigitalSatUsersWithCount = {
  __typename?: 'DigitalSatUsersWithCount';
  count: Scalars['Int']['output'];
  records: Array<Tutor>;
};

export type EnterMaunallyQuestionNo = Node & {
  __typename?: 'EnterMaunallyQuestionNo';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  questionNumber?: Maybe<Scalars['Int']['output']>;
  subjectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ExamTimerUpdateInput = {
  breakTimeRemaining?: InputMaybe<Scalars['Int']['input']>;
  breakTimeStart?: InputMaybe<Scalars['Boolean']['input']>;
  remainingSeconds?: InputMaybe<Scalars['Int']['input']>;
  userTestId?: InputMaybe<Scalars['ID']['input']>;
};

export type Faq = Node & {
  __typename?: 'FAQ';
  answer: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type FaqCreateInput = {
  answer: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  question: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type FaqInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type File = Node & {
  __typename?: 'File';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  /** External link location. Mutually exclusive with "path" */
  link?: Maybe<Scalars['String']['output']>;
  /** Path to file location in firebase storage. Mutually exclusive with "link" */
  path?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userHasCompleted: Scalars['Boolean']['output'];
  userHasDownloaded: Scalars['Boolean']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type Homework = Node & {
  __typename?: 'Homework';
  assignments?: Maybe<Array<Assignments>>;
  bluebookTest?: Maybe<Array<DigitalSatTestSection>>;
  classIds?: Maybe<Scalars['String']['output']>;
  classes?: Maybe<Array<Classes>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  homeworkStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isUsed: Scalars['Boolean']['output'];
  lessonStudents?: Maybe<Tutor>;
  lessonVideoStatus?: Maybe<Scalars['String']['output']>;
  lessons?: Maybe<Array<PlaylistLesson>>;
  quizzes?: Maybe<Quiz>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
  statusOfStudent?: Maybe<Scalars['String']['output']>;
  studentIds?: Maybe<Scalars['String']['output']>;
  students?: Maybe<Array<Tutor>>;
  tests?: Maybe<Array<DigitalSatTestSection>>;
  title: Scalars['String']['output'];
  totalResponse?: Maybe<Array<User>>;
  tutorId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userCreatedBy?: Maybe<User>;
  userId: Scalars['ID']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type HomeworkFiltersArg = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HomeworkReportFiltersArg = {
  lessonTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  testName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  videoQuizTitle?: InputMaybe<Scalars['String']['input']>;
};

export type HomeworksWithCount = {
  __typename?: 'HomeworksWithCount';
  completedHomeworkCount: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  pendingHomeworkCount: Scalars['Int']['output'];
  records: Array<Homework>;
};

export type LastUserInput = {
  __typename?: 'LastUserInput';
  answerType?: Maybe<TestSubjectAnswerEntryEnumType>;
  correctQuestionNumbers: Array<Scalars['Int']['output']>;
  essayAnalysisScore?: Maybe<Scalars['Int']['output']>;
  essayReadingScore?: Maybe<Scalars['Int']['output']>;
  essayWritingScore?: Maybe<Scalars['Int']['output']>;
  missedQuestionNumbers: Array<Scalars['Int']['output']>;
  omittedQuestionNumbers: Array<Scalars['Int']['output']>;
  /** @deprecated deprecated in favor of answerType */
  skipSection?: Maybe<Scalars['Boolean']['output']>;
  subjectId: Scalars['ID']['output'];
};

export type Lesson = Node & {
  __typename?: 'Lesson';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  files?: Maybe<Array<File>>;
  firstVideoId?: Maybe<Scalars['ID']['output']>;
  hasUnderstood: Scalars['Boolean']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isBookmarked: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  isTrialAccess: Scalars['Boolean']['output'];
  playlist?: Maybe<Playlist>;
  subject: Subject;
  subjectId: Scalars['ID']['output'];
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  videos: Array<Video>;
};

export type LessonHasUnderstood = {
  __typename?: 'LessonHasUnderstood';
  lessonMarkedIds: Array<Scalars['Int']['output']>;
  lessonUnderstoodIds: Array<Scalars['Int']['output']>;
  videoUnderstoodIds: Array<Scalars['Int']['output']>;
};

export enum MailingMethodEnum {
  ElectronicEbook = 'Electronic_Ebook',
  MediaMail = 'Media_Mail',
  None = 'None',
  PriorityMail = 'Priority_Mail'
}

export type MarkReviewCreateInput = {
  isMarkedReview: Scalars['Boolean']['input'];
  questionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  userTestId: Scalars['ID']['input'];
};

export type Migration = Node & {
  __typename?: 'Migration';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<Tutor>;
  addClassStudent?: Maybe<Scalars['Boolean']['output']>;
  addStripeCardDetails: User;
  addStudent: AddStudentType;
  adminSetUserPassword: Scalars['Boolean']['output'];
  assignTutorToStudent: User;
  cancelTutorTrial: Subscription;
  classCreate: Classes;
  classStatusUpdate?: Maybe<Scalars['Boolean']['output']>;
  copyHomework?: Maybe<Homework>;
  copyQuiz?: Maybe<Quiz>;
  criteriaManuallyEntryUpdate: Test;
  deleteClass?: Maybe<Scalars['Boolean']['output']>;
  deleteHomework?: Maybe<Homework>;
  deleteInvitedStudent: Scalars['Boolean']['output'];
  deleteTutor: Scalars['Boolean']['output'];
  digitalSatAnnotation: Test;
  digitalSatCreate: DigitalSat;
  digitalSatFeedbackCreate: DigitalSatFeedback;
  digitalSatImportQuestionCreate: TestAnswer;
  digitalSatInputScoreCreate: Test;
  digitalSatPracticeSetCreate: Lesson;
  digitalSatPracticeSetCreateQuestion: Lesson;
  digitalSatPracticeSetDeleteQuestion?: Maybe<Lesson>;
  digitalSatPracticeSetIsPulished?: Maybe<Scalars['Boolean']['output']>;
  digitalSatQuestionStatusUpdate: TestAnswer;
  digitalSatStartExamUpdate: Test;
  digitalSatTestAnswerCreate: TestAnswer;
  digitalSatUserTestAnswerUpdate: Test;
  digitalUserTestResultsCreate: Test;
  enterManuallyScore: Test;
  examTimerUpdate: UserTestResults;
  faqCreate: Faq;
  faqDeleteById: Scalars['ID']['output'];
  faqStatusUpdate: Faq;
  fileCreateLesson: Lesson;
  fileCreateResourceGroup: ResourceGroup;
  fileDelete?: Maybe<File>;
  homeworkCreate: Homework;
  homeworkLessonStatus?: Maybe<Scalars['Boolean']['output']>;
  homeworkStatusUpdate?: Maybe<Scalars['Boolean']['output']>;
  lessonCopyCreate: Lesson;
  lessonCreate: Lesson;
  lessonDelete: Lesson;
  lessonUpdate: Lesson;
  lessonUpdateVideoOrder: Lesson;
  markFileDownloaded: File;
  markReviewCreate: TestAnswer;
  notificationCreate: Notifications;
  notificationDelete: Scalars['Boolean']['output'];
  notificationUpdate: Scalars['Boolean']['output'];
  playlistCreate: Playlist;
  playlistCreatePublic: Playlist;
  playlistDelete: Lesson;
  playlistUpdate: Playlist;
  practiceModeChange: PracticeSet;
  practiceSetAnnotation?: Maybe<Scalars['Boolean']['output']>;
  practiceSetImportQuestion?: Maybe<Lesson>;
  practiceSetMarkReview: PracticeSet;
  practiceSetStrike: Lesson;
  practiceSetUpdateHighlightNotesStatus: Quiz;
  questionBankCreate: QuestionBank;
  questionBankDelete?: Maybe<QuestionBank>;
  questionBankSearch: Array<QuestionBank>;
  questionBankStatusUpdate: Scalars['Boolean']['output'];
  questionScoreUpdate: TestAnswer;
  questionTimeUpdate: TestAnswer;
  questionTimer: Scalars['Boolean']['output'];
  quizAlertUpdate: User;
  quizAnnotation: Quiz;
  quizCreate: Quiz;
  quizDelete?: Maybe<Quiz>;
  quizExamTimerUpdate?: Maybe<Scalars['Boolean']['output']>;
  quizMarkReviewCreate: Quiz;
  quizQuestionAnswerUpdate: Quiz;
  quizQuestionTimer: Scalars['Boolean']['output'];
  quizStartExamUpdate: Quiz;
  quizStatusUpdate?: Maybe<Scalars['Boolean']['output']>;
  quizStrikeUpdate?: Maybe<Scalars['Boolean']['output']>;
  quizUpdateHighlightNotesStatus: Quiz;
  removeClassStudent?: Maybe<Scalars['Boolean']['output']>;
  resendInvitationEmail: AddStudentType;
  resourceGroupCreate: Subject;
  resourceGroupUpdate: ResourceGroup;
  scoreBookmark: Playlist;
  setFaqOrder: Faq;
  setPlaylistLessons: Playlist;
  setPlaylistOrder: Subject;
  setPracticeSetOrder: PracticeSet;
  setSubjectOrder: Course;
  setTestAnswerOrder: Test;
  setTestOrder: Course;
  setUserFileCompleted: File;
  setUserLessonBookmark: Lesson;
  setUserLessonBookmarks: Array<Lesson>;
  setUserLessonUnderstood: Lesson;
  setUserVideoSeen: Video;
  setUserVideoUnderstood: Lesson;
  setupIntentCreate: SetupIntentResponse;
  showHideQuizTimerUpdate: Quiz;
  showHideTimerUpdate: UserTestResults;
  strikeUpdate: TestAnswer;
  studentAssignment?: Maybe<Scalars['Boolean']['output']>;
  subscriptionActivate: SubscriptionActivateResponse;
  subscriptionCancel: Subscription;
  subscriptionCancelTrial: Subscription;
  subscriptionCreate: User;
  subscriptionTutorCancel: Subscription;
  subscriptionUpdate: User;
  switchPlan: Subscription;
  switchRole: Scalars['ID']['output'];
  tagCategoryCreate: TagCategory;
  tagCategoryDelete?: Maybe<Scalars['Boolean']['output']>;
  tagCreate: Tag;
  tagDelete?: Maybe<Scalars['Boolean']['output']>;
  tagToggleAssignCategory: TagCategory;
  tagToggleAssignLesson: Lesson;
  tagToggleAssignQuestionBank: QuestionBank;
  tagToggleAssignTestAnswer: TestAnswer;
  testAnswerDeleteById: Test;
  testBookUserToggle: TestBook;
  testUpdate: Test;
  toggleParcticeSetTag?: Maybe<Scalars['Boolean']['output']>;
  togglePlaylistLesson: Playlist;
  tutorCreate: Tutor;
  tutorFeedback?: Maybe<Tutor>;
  updateHighlightNotesStatus: TestAnswer;
  updateLessonVideo: Lesson;
  userCreate: User;
  userCreateAdmin: User;
  userDeleteById: Scalars['ID']['output'];
  userInitialUpdate: User;
  userLastLogged: Scalars['ID']['output'];
  userNotificationSatusUpdate: Scalars['Boolean']['output'];
  userPracticeSetAnswers: Array<PracticeSet>;
  userQuizCreate: Quiz;
  userTestResultsCreate: Test;
  userUpdate: User;
  videoCreate: Lesson;
  videoDelete: Lesson;
  videoUpdate: Lesson;
};


export type MutationAcceptInvitationArgs = {
  tutorId: Scalars['ID']['input'];
};


export type MutationAddClassStudentArgs = {
  id: Scalars['ID']['input'];
  studentIds: Scalars['String']['input'];
};


export type MutationAddStripeCardDetailsArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};


export type MutationAddStudentArgs = {
  input: AddStudentInput;
};


export type MutationAdminSetUserPasswordArgs = {
  newPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAssignTutorToStudentArgs = {
  classIds: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  tutorIds: Array<Scalars['ID']['input']>;
};


export type MutationCancelTutorTrialArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
};


export type MutationClassCreateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  studentIds: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  tutorIds: Array<Scalars['ID']['input']>;
};


export type MutationClassStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationCopyHomeworkArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCopyQuizArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCriteriaManuallyEntryUpdateArgs = {
  input: CriteriaManuallyEntryUpdateInput;
};


export type MutationDeleteClassArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteHomeworkArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteInvitedStudentArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeleteTutorArgs = {
  tutorId: Scalars['ID']['input'];
};


export type MutationDigitalSatAnnotationArgs = {
  input: DigitalSatAnnotationInput;
};


export type MutationDigitalSatCreateArgs = {
  input: DigitalSatCreateInput;
};


export type MutationDigitalSatFeedbackCreateArgs = {
  input: DigitalSatFeedbackInput;
};


export type MutationDigitalSatImportQuestionCreateArgs = {
  subjectId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  uploadFile: Scalars['String']['input'];
};


export type MutationDigitalSatInputScoreCreateArgs = {
  input: DigitalSatInputScoreInput;
};


export type MutationDigitalSatPracticeSetCreateArgs = {
  difficultyLevel: Scalars['String']['input'];
  isCompulsion: Scalars['Boolean']['input'];
  lessonId: Scalars['ID']['input'];
  mode: Scalars['String']['input'];
  title: Scalars['String']['input'];
  vimeoId: Scalars['Int']['input'];
};


export type MutationDigitalSatPracticeSetCreateQuestionArgs = {
  answer: Scalars['String']['input'];
  difficultyLevel: Scalars['String']['input'];
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSaveToQuestionbank: Scalars['Boolean']['input'];
  lessonId: Scalars['ID']['input'];
  optionA: Scalars['String']['input'];
  optionB: Scalars['String']['input'];
  optionC: Scalars['String']['input'];
  optionD: Scalars['String']['input'];
  question: Scalars['String']['input'];
  questionBankId?: InputMaybe<Scalars['Int']['input']>;
  questionType: Scalars['String']['input'];
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Scalars['String']['input']>;
  videoId: Scalars['ID']['input'];
  vimeoId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDigitalSatPracticeSetDeleteQuestionArgs = {
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
  practiceSetId: Scalars['ID']['input'];
};


export type MutationDigitalSatPracticeSetIsPulishedArgs = {
  id: Scalars['ID']['input'];
  isPublished: Scalars['Boolean']['input'];
};


export type MutationDigitalSatQuestionStatusUpdateArgs = {
  input: DigitalSatQuestionStatusUpdateInput;
};


export type MutationDigitalSatStartExamUpdateArgs = {
  input: DigitalSatStartExamUpdateInput;
};


export type MutationDigitalSatTestAnswerCreateArgs = {
  answer: Scalars['String']['input'];
  difficultyLevel: Scalars['String']['input'];
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSaveToQuestionbank: Scalars['Boolean']['input'];
  optionA?: InputMaybe<Scalars['String']['input']>;
  optionB?: InputMaybe<Scalars['String']['input']>;
  optionC?: InputMaybe<Scalars['String']['input']>;
  optionD?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  questionBankId?: InputMaybe<Scalars['Int']['input']>;
  questionMarks: Scalars['String']['input'];
  questionType: Scalars['String']['input'];
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  subjectId: Scalars['ID']['input'];
  tagIds?: InputMaybe<Scalars['String']['input']>;
  testId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  vimeoId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDigitalSatUserTestAnswerUpdateArgs = {
  input: DigitalSatUserTestAnswerUpdateInput;
};


export type MutationDigitalUserTestResultsCreateArgs = {
  input: DigitalSatUserTestResultsCreateInput;
};


export type MutationEnterManuallyScoreArgs = {
  input: UserTestResultsCreateInputs;
};


export type MutationExamTimerUpdateArgs = {
  input: ExamTimerUpdateInput;
};


export type MutationFaqCreateArgs = {
  input: FaqCreateInput;
};


export type MutationFaqDeleteByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFaqStatusUpdateArgs = {
  input: FaqInput;
};


export type MutationFileCreateLessonArgs = {
  lessonId: Scalars['ID']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationFileCreateResourceGroupArgs = {
  link?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  resourceGroupId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationFileDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHomeworkCreateArgs = {
  assignments?: InputMaybe<Scalars['String']['input']>;
  bluebookIds?: InputMaybe<Scalars['String']['input']>;
  classes: Array<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lessonIds?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  quizIds?: InputMaybe<Scalars['String']['input']>;
  responseType?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Boolean']['input'];
  students: Array<Scalars['ID']['input']>;
  testIds?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationHomeworkLessonStatusArgs = {
  lessonId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  videoId: Scalars['ID']['input'];
};


export type MutationHomeworkStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationLessonCopyCreateArgs = {
  lessonId: Scalars['ID']['input'];
  playlistId: Scalars['ID']['input'];
};


export type MutationLessonCreateArgs = {
  isTrialAccess: Scalars['Boolean']['input'];
  lessonTitle: Scalars['String']['input'];
  playlistId?: InputMaybe<Scalars['ID']['input']>;
  subjectId: Scalars['ID']['input'];
  testAnswerId?: InputMaybe<Scalars['ID']['input']>;
  vimeoIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationLessonDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLessonUpdateArgs = {
  id: Scalars['ID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isTrialAccess?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLessonUpdateVideoOrderArgs = {
  lessonId: Scalars['ID']['input'];
  videoIds: Array<Scalars['ID']['input']>;
};


export type MutationMarkFileDownloadedArgs = {
  fileId: Scalars['ID']['input'];
};


export type MutationMarkReviewCreateArgs = {
  input: MarkReviewCreateInput;
};


export type MutationNotificationCreateArgs = {
  description: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationNotificationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationNotificationUpdateArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationPlaylistCreateArgs = {
  lessonIds: Array<Scalars['ID']['input']>;
  playlistTitle: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
  testId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationPlaylistCreatePublicArgs = {
  lessonIds: Array<Scalars['ID']['input']>;
  playlistTitle: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
  testId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationPlaylistDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlaylistUpdateArgs = {
  id: Scalars['ID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPracticeModeChangeArgs = {
  examId: Scalars['Int']['input'];
  videoId: Scalars['ID']['input'];
};


export type MutationPracticeSetAnnotationArgs = {
  annotation: Scalars['String']['input'];
  examId: Scalars['Int']['input'];
  questionId: Scalars['Int']['input'];
  videoId: Scalars['ID']['input'];
};


export type MutationPracticeSetImportQuestionArgs = {
  uploadFile: Scalars['String']['input'];
  videoId: Scalars['ID']['input'];
};


export type MutationPracticeSetMarkReviewArgs = {
  examId: Scalars['Int']['input'];
  isMarkedReview: Scalars['Boolean']['input'];
  questionId: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationPracticeSetStrikeArgs = {
  input: PracrticeSetStrikeInput;
};


export type MutationPracticeSetUpdateHighlightNotesStatusArgs = {
  examId: Scalars['Int']['input'];
  status?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationQuestionBankCreateArgs = {
  answer: Scalars['String']['input'];
  difficultyLevel: Scalars['String']['input'];
  explanation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  optionA?: InputMaybe<Scalars['String']['input']>;
  optionB?: InputMaybe<Scalars['String']['input']>;
  optionC?: InputMaybe<Scalars['String']['input']>;
  optionD?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  questionTags: Scalars['String']['input'];
  questionType: Scalars['String']['input'];
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Scalars['String']['input']>;
  testTitle: Scalars['String']['input'];
  vimeoId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationQuestionBankDeleteArgs = {
  id: Scalars['ID']['input'];
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationQuestionBankSearchArgs = {
  searchText: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationQuestionBankStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationQuestionScoreUpdateArgs = {
  input: QuestionScoreUpdateInput;
};


export type MutationQuestionTimeUpdateArgs = {
  questionTime: Scalars['Int']['input'];
  testAnswerId: Scalars['ID']['input'];
  userTestId: Scalars['ID']['input'];
};


export type MutationQuestionTimerArgs = {
  input: QuestionTimerInput;
};


export type MutationQuizAlertUpdateArgs = {
  status?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationQuizAnnotationArgs = {
  input: QuizAnnotationInput;
};


export type MutationQuizCreateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  questionBankType: Scalars['String']['input'];
  questionTimer: Scalars['Boolean']['input'];
  quizQuestions: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
  subject: Scalars['String']['input'];
  title: Scalars['String']['input'];
  totalTime: Scalars['Int']['input'];
};


export type MutationQuizDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationQuizExamTimerUpdateArgs = {
  input: QuizExamTimerInput;
};


export type MutationQuizMarkReviewCreateArgs = {
  input: QuizMarkReviewInput;
};


export type MutationQuizQuestionAnswerUpdateArgs = {
  input: QuizQuestionAnswerUpdateInput;
};


export type MutationQuizQuestionTimerArgs = {
  input: QuizQuestionTimerInput;
};


export type MutationQuizStartExamUpdateArgs = {
  input: QuizStartExamUpdateInput;
};


export type MutationQuizStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationQuizStrikeUpdateArgs = {
  input: QuizStrikeUpdateInput;
};


export type MutationQuizUpdateHighlightNotesStatusArgs = {
  status?: InputMaybe<Scalars['Boolean']['input']>;
  userQuizId: Scalars['ID']['input'];
};


export type MutationRemoveClassStudentArgs = {
  id: Scalars['ID']['input'];
  studentId: Scalars['String']['input'];
};


export type MutationResendInvitationEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResourceGroupCreateArgs = {
  isTrialAccess: Scalars['Boolean']['input'];
  subjectId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationResourceGroupUpdateArgs = {
  id: Scalars['ID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isTrialAccess?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationScoreBookmarkArgs = {
  lessonId: Scalars['ID']['input'];
  playlistId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetFaqOrderArgs = {
  faqIds: Array<Scalars['ID']['input']>;
};


export type MutationSetPlaylistLessonsArgs = {
  lessonIds: Array<Scalars['ID']['input']>;
  playlistId: Scalars['ID']['input'];
};


export type MutationSetPlaylistOrderArgs = {
  playlistIds: Array<Scalars['ID']['input']>;
  subjectId: Scalars['ID']['input'];
};


export type MutationSetPracticeSetOrderArgs = {
  questionIds: Array<Scalars['ID']['input']>;
};


export type MutationSetSubjectOrderArgs = {
  courseId: Scalars['ID']['input'];
  subjectIds: Array<Scalars['ID']['input']>;
};


export type MutationSetTestAnswerOrderArgs = {
  questionIds: Array<Scalars['ID']['input']>;
};


export type MutationSetTestOrderArgs = {
  courseId: Scalars['ID']['input'];
  testBookIds: Array<Scalars['ID']['input']>;
};


export type MutationSetUserFileCompletedArgs = {
  fileId: Scalars['ID']['input'];
  hasCompleted: Scalars['Boolean']['input'];
};


export type MutationSetUserLessonBookmarkArgs = {
  isBookmarked: Scalars['Boolean']['input'];
  lessonId: Scalars['ID']['input'];
};


export type MutationSetUserLessonBookmarksArgs = {
  lessonIds: Array<InputMaybe<Scalars['ID']['input']>>;
  userId: Scalars['ID']['input'];
};


export type MutationSetUserLessonUnderstoodArgs = {
  hasUnderstood: Scalars['Boolean']['input'];
  lessonId: Scalars['ID']['input'];
};


export type MutationSetUserVideoSeenArgs = {
  videoId: Scalars['ID']['input'];
};


export type MutationSetUserVideoUnderstoodArgs = {
  hasUnderstood: Scalars['Boolean']['input'];
  videoId: Scalars['ID']['input'];
};


export type MutationSetupIntentCreateArgs = {
  input: SetupIntentCreateInput;
};


export type MutationShowHideQuizTimerUpdateArgs = {
  input: ShowHideQuizTimerInput;
};


export type MutationShowHideTimerUpdateArgs = {
  input: ShowHideTimerInput;
};


export type MutationStrikeUpdateArgs = {
  input: StrikeUpdateInput;
};


export type MutationStudentAssignmentArgs = {
  answer: Scalars['String']['input'];
  homeworkId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  isChecked: Scalars['Boolean']['input'];
};


export type MutationSubscriptionActivateArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type MutationSubscriptionCancelArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
};


export type MutationSubscriptionCancelTrialArgs = {
  isSwitchPlan?: InputMaybe<Scalars['Boolean']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  stripeSubscriptionId: Scalars['String']['input'];
  surveyInfo: Scalars['String']['input'];
};


export type MutationSubscriptionCreateArgs = {
  input: SubscriptionCreateInput;
};


export type MutationSubscriptionUpdateArgs = {
  hasShippedBooks?: InputMaybe<Scalars['Boolean']['input']>;
  newCancelAtDate?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type MutationSwitchPlanArgs = {
  isActiveSwitch?: InputMaybe<Scalars['Boolean']['input']>;
  isReSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  isSwitch?: InputMaybe<Scalars['Boolean']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  stripeSubscriptionId: Scalars['String']['input'];
};


export type MutationTagCategoryCreateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  tagIds: Array<Scalars['ID']['input']>;
};


export type MutationTagCategoryDeleteArgs = {
  tagCategoryId: Scalars['ID']['input'];
};


export type MutationTagCreateArgs = {
  courseId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationTagDeleteArgs = {
  tagId: Scalars['ID']['input'];
};


export type MutationTagToggleAssignCategoryArgs = {
  tagCategoryId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};


export type MutationTagToggleAssignLessonArgs = {
  lessonId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};


export type MutationTagToggleAssignQuestionBankArgs = {
  questionbankId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};


export type MutationTagToggleAssignTestAnswerArgs = {
  tagId: Scalars['ID']['input'];
  testAnswerId: Scalars['ID']['input'];
};


export type MutationTestAnswerDeleteByIdArgs = {
  id: Scalars['ID']['input'];
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationTestBookUserToggleArgs = {
  testBookId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationTestUpdateArgs = {
  id: Scalars['ID']['input'];
  instructionStep1?: InputMaybe<Scalars['String']['input']>;
  instructionStep2?: InputMaybe<Scalars['String']['input']>;
  instructionStep3?: InputMaybe<Scalars['String']['input']>;
  instructionStep4?: InputMaybe<Scalars['String']['input']>;
  isBluebookPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isTrialAccess?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationToggleParcticeSetTagArgs = {
  questionId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};


export type MutationTogglePlaylistLessonArgs = {
  lessonId: Scalars['ID']['input'];
  playlistId: Scalars['ID']['input'];
};


export type MutationTutorCreateArgs = {
  input: TutorCreateInput;
};


export type MutationTutorFeedbackArgs = {
  feedback: Scalars['String']['input'];
  fileUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateHighlightNotesStatusArgs = {
  status?: InputMaybe<Scalars['Boolean']['input']>;
  userTestId: Scalars['ID']['input'];
};


export type MutationUpdateLessonVideoArgs = {
  id: Scalars['ID']['input'];
  mode?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserCreateAdminArgs = {
  input: UserCreateAdminInput;
};


export type MutationUserDeleteByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUserInitialUpdateArgs = {
  input: UserInitialUpdateInput;
};


export type MutationUserNotificationSatusUpdateArgs = {
  notificationId: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationUserPracticeSetAnswersArgs = {
  input: PracticeSetInput;
};


export type MutationUserQuizCreateArgs = {
  homeworkId?: InputMaybe<Scalars['ID']['input']>;
  mode: Scalars['String']['input'];
  quizId: Scalars['ID']['input'];
};


export type MutationUserTestResultsCreateArgs = {
  input: UserTestResultsCreateInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationVideoCreateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  lessonId: Scalars['ID']['input'];
  vimeoId: Scalars['Int']['input'];
};


export type MutationVideoDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVideoUpdateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Node = {
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type Notifications = Node & {
  __typename?: 'Notifications';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type NotificationsCount = {
  __typename?: 'NotificationsCount';
  count: Scalars['Int']['output'];
  records: Array<Notifications>;
};

export enum OrderEnum {
  Ascending = 'ascending',
  Descending = 'descending'
}

export type PaginationArg = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PaymentPlan = Node & {
  __typename?: 'PaymentPlan';
  amount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  length: Scalars['String']['output'];
  prorateAmount?: Maybe<Scalars['Int']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  trialPeriodDays?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type PaymentPlanWithTrialInformation = {
  __typename?: 'PaymentPlanWithTrialInformation';
  canSelectTrial: Scalars['Boolean']['output'];
  paymentPlan: PaymentPlan;
};

export type PendingUserShipment = {
  __typename?: 'PendingUserShipment';
  address: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  height: Scalars['String']['output'];
  length: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  orderItems: Scalars['String']['output'];
  ounces: Scalars['String']['output'];
  state: Scalars['String']['output'];
  width: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type Playlist = Node & {
  __typename?: 'Playlist';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  lessons: Array<Lesson>;
  order: Scalars['Int']['output'];
  test?: Maybe<Test>;
  testId?: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type PlaylistLesson = Node & {
  __typename?: 'PlaylistLesson';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lessons?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type PracrticeSetStrikeInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  examId: Scalars['Int']['input'];
  isUpdateAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  lessonId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  strike: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type PracticeQue = Node & {
  __typename?: 'PracticeQue';
  answer: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isHighlight?: Maybe<Scalars['Boolean']['output']>;
  isPublished: Scalars['Boolean']['output'];
  optionA?: Maybe<Scalars['String']['output']>;
  optionB?: Maybe<Scalars['String']['output']>;
  optionC?: Maybe<Scalars['String']['output']>;
  optionD?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionBankId?: Maybe<Scalars['Int']['output']>;
  questionType?: Maybe<Scalars['String']['output']>;
  referenceDetails?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Tag>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  vimeoId?: Maybe<Scalars['Int']['output']>;
  yourAnswer?: Maybe<Scalars['String']['output']>;
};

export type PracticeSet = Node & {
  __typename?: 'PracticeSet';
  all?: Maybe<Scalars['String']['output']>;
  allQuestion?: Maybe<Scalars['String']['output']>;
  allQuestions?: Maybe<Array<PracticeQue>>;
  analytics?: Maybe<Array<PracticeSet>>;
  answer?: Maybe<Scalars['String']['output']>;
  attemptedStudents?: Maybe<Scalars['String']['output']>;
  correctAnswer?: Maybe<Scalars['Int']['output']>;
  correctResponse?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  currentQuestion?: Maybe<Scalars['String']['output']>;
  currentQuestionNo?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  examId?: Maybe<Scalars['Int']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  homeworkId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  incorrectAnswer?: Maybe<Scalars['Int']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  isMarked?: Maybe<Scalars['Boolean']['output']>;
  isPublished: Scalars['Boolean']['output'];
  lessonId?: Maybe<Scalars['ID']['output']>;
  lessonTitle?: Maybe<Scalars['String']['output']>;
  math?: Maybe<Scalars['String']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  mostMissedQuestions?: Maybe<Scalars['Int']['output']>;
  optionA: Scalars['String']['output'];
  optionAResponse?: Maybe<Scalars['String']['output']>;
  optionB: Scalars['String']['output'];
  optionBResponse?: Maybe<Scalars['String']['output']>;
  optionC: Scalars['String']['output'];
  optionCResponse?: Maybe<Scalars['String']['output']>;
  optionD: Scalars['String']['output'];
  optionDResponse?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  playlistId?: Maybe<Scalars['ID']['output']>;
  playlistTitle?: Maybe<Scalars['String']['output']>;
  practiceSetdata?: Maybe<Scalars['String']['output']>;
  practiceSettitle?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  questionId?: Maybe<Scalars['ID']['output']>;
  questionLevel?: Maybe<Scalars['String']['output']>;
  questionType: Scalars['String']['output'];
  reading?: Maybe<Scalars['String']['output']>;
  referenceDetails?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  strike?: Maybe<Scalars['String']['output']>;
  studentAnswer?: Maybe<Scalars['String']['output']>;
  studentResponses?: Maybe<Scalars['Int']['output']>;
  students?: Maybe<Array<Tutor>>;
  subjectId?: Maybe<Scalars['ID']['output']>;
  subjectTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']['output']>;
  totalCorrectAnswer?: Maybe<Scalars['Int']['output']>;
  totalQuestion?: Maybe<Scalars['Int']['output']>;
  totalQuestionNo?: Maybe<Scalars['String']['output']>;
  totalResponse?: Maybe<Scalars['Int']['output']>;
  totalWrongAnswer?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userScore?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  videoId?: Maybe<Scalars['ID']['output']>;
  vimeoId: Scalars['Int']['output'];
  wrongResponse?: Maybe<Scalars['String']['output']>;
  yourAnswer?: Maybe<Scalars['String']['output']>;
};


export type PracticeSetAnalyticsArgs = {
  homeworkId?: InputMaybe<Scalars['ID']['input']>;
};

export type PracticeSetInput = {
  examId?: InputMaybe<Scalars['String']['input']>;
  firstVisit?: InputMaybe<Scalars['String']['input']>;
  isUpdateStrike: Scalars['Boolean']['input'];
  questionId?: InputMaybe<Scalars['ID']['input']>;
  resetType?: InputMaybe<Scalars['String']['input']>;
  strike: Scalars['String']['input'];
  videoId?: InputMaybe<Scalars['ID']['input']>;
  yourAnswer?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductIdEnum {
  Act = 'ACT',
  Actanddigitalsatcombo = 'ACTANDDIGITALSATCOMBO',
  Both = 'Both',
  Digitalsat = 'DIGITALSAT',
  Sat = 'SAT',
  Tutormodebasic = 'TUTORMODEBASIC',
  Tutormodeplus = 'TUTORMODEPLUS'
}

export type Query = {
  __typename?: 'Query';
  FAQ: Array<Faq>;
  FaqAccommodation: Array<Faq>;
  activityLogInformationList?: Maybe<ActivityLogInformationListWithCount>;
  allDigitalSatQuestions?: Maybe<AllDigitalSatQuestions>;
  allDigitalSatUsers?: Maybe<Array<Tutor>>;
  allQuestionBank: Array<QuestionBank>;
  allQuiz?: Maybe<Quiz>;
  allQuizQuestions?: Maybe<AllQuizQuestions>;
  bluebookInstruction: Test;
  bluebookStudentAnalytics: BluebookStudentAnalyticstWithCount;
  bookmarkedLessons?: Maybe<Array<Lesson>>;
  classes: ClassesWithCount;
  classesDetails?: Maybe<ClassesDetails>;
  completeTestAndSectionScore: CompleteTestAndSectionScoreCount;
  countries: Array<Country>;
  coupon: Coupon;
  courses: Array<Course>;
  criteriaManuallyEntry: TestBook;
  dashboardDigitalSatTestsScore?: Maybe<UserTestScore>;
  digitalSatFeedback: DigitalSatFeedbackCount;
  digitalSatPracticeSet?: Maybe<PracticeSet>;
  digitalSatSection?: Maybe<TestSection>;
  digitalSatTestBooksAll: TestBook;
  digitalSatTestsScore?: Maybe<DigitalSatTestsScoreCount>;
  digitalSatTestsScoreDetails?: Maybe<UserTestScore>;
  digitalSatUsers: DigitalSatUsersWithCount;
  enterMaunallyQuestionNo?: Maybe<Array<EnterMaunallyQuestionNo>>;
  examTimer: UserTestResults;
  homeworkBluebookAnalytics?: Maybe<UserTestScore>;
  homeworkDetails?: Maybe<Homework>;
  homeworks: HomeworksWithCount;
  introLesson?: Maybe<Lesson>;
  invitedTutorList?: Maybe<Array<Tutor>>;
  isExamStart: UserTestResults;
  latestTest?: Maybe<UserTestResults>;
  latestTestResults?: Maybe<UserTestResults>;
  lesson?: Maybe<Lesson>;
  lessonStatus?: Maybe<Array<Homework>>;
  migration: Array<Migration>;
  mostRecentHomework?: Maybe<Homework>;
  notification: NotificationsCount;
  parcticeSetAnalytics?: Maybe<PracticeSet>;
  paymentPlan: PaymentPlanWithTrialInformation;
  paymentPlans: Array<PaymentPlan>;
  pendingUserShipments: Array<PendingUserShipment>;
  playlist?: Maybe<Playlist>;
  playlists?: Maybe<Array<Playlist>>;
  practiceResult?: Maybe<Array<PracticeSet>>;
  practiceSetData?: Maybe<PracticeSet>;
  practiceSetScore?: Maybe<PracticeSet>;
  practiceSetStudentAnalytics?: Maybe<Array<PracticeSet>>;
  questionBank: QuestionBankWithCount;
  questionCount: QuestionCount;
  questionTags?: Maybe<Array<Tag>>;
  quizDetails?: Maybe<Quiz>;
  quizExamTimer: Quiz;
  quizQuestion?: Maybe<Array<TestAnswer>>;
  quizResume?: Maybe<Array<Quiz>>;
  quizScore: Array<Quiz>;
  quizStudentAnalytics: QuizStudentAnalyticstWithCount;
  quizzes: QuizzesWithCount;
  recentUploads?: Maybe<Array<Lesson>>;
  recommendCustomStudyListLessons: Array<Lesson>;
  resourceGroup?: Maybe<ResourceGroup>;
  resourceGroups?: Maybe<Array<ResourceGroup>>;
  resumeTest: Array<UserTestResults>;
  states: Array<State>;
  stripeDetails: StripeUser;
  studentAnalytics?: Maybe<Quiz>;
  studentBookmarkLessons: LessonHasUnderstood;
  studentDetails?: Maybe<Tutor>;
  studentList: StudentListWithCount;
  studentWiseHomework: StudentWiseHomeworkWithCount;
  studentWiseQuizzes: StudentWiseQuizzesWithCount;
  studentsClassesAndTutors?: Maybe<Tutor>;
  subject?: Maybe<Subject>;
  subjects: Array<Subject>;
  subscriberQuizInstruction?: Maybe<Quiz>;
  tagCategory?: Maybe<TagCategoryListWithCount>;
  tags?: Maybe<Array<Tag>>;
  test?: Maybe<Test>;
  testBook?: Maybe<TestBook>;
  testBooks: Array<TestBook>;
  testBooksAll: Array<TestBook>;
  testDigitalSat?: Maybe<Test>;
  testStatus?: Maybe<Array<Tutor>>;
  testStudentAnalytics: TestStudentAnalyticstWithCount;
  timeLogInformationList?: Maybe<TimeLogInformationListWithCount>;
  totalActiveQuestions: Array<TestAnswer>;
  tutorHomeworkReport?: Maybe<TutorHomeworkReport>;
  tutors: TutorsWithCount;
  user?: Maybe<User>;
  userTestAnswer: UserTestResults;
  userTestDSatSnippet: Array<UserTestDSatSnippet>;
  userTestSnippet: Array<UserTestSnippet>;
  userTrialSurvey: Array<User>;
  users: UsersWithCount;
};


export type QueryFaqAccommodationArgs = {
  type: Scalars['String']['input'];
};


export type QueryActivityLogInformationListArgs = {
  filters?: InputMaybe<UserFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryAllDigitalSatQuestionsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userTestId: Scalars['ID']['input'];
};


export type QueryAllQuizArgs = {
  filters?: InputMaybe<QuizFiltersArg>;
};


export type QueryAllQuizQuestionsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userQuizId: Scalars['ID']['input'];
};


export type QueryBluebookStudentAnalyticsArgs = {
  filters?: InputMaybe<TestFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryClassesArgs = {
  filters?: InputMaybe<ClassesFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryClassesDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompleteTestAndSectionScoreArgs = {
  tabTitle: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryCouponArgs = {
  couponCode: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCriteriaManuallyEntryArgs = {
  testBookId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
};


export type QueryDigitalSatFeedbackArgs = {
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryDigitalSatPracticeSetArgs = {
  lessonId: Scalars['ID']['input'];
  videoId: Scalars['ID']['input'];
};


export type QueryDigitalSatSectionArgs = {
  dbTestBooks?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};


export type QueryDigitalSatTestsScoreArgs = {
  examId?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  tabTitle: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryDigitalSatTestsScoreDetailsArgs = {
  cardTitle: Scalars['String']['input'];
  examId: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryDigitalSatUsersArgs = {
  filters?: InputMaybe<TutorFiltersArg>;
  orderBy: TutorOrderByArg;
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryEnterMaunallyQuestionNoArgs = {
  testBookId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryExamTimerArgs = {
  userTestId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHomeworkBluebookAnalyticsArgs = {
  homeworkId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};


export type QueryHomeworkDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHomeworksArgs = {
  classId?: InputMaybe<Scalars['Int']['input']>;
  days?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<HomeworkFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
  status: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIntroLessonArgs = {
  productId: ProductIdEnum;
};


export type QueryLatestTestResultsArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryLessonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLessonStatusArgs = {
  hid: Scalars['Int']['input'];
  lessonId: Scalars['Int']['input'];
};


export type QueryMigrationArgs = {
  userQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNotificationArgs = {
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryParcticeSetAnalyticsArgs = {
  homeworkId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type QueryPaymentPlanArgs = {
  paymentPlanId: Scalars['String']['input'];
};


export type QueryPaymentPlansArgs = {
  productId: ProductIdEnum;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlaylistArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlaylistsArgs = {
  subjectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPracticeResultArgs = {
  subjectIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryPracticeSetDataArgs = {
  examId?: InputMaybe<Scalars['Int']['input']>;
  lessonId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  videoId: Scalars['ID']['input'];
};


export type QueryPracticeSetScoreArgs = {
  videoId: Scalars['ID']['input'];
};


export type QueryPracticeSetStudentAnalyticsArgs = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryQuestionBankArgs = {
  filters?: InputMaybe<QuestionBankFiltersArg>;
  orderBy: QuestionBankOrderByArg;
  pagination?: InputMaybe<PaginationArg>;
  subject: Scalars['String']['input'];
};


export type QueryQuestionCountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryQuestionTagsArgs = {
  courseId: Scalars['ID']['input'];
  questionBankType?: InputMaybe<Array<Scalars['String']['input']>>;
  sectionName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryQuizDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuizExamTimerArgs = {
  userQuizId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryQuizQuestionArgs = {
  deleteQuestions?: InputMaybe<Scalars['String']['input']>;
  easy?: InputMaybe<Scalars['Int']['input']>;
  hard?: InputMaybe<Scalars['Int']['input']>;
  medium?: InputMaybe<Scalars['Int']['input']>;
  questionBankType?: InputMaybe<Array<Scalars['String']['input']>>;
  quizId?: InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type QueryQuizResumeArgs = {
  homeworkId?: InputMaybe<Scalars['Int']['input']>;
  quizId: Scalars['Int']['input'];
};


export type QueryQuizScoreArgs = {
  homeworkId: Scalars['Int']['input'];
  quizId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryQuizStudentAnalyticsArgs = {
  filters?: InputMaybe<QuizFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryQuizzesArgs = {
  filters?: InputMaybe<QuizFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
  subject: Scalars['String']['input'];
};


export type QueryRecentUploadsArgs = {
  courseId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRecommendCustomStudyListLessonsArgs = {
  testAnswerIds: Array<Scalars['ID']['input']>;
};


export type QueryResourceGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryResourceGroupsArgs = {
  subjectId: Scalars['ID']['input'];
};


export type QueryStudentAnalyticsArgs = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryStudentBookmarkLessonsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStudentDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStudentListArgs = {
  pagination?: InputMaybe<PaginationArg>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStudentWiseHomeworkArgs = {
  id: Scalars['ID']['input'];
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryStudentWiseQuizzesArgs = {
  id: Scalars['ID']['input'];
  pagination?: InputMaybe<PaginationArg>;
};


export type QuerySubjectArgs = {
  subjectId: Scalars['ID']['input'];
};


export type QuerySubjectsArgs = {
  courseId: Scalars['ID']['input'];
};


export type QuerySubscriberQuizInstructionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTagCategoryArgs = {
  pagination?: InputMaybe<PaginationArg>;
  subject: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryTestArgs = {
  testId: Scalars['ID']['input'];
};


export type QueryTestBookArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTestBooksArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryTestStatusArgs = {
  hid: Scalars['Int']['input'];
  testData: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryTestStudentAnalyticsArgs = {
  filters?: InputMaybe<TestFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryTimeLogInformationListArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTotalActiveQuestionsArgs = {
  subjectId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
};


export type QueryTutorHomeworkReportArgs = {
  filters?: InputMaybe<HomeworkReportFiltersArg>;
  homeworkId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};


export type QueryTutorsArgs = {
  filters?: InputMaybe<TutorFiltersArg>;
  orderBy: TutorOrderByArg;
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserTestAnswerArgs = {
  subjectId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
};


export type QueryUserTestDSatSnippetArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type QueryUserTestSnippetArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserFiltersArg>;
  orderBy: UserOrderByArg;
  pagination?: InputMaybe<PaginationArg>;
};

export type QuestionBank = Node & {
  __typename?: 'QuestionBank';
  answer: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  difficultyLevel: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  optionA: Scalars['String']['output'];
  optionB: Scalars['String']['output'];
  optionC: Scalars['String']['output'];
  optionD: Scalars['String']['output'];
  practiceSetId?: Maybe<Scalars['Int']['output']>;
  practiceSetQuestion?: Maybe<PracticeSet>;
  question: Scalars['String']['output'];
  questionTags: Scalars['String']['output'];
  questionType: Scalars['String']['output'];
  referenceDetails: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  serialNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tags?: Maybe<Array<Tag>>;
  testAnswerId?: Maybe<Scalars['Int']['output']>;
  testTitle: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  vimeoId?: Maybe<Scalars['Int']['output']>;
};

export type QuestionBankFiltersArg = {
  question?: InputMaybe<Scalars['String']['input']>;
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<TypeEnum>>;
};

export type QuestionBankOrderByArg = {
  column: QuestionBankOrderByEnum;
  order: OrderEnum;
};

export enum QuestionBankOrderByEnum {
  Type = 'type'
}

export type QuestionBankWithCount = {
  __typename?: 'QuestionBankWithCount';
  count: Scalars['Int']['output'];
  records: Array<QuestionBank>;
};

export type QuestionCount = {
  __typename?: 'QuestionCount';
  count: Scalars['Int']['output'];
};

export type QuestionScoreUpdateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  questionMarks?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionTimerInput = {
  questionId?: InputMaybe<Scalars['ID']['input']>;
  spendTime?: InputMaybe<Scalars['Int']['input']>;
  userTestId: Scalars['ID']['input'];
};

export type Quiz = Node & {
  __typename?: 'Quiz';
  all?: Maybe<Array<Quiz>>;
  allQuiz?: Maybe<Array<Quiz>>;
  annotate?: Maybe<Scalars['String']['output']>;
  answer: Scalars['String']['output'];
  attemptedStudents?: Maybe<Scalars['String']['output']>;
  averageCorrect?: Maybe<Scalars['Int']['output']>;
  averageWrong?: Maybe<Scalars['Int']['output']>;
  correctResponse?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['String']['output'];
  examId: Scalars['Int']['output'];
  explanation?: Maybe<Scalars['String']['output']>;
  homeworkId?: Maybe<Scalars['Int']['output']>;
  homeworkStatus: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  isDirectionViewed: Scalars['Boolean']['output'];
  isHighlight?: Maybe<Scalars['Boolean']['output']>;
  isMarked?: Maybe<Scalars['Boolean']['output']>;
  isShowTimer: Scalars['Boolean']['output'];
  isUsedInHomework: Scalars['Boolean']['output'];
  math?: Maybe<Array<Quiz>>;
  mode?: Maybe<Scalars['String']['output']>;
  mostMissedQuestions?: Maybe<Scalars['Int']['output']>;
  noOfAttempt?: Maybe<Scalars['Int']['output']>;
  numberOfQuestion: Scalars['Int']['output'];
  optionA?: Maybe<Scalars['String']['output']>;
  optionAResponse?: Maybe<Scalars['String']['output']>;
  optionB?: Maybe<Scalars['String']['output']>;
  optionBResponse?: Maybe<Scalars['String']['output']>;
  optionC?: Maybe<Scalars['String']['output']>;
  optionCResponse?: Maybe<Scalars['String']['output']>;
  optionD?: Maybe<Scalars['String']['output']>;
  optionDResponse?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionBankType?: Maybe<Scalars['String']['output']>;
  questionId: Scalars['ID']['output'];
  questionLevel?: Maybe<Scalars['String']['output']>;
  questionReview?: Maybe<Scalars['String']['output']>;
  questionTimer: Scalars['Boolean']['output'];
  questionType?: Maybe<Scalars['String']['output']>;
  quizId: Scalars['ID']['output'];
  quizQuestions?: Maybe<Scalars['String']['output']>;
  quizStatus: Scalars['String']['output'];
  reading?: Maybe<Array<Quiz>>;
  referenceDetails?: Maybe<Scalars['String']['output']>;
  remainingSeconds: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
  statusOfStudent?: Maybe<Scalars['String']['output']>;
  strike?: Maybe<Scalars['String']['output']>;
  studentAnalytics?: Maybe<Array<Quiz>>;
  studentAnswer?: Maybe<Scalars['String']['output']>;
  studentResponses?: Maybe<Scalars['Int']['output']>;
  students?: Maybe<Array<Tutor>>;
  subject: Scalars['String']['output'];
  tagId: Scalars['ID']['output'];
  tagedQuestions?: Maybe<Array<TestAnswer>>;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String']['output'];
  totalCorrectAnswer?: Maybe<Scalars['Int']['output']>;
  totalQuestion?: Maybe<Scalars['Int']['output']>;
  totalResponse?: Maybe<Scalars['Int']['output']>;
  totalScore?: Maybe<Scalars['Int']['output']>;
  totalTime: Scalars['Int']['output'];
  totalWrongAnswer?: Maybe<Scalars['Int']['output']>;
  tutorId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userQuiz: Scalars['String']['output'];
  userQuizId?: Maybe<Scalars['Int']['output']>;
  userQuizQuestionId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  wrongResponse?: Maybe<Scalars['String']['output']>;
  yourAnswer?: Maybe<Scalars['String']['output']>;
};


export type QuizAllQuizArgs = {
  filters?: InputMaybe<QuizFiltersArg>;
};


export type QuizMathArgs = {
  filters?: InputMaybe<QuizFiltersArg>;
};


export type QuizReadingArgs = {
  filters?: InputMaybe<QuizFiltersArg>;
};

export type QuizAnnotationInput = {
  annotation: Scalars['String']['input'];
  quizQuestionId: Scalars['ID']['input'];
  userQuizId: Scalars['ID']['input'];
};

export type QuizExamTimerInput = {
  remainingSeconds?: InputMaybe<Scalars['Int']['input']>;
  userQuizId: Scalars['ID']['input'];
};

export type QuizFiltersArg = {
  className?: InputMaybe<Scalars['String']['input']>;
  homework?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuizMarkReviewInput = {
  isMarkedReview: Scalars['Boolean']['input'];
  questionId: Scalars['ID']['input'];
  userQuizId: Scalars['ID']['input'];
};

export type QuizQuestionAnswerUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  examId: Scalars['Int']['input'];
  isUpdateStrike: Scalars['Boolean']['input'];
  quizQuestionId: Scalars['ID']['input'];
  strike: Scalars['String']['input'];
  userQuizId: Scalars['ID']['input'];
};

export type QuizQuestionTimerInput = {
  questionId?: InputMaybe<Scalars['ID']['input']>;
  spendTime?: InputMaybe<Scalars['Int']['input']>;
  userQuizId: Scalars['ID']['input'];
};

export type QuizStartExamUpdateInput = {
  examId: Scalars['Int']['input'];
  isDirectionViewed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userQuizId: Scalars['ID']['input'];
};

export type QuizStrikeUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  isUpdateAnswer: Scalars['Boolean']['input'];
  quizQuestionId: Scalars['ID']['input'];
  strike: Scalars['String']['input'];
  userQuizId: Scalars['ID']['input'];
};

export type QuizStudentAnalyticstWithCount = {
  __typename?: 'QuizStudentAnalyticstWithCount';
  count: Scalars['Int']['output'];
  records: Array<Tutor>;
};

export type QuizzesWithCount = {
  __typename?: 'QuizzesWithCount';
  count: Scalars['Int']['output'];
  records: Array<Quiz>;
};

export type ResourceGroup = Node & {
  __typename?: 'ResourceGroup';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  files?: Maybe<Array<File>>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  isTrialAccess: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type SetupIntentCreateInput = {
  hasAgreedToStoreCardData: Scalars['Boolean']['input'];
};

export type SetupIntentResponse = {
  __typename?: 'SetupIntentResponse';
  clientSecret: Scalars['String']['output'];
};

export type ShowHideQuizTimerInput = {
  examId: Scalars['Int']['input'];
  isShowTimer?: InputMaybe<Scalars['Boolean']['input']>;
  userQuizId: Scalars['ID']['input'];
};

export type ShowHideTimerInput = {
  isShowTimer?: InputMaybe<Scalars['Boolean']['input']>;
  testId?: InputMaybe<Scalars['ID']['input']>;
};

export type State = Node & {
  __typename?: 'State';
  abbreviation?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type StrikeUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  isUpdateAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  questionId: Scalars['ID']['input'];
  strike: Scalars['String']['input'];
  testId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type StripeUser = {
  __typename?: 'StripeUser';
  comboData: Scalars['String']['output'];
  satData: Scalars['String']['output'];
};

export type StudentLessonsCount = {
  __typename?: 'StudentLessonsCount';
  completed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type StudentListWithCount = {
  __typename?: 'StudentListWithCount';
  count: Scalars['Int']['output'];
  records: Array<User>;
};

export type StudentWiseHomeworkWithCount = {
  __typename?: 'StudentWiseHomeworkWithCount';
  count: Scalars['Int']['output'];
  records: Array<Homework>;
};

export type StudentWiseQuizzesWithCount = {
  __typename?: 'StudentWiseQuizzesWithCount';
  count: Scalars['Int']['output'];
  records: Array<Quiz>;
};

export type Subject = Node & {
  __typename?: 'Subject';
  bookmarkedLessonCount: Scalars['Int']['output'];
  courseId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  lessonCount: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  playlists: Array<Playlist>;
  resourceGroups: Array<ResourceGroup>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type SubjectScores = {
  __typename?: 'SubjectScores';
  correct?: Maybe<Scalars['Int']['output']>;
  essayAnalysisScore?: Maybe<Scalars['Int']['output']>;
  essayReadingScore?: Maybe<Scalars['Int']['output']>;
  essayWritingScore?: Maybe<Scalars['Int']['output']>;
  missed?: Maybe<Scalars['Int']['output']>;
  omitted?: Maybe<Scalars['Int']['output']>;
  playlist: Playlist;
  score?: Maybe<Scalars['Int']['output']>;
  subject: Subject;
  subjectId: Scalars['ID']['output'];
  total?: Maybe<Scalars['Int']['output']>;
  userTestId: Scalars['ID']['output'];
};

export type SubjectScoresInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  answerType: TestSubjectAnswerEntryEnumType;
  correctQuestionNumbers: Array<Scalars['Int']['input']>;
  essayAnalysisScore?: InputMaybe<Scalars['Int']['input']>;
  essayReadingScore?: InputMaybe<Scalars['Int']['input']>;
  essayWritingScore?: InputMaybe<Scalars['Int']['input']>;
  missedQuestionNumbers: Array<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  omittedQuestionNumbers: Array<Scalars['Int']['input']>;
  subjectId: Scalars['ID']['input'];
};

export type Subscription = Node & {
  __typename?: 'Subscription';
  /** timestamp denoting when the subscription is scheduled to be canceled */
  cancelAt: Scalars['String']['output'];
  /** timestamp denoting if and when subscription was canceled */
  canceledAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  currentPlan?: Maybe<Scalars['String']['output']>;
  freeTrialActive: Scalars['Boolean']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  mailingMethod?: Maybe<MailingMethodEnum>;
  planDuration?: Maybe<Scalars['String']['output']>;
  selectedFreeTrial: Scalars['Boolean']['output'];
  shippedBooksAt?: Maybe<Scalars['String']['output']>;
  status: SubscriptionStatusEnum;
  stripeSubscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionActivateResponse = {
  __typename?: 'SubscriptionActivateResponse';
  totalPrice: Scalars['Float']['output'];
  user: User;
};

export type SubscriptionCreateInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  isTrial: Scalars['Boolean']['input'];
  mailingMethod: MailingMethodEnum;
  paymentMethodId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
};

export enum SubscriptionStatusEnum {
  Active = 'Active',
  Canceled = 'Canceled',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'Incomplete_expired',
  PastDue = 'Past_due',
  Trialing = 'Trialing',
  Unpaid = 'Unpaid'
}

export type SubscriptionStatusFilterArg = {
  status?: InputMaybe<Array<SubscriptionStatusEnum>>;
};

export type Tag = Node & {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  easyQuestionsCount: Scalars['Int']['output'];
  hardQuestionsCount: Scalars['Int']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  mediumQuestionsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  testTitle?: Maybe<Scalars['String']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
  unUsedQuestion?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type TagCategory = Node & {
  __typename?: 'TagCategory';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  tags?: Maybe<Array<Tag>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type TagCategoryListWithCount = {
  __typename?: 'TagCategoryListWithCount';
  count: Scalars['Int']['output'];
  records: Array<TagCategory>;
};

export type Test = Node & {
  __typename?: 'Test';
  answers: Array<TestAnswer>;
  courseId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  hasEssaySection: Scalars['Boolean']['output'];
  hasGradingRubrics: Scalars['Boolean']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  instructionStep1?: Maybe<Scalars['String']['output']>;
  instructionStep2?: Maybe<Scalars['String']['output']>;
  instructionStep3?: Maybe<Scalars['String']['output']>;
  instructionStep4?: Maybe<Scalars['String']['output']>;
  isBluebookPublished: Scalars['Boolean']['output'];
  isBreak: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  isTrialAccess: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  subjects: Array<Subject>;
  title: Scalars['String']['output'];
  totalQuestions: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userResults?: Maybe<UserTestResults>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type TestAnswer = Node & {
  __typename?: 'TestAnswer';
  annotate?: Maybe<Scalars['String']['output']>;
  answer: Scalars['String']['output'];
  attemptedStudents?: Maybe<Scalars['Int']['output']>;
  correctResponse?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  customQuestionNumber?: Maybe<Scalars['String']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  examId: Scalars['Int']['output'];
  explanation?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  instructionLesson?: Maybe<Lesson>;
  instructionLessonId?: Maybe<Scalars['ID']['output']>;
  isHighlight?: Maybe<Scalars['Boolean']['output']>;
  isMarked?: Maybe<Scalars['Boolean']['output']>;
  isMathCalc: Scalars['Boolean']['output'];
  isModify: Scalars['Boolean']['output'];
  mostMissedQuestions?: Maybe<Scalars['Int']['output']>;
  optionA?: Maybe<Scalars['String']['output']>;
  optionAResponse?: Maybe<Scalars['String']['output']>;
  optionB?: Maybe<Scalars['String']['output']>;
  optionBResponse?: Maybe<Scalars['String']['output']>;
  optionC?: Maybe<Scalars['String']['output']>;
  optionCResponse?: Maybe<Scalars['String']['output']>;
  optionD?: Maybe<Scalars['String']['output']>;
  optionDResponse?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionBankId?: Maybe<Scalars['Int']['output']>;
  questionLevel?: Maybe<Scalars['String']['output']>;
  questionMarks?: Maybe<Scalars['String']['output']>;
  questionNumber?: Maybe<Scalars['Int']['output']>;
  questionTagCategory?: Maybe<Scalars['String']['output']>;
  questionTime?: Maybe<Scalars['Int']['output']>;
  questionType?: Maybe<Scalars['String']['output']>;
  referenceDetails?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  sectionTitle: Scalars['String']['output'];
  serialNumber?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  strike?: Maybe<Scalars['String']['output']>;
  subject: Subject;
  subjectId: Scalars['ID']['output'];
  tag?: Maybe<Scalars['String']['output']>;
  tagId: Scalars['ID']['output'];
  tagTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  testBookTitle: Scalars['String']['output'];
  testId: Scalars['ID']['output'];
  testTitle: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userAnswer?: Maybe<TestAnswerEnum>;
  version?: Maybe<Scalars['Int']['output']>;
  vimeoId?: Maybe<Scalars['Int']['output']>;
  wrongResponse?: Maybe<Scalars['String']['output']>;
  wrongResponseCount?: Maybe<Scalars['Int']['output']>;
  yourAnswer?: Maybe<Scalars['String']['output']>;
};

export enum TestAnswerEnum {
  Correct = 'correct',
  Missed = 'missed',
  Omitted = 'omitted'
}

export type TestBook = Node & {
  __typename?: 'TestBook';
  answerKey: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  digitalSatTests: Array<TestBook>;
  dsatMaxScore?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  importTests: Array<TestBook>;
  isQuestionFor: Scalars['String']['output'];
  isTrialAccess: Scalars['Boolean']['output'];
  maxScore?: Maybe<Scalars['Int']['output']>;
  order: Scalars['Int']['output'];
  questionNumber?: Maybe<Scalars['Int']['output']>;
  sectionQuestion?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['Int']['output']>;
  tests: Array<Test>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userHasBook: Scalars['Boolean']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};


export type TestBookUserHasBookArgs = {
  userId: Scalars['ID']['input'];
};

export type TestFiltersArg = {
  className?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  test?: InputMaybe<Scalars['String']['input']>;
};

export type TestSection = Node & {
  __typename?: 'TestSection';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  testSections?: Maybe<Array<DigitalSatTestSection>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type TestSectionTestSectionsArgs = {
  title: Scalars['String']['input'];
};

export type TestStudentAnalyticstWithCount = {
  __typename?: 'TestStudentAnalyticstWithCount';
  count: Scalars['Int']['output'];
  records: Array<UserTestScore>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  height: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  link_with_play_button: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type TimeLogInformationListWithCount = {
  __typename?: 'TimeLogInformationListWithCount';
  count: Scalars['Int']['output'];
  records: Array<User>;
};

export type Tutor = Node & {
  __typename?: 'Tutor';
  classId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  date: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  homework?: Maybe<Array<Homework>>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  lastActiveAt?: Maybe<Scalars['String']['output']>;
  lastLoggedAt?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  practiceSetCorrectAnswer: Scalars['Int']['output'];
  quizId: Scalars['Int']['output'];
  quizQuestionsCount: Scalars['Int']['output'];
  quizWiseHomework: Array<Homework>;
  role?: Maybe<UserRoleEnum>;
  status: Scalars['String']['output'];
  studentAssignmentCount: StudentAssignmentCount;
  studentBluebookTestsCount: StudentBluebookTestsCount;
  studentClasses?: Maybe<Array<Classes>>;
  studentLessonsCount: StudentLessonsCount;
  studentQuiz?: Maybe<Array<Quiz>>;
  studentQuizCount: StudentQuizCount;
  studentTestsCount: StudentTestsCount;
  studentTutors?: Maybe<Array<User>>;
  subscriptions: Array<Subscription>;
  title: Scalars['String']['output'];
  totalCorrectAnswer: Scalars['Int']['output'];
  tutorClasses?: Maybe<Array<Classes>>;
  tutorHomeworks?: Maybe<Array<Homework>>;
  tutorQuizzes?: Maybe<Array<Quiz>>;
  tutorStudents?: Maybe<Array<User>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  videoId?: Maybe<Scalars['ID']['output']>;
};


export type TutorSubscriptionsArgs = {
  filter?: InputMaybe<SubscriptionStatusFilterArg>;
};

export type TutorCreateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hasAgreedToMailchimps: Scalars['Boolean']['input'];
  hasAgreedToTermsConds: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
};

export type TutorFiltersArg = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Array<ProductIdEnum>>;
};

export type TutorOrderByArg = {
  column: TutorOrderByEnum;
  order: OrderEnum;
};

export enum TutorOrderByEnum {
  CreatedAt = 'createdAt',
  Email = 'email'
}

export type TutorsWithCount = {
  __typename?: 'TutorsWithCount';
  count: Scalars['Int']['output'];
  records: Array<Tutor>;
};

export enum TypeEnum {
  DigitalSat = 'DigitalSat',
  PracticeSet = 'PracticeSet',
  QuestionBank = 'QuestionBank'
}

export type User = Node & {
  __typename?: 'User';
  action?: Maybe<Scalars['String']['output']>;
  activeCourses: Array<Course>;
  activeRole?: Maybe<UserRoleEnum>;
  activeSchedules?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  allBooksShipped: Scalars['Boolean']['output'];
  cardLastFourDigits?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hasSetPassword: Scalars['Boolean']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  invitationStatus?: Maybe<Scalars['Boolean']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isCard?: Maybe<Scalars['Boolean']['output']>;
  isFeedback?: Maybe<Scalars['Boolean']['output']>;
  isNotification?: Maybe<Scalars['Boolean']['output']>;
  isTutorInvited: Scalars['Boolean']['output'];
  isTutorUser: Scalars['Boolean']['output'];
  lastActiveAt?: Maybe<Scalars['String']['output']>;
  lastLoggedAt?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  lessonsMarkedCompleted: Scalars['String']['output'];
  nextTestAt?: Maybe<Scalars['String']['output']>;
  nextTestCourse?: Maybe<Course>;
  nextTestCourseId?: Maybe<Scalars['Int']['output']>;
  nextTestIsNational?: Maybe<Scalars['Boolean']['output']>;
  parentEmail?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  quizAlert: Scalars['Boolean']['output'];
  quizzesAttempted: Scalars['String']['output'];
  role?: Maybe<UserRoleEnum>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<Subscription>;
  surveyInfo?: Maybe<Scalars['String']['output']>;
  testsEntered: Scalars['String']['output'];
  upcomingNotification: Array<Notifications>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type UserSubscriptionsArgs = {
  filter?: InputMaybe<SubscriptionStatusFilterArg>;
};

export type UserCreateAdminInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type UserCreateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hasAgreedToMailchimps: Scalars['Boolean']['input'];
  hasAgreedToTermsConds: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
};

export type UserFiltersArg = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRoleEnum>>;
  subscriptions?: InputMaybe<Array<ProductIdEnum>>;
};

export type UserInitialUpdateInput = {
  address: AddressCreateInput;
  parentEmail?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  userMailingMethod?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrderByArg = {
  column: UserOrderByEnum;
  order: OrderEnum;
};

export enum UserOrderByEnum {
  AllBooksShipped = 'allBooksShipped',
  CreatedAt = 'createdAt',
  Email = 'email'
}

export enum UserRoleEnum {
  Admin = 'Admin',
  CourseEditor = 'Course_Editor',
  Subscriber = 'Subscriber',
  Tutor = 'Tutor'
}

export type UserTestDSatSnippet = {
  __typename?: 'UserTestDSatSnippet';
  bluebookMathScore?: Maybe<Scalars['String']['output']>;
  bluebookReadingAndWritingScore?: Maybe<Scalars['String']['output']>;
  bluebookSection1MathMissed?: Maybe<Scalars['String']['output']>;
  bluebookSection1MathOmmited?: Maybe<Scalars['String']['output']>;
  bluebookSection1ReadingAndWritingMissed?: Maybe<Scalars['String']['output']>;
  bluebookSection1ReadingAndWritingOmmited?: Maybe<Scalars['String']['output']>;
  bluebookSection2EasyMathMissed?: Maybe<Scalars['String']['output']>;
  bluebookSection2EasyMathOmmited?: Maybe<Scalars['String']['output']>;
  bluebookSection2EasyReadingAndWritingMissed?: Maybe<Scalars['String']['output']>;
  bluebookSection2EasyReadingAndWritingOmmited?: Maybe<Scalars['String']['output']>;
  bluebookSection2HardMathMissed?: Maybe<Scalars['String']['output']>;
  bluebookSection2HardMathOmmited?: Maybe<Scalars['String']['output']>;
  bluebookSection2HardReadingAndWritingMissed?: Maybe<Scalars['String']['output']>;
  bluebookSection2HardReadingAndWritingOmmited?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  digitalSatSection1MathMissed?: Maybe<Scalars['String']['output']>;
  digitalSatSection1MathOmmited?: Maybe<Scalars['String']['output']>;
  digitalSatSection1MathScore?: Maybe<Scalars['String']['output']>;
  digitalSatSection1ReadingAndWritingMissed?: Maybe<Scalars['String']['output']>;
  digitalSatSection1ReadingAndWritingOmmited?: Maybe<Scalars['String']['output']>;
  digitalSatSection1ReadingAndWritingScore?: Maybe<Scalars['String']['output']>;
  digitalSatSection2EasyMathMissed?: Maybe<Scalars['String']['output']>;
  digitalSatSection2EasyMathOmmited?: Maybe<Scalars['String']['output']>;
  digitalSatSection2EasyMathScore?: Maybe<Scalars['String']['output']>;
  digitalSatSection2EasyReadingAndWritingMissed?: Maybe<Scalars['String']['output']>;
  digitalSatSection2EasyReadingAndWritingOmmited?: Maybe<Scalars['String']['output']>;
  digitalSatSection2EasyReadingAndWritingScore?: Maybe<Scalars['String']['output']>;
  digitalSatSection2HardMathMissed?: Maybe<Scalars['String']['output']>;
  digitalSatSection2HardMathOmmited?: Maybe<Scalars['String']['output']>;
  digitalSatSection2HardMathScore?: Maybe<Scalars['String']['output']>;
  digitalSatSection2HardReadingAndWritingMissed?: Maybe<Scalars['String']['output']>;
  digitalSatSection2HardReadingAndWritingOmmited?: Maybe<Scalars['String']['output']>;
  digitalSatSection2HardReadingAndWritingScore?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  testTakenDate?: Maybe<Scalars['String']['output']>;
  testTitle?: Maybe<Scalars['String']['output']>;
};

export type UserTestResults = {
  __typename?: 'UserTestResults';
  accomodation: Scalars['String']['output'];
  breakTimeRemaining: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  currentQuestionId?: Maybe<Scalars['Int']['output']>;
  examId: Scalars['Int']['output'];
  examStatus: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  individualSubjectScores: Array<SubjectScores>;
  isDirectionViewed: Scalars['Boolean']['output'];
  isShowTimer: Scalars['Boolean']['output'];
  overAllScore: Scalars['Int']['output'];
  remainingSeconds: Scalars['Int']['output'];
  sectionId: Scalars['ID']['output'];
  sectionTitle: Scalars['String']['output'];
  testId: Scalars['ID']['output'];
  testTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalCorrect: Scalars['Int']['output'];
  totalMissed: Scalars['Int']['output'];
  totalOmitted: Scalars['Int']['output'];
  totalScore?: Maybe<Scalars['Float']['output']>;
  userId: Scalars['ID']['output'];
  userInput?: Maybe<Array<LastUserInput>>;
};

export type UserTestResultsCreateInput = {
  subjectScores: Array<SubjectScoresInput>;
  testId: Scalars['ID']['input'];
};

export type UserTestResultsCreateInputs = {
  subjectScores: Array<SubjectScoresInput>;
  testBookId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
};

export type UserTestScore = Node & {
  __typename?: 'UserTestScore';
  all?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  easyCorrectAnswerPercentage?: Maybe<Scalars['Int']['output']>;
  examId?: Maybe<Scalars['String']['output']>;
  hardCorrectAnswerPercentage?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isAnswerCorrect?: Maybe<Scalars['String']['output']>;
  math?: Maybe<Scalars['String']['output']>;
  mathDifficultyCorrectAnswer?: Maybe<UserTestScore>;
  mathGraphData?: Maybe<Scalars['String']['output']>;
  mathLowestScore?: Maybe<Scalars['Int']['output']>;
  mathOne?: Maybe<Array<TestAnswer>>;
  mathScore?: Maybe<Scalars['Float']['output']>;
  mathSection?: Maybe<Scalars['String']['output']>;
  mathTotalObtainedScore?: Maybe<Scalars['Int']['output']>;
  mathTotalObtainedScoreSectionOne?: Maybe<Scalars['Int']['output']>;
  mathTotalObtainedScoreSectionTwo?: Maybe<Scalars['Int']['output']>;
  mathTotalScore?: Maybe<Scalars['Int']['output']>;
  mathTwoEasy?: Maybe<Array<TestAnswer>>;
  mathTwoHard?: Maybe<Array<TestAnswer>>;
  mediumCorrectAnswerPercentage?: Maybe<Scalars['Int']['output']>;
  reading?: Maybe<Scalars['String']['output']>;
  readingDifficultyCorrectAnswer?: Maybe<UserTestScore>;
  readingGraphData?: Maybe<Scalars['String']['output']>;
  readingLowestScore?: Maybe<Scalars['Int']['output']>;
  readingOne?: Maybe<Array<TestAnswer>>;
  readingScore?: Maybe<Scalars['Float']['output']>;
  readingSection?: Maybe<Scalars['String']['output']>;
  readingTotalObtainedScore?: Maybe<Scalars['Float']['output']>;
  readingTotalObtainedScoreSectionOne?: Maybe<Scalars['Int']['output']>;
  readingTotalObtainedScoreSectionTwo?: Maybe<Scalars['Int']['output']>;
  readingTotalScore?: Maybe<Scalars['Int']['output']>;
  readingTwoEasy?: Maybe<Array<TestAnswer>>;
  readingTwoHard?: Maybe<Array<TestAnswer>>;
  student?: Maybe<Tutor>;
  students?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['ID']['output']>;
  tagCategoryCorrectIncorrectAnswer?: Maybe<Scalars['String']['output']>;
  testBookId?: Maybe<Scalars['ID']['output']>;
  testBookTitle?: Maybe<Scalars['String']['output']>;
  testId?: Maybe<Scalars['ID']['output']>;
  testTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalLowestScore?: Maybe<Scalars['Int']['output']>;
  totalObtainedScore?: Maybe<Scalars['Float']['output']>;
  totalQuestions?: Maybe<Scalars['Int']['output']>;
  totalResponse?: Maybe<Scalars['Int']['output']>;
  totalScore: Scalars['Float']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type UserTestSnippet = {
  __typename?: 'UserTestSnippet';
  actEnglishMissed?: Maybe<Scalars['String']['output']>;
  actEnglishOmitted?: Maybe<Scalars['String']['output']>;
  actEnglishScore?: Maybe<Scalars['Int']['output']>;
  actEssayMissed?: Maybe<Scalars['String']['output']>;
  actEssayOmitted?: Maybe<Scalars['String']['output']>;
  actEssayScore?: Maybe<Scalars['Int']['output']>;
  actMathMissed?: Maybe<Scalars['String']['output']>;
  actMathOmitted?: Maybe<Scalars['String']['output']>;
  actMathScore?: Maybe<Scalars['Int']['output']>;
  actReadingMissed?: Maybe<Scalars['String']['output']>;
  actReadingOmitted?: Maybe<Scalars['String']['output']>;
  actReadingScore?: Maybe<Scalars['Int']['output']>;
  actScienceMissed?: Maybe<Scalars['String']['output']>;
  actScienceOmitted?: Maybe<Scalars['String']['output']>;
  actScienceScore?: Maybe<Scalars['Int']['output']>;
  actTotalScore?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  satMathCalcMissed?: Maybe<Scalars['String']['output']>;
  satMathCalcOmitted?: Maybe<Scalars['String']['output']>;
  satMathMissed?: Maybe<Scalars['String']['output']>;
  satMathOmitted?: Maybe<Scalars['String']['output']>;
  satMathScore?: Maybe<Scalars['Int']['output']>;
  satReadingMissed?: Maybe<Scalars['String']['output']>;
  satReadingOmitted?: Maybe<Scalars['String']['output']>;
  satReadingScore?: Maybe<Scalars['Int']['output']>;
  satTotalScore?: Maybe<Scalars['String']['output']>;
  satWritingLanguageMissed?: Maybe<Scalars['String']['output']>;
  satWritingLanguageOmitted?: Maybe<Scalars['String']['output']>;
  satWritingLanguageScore?: Maybe<Scalars['Int']['output']>;
  testTakenDate?: Maybe<Scalars['String']['output']>;
  testTitle?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateInput = {
  address?: InputMaybe<AddressUpdateInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasShippedBooks?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  parentEmail?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoleEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UsersWithCount = {
  __typename?: 'UsersWithCount';
  count: Scalars['Int']['output'];
  records: Array<User>;
};

export type Video = Node & {
  __typename?: 'Video';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  hasSeen: Scalars['Boolean']['output'];
  hasUnderstood: Scalars['Boolean']['output'];
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  lengthInSeconds: Scalars['Int']['output'];
  lessonId: Scalars['ID']['output'];
  mode?: Maybe<Scalars['String']['output']>;
  thumbnailLinkUrl: Scalars['String']['output'];
  thumbnails: Array<Thumbnail>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  vimeoId: Scalars['Int']['output'];
};


export type VideoThumbnailLinkUrlArgs = {
  minWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type AllDigitalSatQuestions = Node & {
  __typename?: 'allDigitalSatQuestions';
  allQuestions?: Maybe<Array<TestAnswer>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  currentQuestion?: Maybe<TestAnswer>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type AllDigitalSatQuestionsAllQuestionsArgs = {
  userTestId: Scalars['ID']['input'];
};


export type AllDigitalSatQuestionsCurrentQuestionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userTestId: Scalars['ID']['input'];
};

export type AllQuizQuestions = Node & {
  __typename?: 'allQuizQuestions';
  allQuestions?: Maybe<Array<Quiz>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  currentQuestion?: Maybe<Quiz>;
  /** Unique identifier for the resource */
  id: Scalars['ID']['output'];
  quizDetails?: Maybe<Quiz>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type AllQuizQuestionsAllQuestionsArgs = {
  userQuizId: Scalars['ID']['input'];
};


export type AllQuizQuestionsCurrentQuestionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userQuizId: Scalars['ID']['input'];
  userQuizQuestionId?: InputMaybe<Scalars['ID']['input']>;
};

export type ClassesDetails = {
  __typename?: 'classesDetails';
  records: Array<Classes>;
};

export type StudentAssignmentCount = {
  __typename?: 'studentAssignmentCount';
  completed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type StudentBluebookTestsCount = {
  __typename?: 'studentBluebookTestsCount';
  completed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type StudentQuizCount = {
  __typename?: 'studentQuizCount';
  completed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type StudentTestsCount = {
  __typename?: 'studentTestsCount';
  completed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export enum TestSubjectAnswerEntryEnumType {
  Enter = 'enter',
  Perfect = 'perfect',
  Skip = 'skip'
}

export type TutorHomeworkReport = {
  __typename?: 'tutorHomeworkReport';
  record: Scalars['String']['output'];
};

export type AddressDataFragment = { __typename?: 'Address', id: string, street: string, postCode: string, city: string, state?: { __typename?: 'State', id: string, name: string, abbreviation?: string | null } | null, country: { __typename?: 'Country', id: string, name: string, hasStates: boolean } };

export type CountryDataFragment = { __typename?: 'Country', id: string, name: string, hasStates: boolean };

export type CourseDataFragment = { __typename?: 'Course', id: string, title: string, subjects: Array<{ __typename?: 'Subject', id: string, title: string, order: number }>, testBooks?: Array<{ __typename?: 'TestBook', id: string, title: string, order: number }> | null };

export type FileDataFragment = { __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean };

export type LessonDataFragment = { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> };

export type LessonDataExtendedFragment = { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, playlist?: { __typename?: 'Playlist', id: string, title: string } | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }>, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null };

export type PlaylistDataFragment = { __typename?: 'Playlist', id: string, title: string, isPublished: boolean, lessons: Array<{ __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> }> };

export type PlaylistLessonsDataFragment = { __typename?: 'Playlist', lessons: Array<{ __typename?: 'Lesson', title: string, isTrialAccess: boolean, isBookmarked: boolean }> };

export type ResourceGroupDataFragment = { __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null };

export type StateDataFragment = { __typename?: 'State', id: string, name: string, abbreviation?: string | null };

export type SubjectResourceGroupDataFragment = { __typename?: 'Subject', id: string, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> };

export type SubjectBaseDataFragment = { __typename?: 'Subject', id: string, title: string, order: number };

export type SubjectDataFragment = { __typename?: 'Subject', id: string, title: string, lessonCount: number, playlists: Array<{ __typename?: 'Playlist', id: string, title: string, order: number }>, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> };

export type SubjectScoresDataFragment = { __typename?: 'SubjectScores', correct?: number | null, omitted?: number | null, missed?: number | null, score?: number | null, subject: { __typename?: 'Subject', id: string, title: string, lessonCount: number, playlists: Array<{ __typename?: 'Playlist', id: string, title: string, order: number }>, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> } };

export type SubscriptionDataFragment = { __typename?: 'Subscription', id: string, title: string, createdAt?: string | null, canceledAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, stripeSubscriptionId: string, mailingMethod?: MailingMethodEnum | null };

export type TagDataFragment = { __typename?: 'Tag', id: string, name: string };

export type TestDataFragment = { __typename?: 'Test', id: string, title: string, isPublished: boolean, isBluebookPublished: boolean, isTrialAccess: boolean, totalQuestions: number, instructionStep1?: string | null, instructionStep2?: string | null, instructionStep3?: string | null, instructionStep4?: string | null, subjects: Array<{ __typename?: 'Subject', id: string, title: string, order: number }>, answers: Array<{ __typename?: 'TestAnswer', id: string, isMathCalc: boolean, questionNumber?: number | null, answer: string, status?: string | null, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, questionType?: string | null, difficultyLevel?: string | null, score?: string | null, referenceDetails?: string | null, explanation?: string | null, questionMarks?: string | null, question?: string | null, type?: string | null, serialNumber?: number | null, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, instructionLesson?: { __typename?: 'Lesson', id: string, title: string, subject: { __typename?: 'Subject', id: string, title: string, order: number } } | null }> };

export type TestAnswerDataFragment = { __typename?: 'TestAnswer', id: string, isMathCalc: boolean, questionNumber?: number | null, answer: string, status?: string | null, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, questionType?: string | null, difficultyLevel?: string | null, score?: string | null, referenceDetails?: string | null, explanation?: string | null, questionMarks?: string | null, question?: string | null, type?: string | null, serialNumber?: number | null, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, instructionLesson?: { __typename?: 'Lesson', id: string, title: string, subject: { __typename?: 'Subject', id: string, title: string, order: number } } | null };

export type TestBookDataFragment = { __typename?: 'TestBook', id: string, title: string, tests: Array<{ __typename?: 'Test', id: string, title: string }>, digitalSatTests: Array<{ __typename?: 'TestBook', id: string, title: string }>, importTests: Array<{ __typename?: 'TestBook', id: string, title: string }> };

export type ThumbnailDataFragment = { __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string };

export type UserDataFragment = { __typename?: 'User', id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean };

export type UserDataExtendedFragment = { __typename?: 'User', updatedAt?: string | null, cardLastFourDigits?: number | null, phoneNumber?: string | null, parentEmail?: string | null, id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean, address?: { __typename?: 'Address', id: string, street: string, postCode: string, city: string, state?: { __typename?: 'State', id: string, name: string, abbreviation?: string | null } | null, country: { __typename?: 'Country', id: string, name: string, hasStates: boolean } } | null, subscriptions: Array<{ __typename?: 'Subscription', shippedBooksAt?: string | null, id: string, title: string, createdAt?: string | null, canceledAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, stripeSubscriptionId: string, mailingMethod?: MailingMethodEnum | null }> };

export type VideoDataFragment = { __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> };

export type QuestionBankDataFragment = { __typename?: 'QuestionBank', id: string, question: string, answer: string, type?: string | null, testTitle: string, questionType: string, optionA: string, optionB: string, optionC: string, optionD: string, difficultyLevel: string, referenceDetails: string, explanation: string, status: string, serialNumber: string, questionTags: string, vimeoId?: number | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null };

export type ToggleLessonTagMutationVariables = Exact<{
  lessonId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
}>;


export type ToggleLessonTagMutation = { __typename?: 'Mutation', tagToggleAssignLesson: { __typename?: 'Lesson', id: string, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null } };

export type LessonUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  isTrialAccess?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type LessonUpdateMutation = { __typename?: 'Mutation', lessonUpdate: { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean } };

export type SubscriptionCancelMutationVariables = Exact<{
  stripeSubscriptionId: Scalars['String']['input'];
}>;


export type SubscriptionCancelMutation = { __typename?: 'Mutation', subscriptionCancel: { __typename?: 'Subscription', id: string, title: string, createdAt?: string | null, canceledAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, stripeSubscriptionId: string, mailingMethod?: MailingMethodEnum | null } };

export type SubscriptionUpdateMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  hasShippedBooks?: InputMaybe<Scalars['Boolean']['input']>;
  newCancelAtDate?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SubscriptionUpdateMutation = { __typename?: 'Mutation', subscriptionUpdate: { __typename?: 'User', id: string, allBooksShipped: boolean, subscriptions: Array<{ __typename?: 'Subscription', id: string, shippedBooksAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, canceledAt?: string | null }> } };

export type TestUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isBluebookPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isTrialAccess?: InputMaybe<Scalars['Boolean']['input']>;
  instructionStep1?: InputMaybe<Scalars['String']['input']>;
  instructionStep2?: InputMaybe<Scalars['String']['input']>;
  instructionStep3?: InputMaybe<Scalars['String']['input']>;
  instructionStep4?: InputMaybe<Scalars['String']['input']>;
}>;


export type TestUpdateMutation = { __typename?: 'Mutation', testUpdate: { __typename?: 'Test', id: string, title: string, isPublished: boolean, isBluebookPublished: boolean, isTrialAccess: boolean, instructionStep1?: string | null, instructionStep2?: string | null, instructionStep3?: string | null, instructionStep4?: string | null } };

export type PlaylistUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PlaylistUpdateMutation = { __typename?: 'Mutation', playlistUpdate: { __typename?: 'Playlist', id: string, title: string, isPublished: boolean } };

export type SetPlaylistLessonsMutationVariables = Exact<{
  playlistId: Scalars['ID']['input'];
  lessonIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type SetPlaylistLessonsMutation = { __typename?: 'Mutation', setPlaylistLessons: { __typename?: 'Playlist', id: string, lessons: Array<{ __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> }> } };

export type TagCreateMutationVariables = Exact<{
  courseId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type TagCreateMutation = { __typename?: 'Mutation', tagCreate: { __typename?: 'Tag', id: string, name: string } };

export type TagDeleteMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
}>;


export type TagDeleteMutation = { __typename?: 'Mutation', tagDelete?: boolean | null };

export type FileCreateLessonMutationVariables = Exact<{
  title: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  lessonId: Scalars['ID']['input'];
}>;


export type FileCreateLessonMutation = { __typename?: 'Mutation', fileCreateLesson: { __typename?: 'Lesson', id: string, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null } };

export type FileDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FileDeleteMutation = { __typename?: 'Mutation', fileDelete?: { __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean } | null };

export type VideoCreateMutationVariables = Exact<{
  vimeoId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  lessonId: Scalars['ID']['input'];
}>;


export type VideoCreateMutation = { __typename?: 'Mutation', videoCreate: { __typename?: 'Lesson', id: string, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> } };

export type VideoUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type VideoUpdateMutation = { __typename?: 'Mutation', videoUpdate: { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> } };

export type VideoDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type VideoDeleteMutation = { __typename?: 'Mutation', videoDelete: { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> } };

export type VideosReorderMutationVariables = Exact<{
  lessonId: Scalars['ID']['input'];
  videoIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type VideosReorderMutation = { __typename?: 'Mutation', lessonUpdateVideoOrder: { __typename?: 'Lesson', id: string, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> } };

export type PlaylistCreateMutationVariables = Exact<{
  playlistTitle: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
  lessonIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  testId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type PlaylistCreateMutation = { __typename?: 'Mutation', playlistCreatePublic: { __typename?: 'Playlist', id: string, title: string, isPublished: boolean, lessons: Array<{ __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> }> } };

export type LessonCreateMutationVariables = Exact<{
  lessonTitle: Scalars['String']['input'];
  vimeoIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  playlistId?: InputMaybe<Scalars['ID']['input']>;
  testAnswerId?: InputMaybe<Scalars['ID']['input']>;
  isTrialAccess: Scalars['Boolean']['input'];
  subjectId: Scalars['ID']['input'];
}>;


export type LessonCreateMutation = { __typename?: 'Mutation', lessonCreate: { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> } };

export type ResourceGroupCreateMutationVariables = Exact<{
  title: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
  isTrialAccess: Scalars['Boolean']['input'];
}>;


export type ResourceGroupCreateMutation = { __typename?: 'Mutation', resourceGroupCreate: { __typename?: 'Subject', id: string, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> } };

export type ResourceGroupUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isTrialAccess?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ResourceGroupUpdateMutation = { __typename?: 'Mutation', resourceGroupUpdate: { __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null } };

export type FileCreateResourceGroupMutationVariables = Exact<{
  title: Scalars['String']['input'];
  resourceGroupId: Scalars['ID']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
}>;


export type FileCreateResourceGroupMutation = { __typename?: 'Mutation', fileCreateResourceGroup: { __typename?: 'ResourceGroup', id: string, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null } };

export type ToggleTestAnswerTagMutationVariables = Exact<{
  testAnswerId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
}>;


export type ToggleTestAnswerTagMutation = { __typename?: 'Mutation', tagToggleAssignTestAnswer: { __typename?: 'TestAnswer', id: string, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null } };

export type UserUpdateMutationVariables = Exact<{
  input: UserUpdateInput;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', updatedAt?: string | null, cardLastFourDigits?: number | null, phoneNumber?: string | null, parentEmail?: string | null, id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean, address?: { __typename?: 'Address', id: string, street: string, postCode: string, city: string, state?: { __typename?: 'State', id: string, name: string, abbreviation?: string | null } | null, country: { __typename?: 'Country', id: string, name: string, hasStates: boolean } } | null, subscriptions: Array<{ __typename?: 'Subscription', shippedBooksAt?: string | null, id: string, title: string, createdAt?: string | null, canceledAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, stripeSubscriptionId: string, mailingMethod?: MailingMethodEnum | null }> } };

export type UserSetPasswordMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UserSetPasswordMutation = { __typename?: 'Mutation', adminSetUserPassword: boolean };

export type UserDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', userDeleteById: string };

export type UserCreateMutationVariables = Exact<{
  input: UserCreateAdminInput;
}>;


export type UserCreateMutation = { __typename?: 'Mutation', userCreateAdmin: { __typename?: 'User', updatedAt?: string | null, cardLastFourDigits?: number | null, phoneNumber?: string | null, parentEmail?: string | null, id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean, address?: { __typename?: 'Address', id: string, street: string, postCode: string, city: string, state?: { __typename?: 'State', id: string, name: string, abbreviation?: string | null } | null, country: { __typename?: 'Country', id: string, name: string, hasStates: boolean } } | null, subscriptions: Array<{ __typename?: 'Subscription', shippedBooksAt?: string | null, id: string, title: string, createdAt?: string | null, canceledAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, stripeSubscriptionId: string, mailingMethod?: MailingMethodEnum | null }> } };

export type DigitalSatCreateMutationVariables = Exact<{
  input: DigitalSatCreateInput;
}>;


export type DigitalSatCreateMutation = { __typename?: 'Mutation', digitalSatCreate: { __typename?: 'DigitalSAT', title: string } };

export type TestBookUserToggleMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  testBookId: Scalars['ID']['input'];
}>;


export type TestBookUserToggleMutation = { __typename?: 'Mutation', testBookUserToggle: { __typename?: 'TestBook', id: string, title: string, userHasBook: boolean } };

export type SetPlaylistOrderMutationVariables = Exact<{
  playlistIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
}>;


export type SetPlaylistOrderMutation = { __typename?: 'Mutation', setPlaylistOrder: { __typename?: 'Subject', id: string, title: string, lessonCount: number, playlists: Array<{ __typename?: 'Playlist', id: string, title: string, order: number }>, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> } };

export type SetSubjectOrderMutationVariables = Exact<{
  courseId: Scalars['ID']['input'];
  subjectIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type SetSubjectOrderMutation = { __typename?: 'Mutation', setSubjectOrder: { __typename?: 'Course', id: string, title: string, subjects: Array<{ __typename?: 'Subject', id: string, title: string, order: number }>, testBooks?: Array<{ __typename?: 'TestBook', id: string, title: string, order: number }> | null } };

export type SetTestOrderMutationVariables = Exact<{
  courseId: Scalars['ID']['input'];
  testBookIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type SetTestOrderMutation = { __typename?: 'Mutation', setTestOrder: { __typename?: 'Course', id: string, title: string, subjects: Array<{ __typename?: 'Subject', id: string, title: string, order: number }>, testBooks?: Array<{ __typename?: 'TestBook', id: string, title: string, order: number }> | null } };

export type DigitalSatTestAnswerCreateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  testId: Scalars['ID']['input'];
  question: Scalars['String']['input'];
  answer: Scalars['String']['input'];
  questionType: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
  optionA: Scalars['String']['input'];
  optionB: Scalars['String']['input'];
  optionC: Scalars['String']['input'];
  optionD: Scalars['String']['input'];
  difficultyLevel: Scalars['String']['input'];
  score: Scalars['String']['input'];
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  questionMarks: Scalars['String']['input'];
  status: Scalars['String']['input'];
  tagIds?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  vimeoId?: InputMaybe<Scalars['Int']['input']>;
  isSaveToQuestionbank: Scalars['Boolean']['input'];
  questionBankId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DigitalSatTestAnswerCreateMutation = { __typename?: 'Mutation', digitalSatTestAnswerCreate: { __typename?: 'TestAnswer', id: string, testId: string, question?: string | null, answer: string, subjectId: string, questionType?: string | null, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, difficultyLevel?: string | null, score?: string | null, referenceDetails?: string | null, explanation?: string | null, questionMarks?: string | null, status?: string | null } };

export type FaqCreateMutationVariables = Exact<{
  input: FaqCreateInput;
}>;


export type FaqCreateMutation = { __typename?: 'Mutation', faqCreate: { __typename?: 'FAQ', id: string, question: string, answer: string, order: number, status: boolean } };

export type FaqsDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FaqsDeleteMutation = { __typename?: 'Mutation', faqDeleteById: string };

export type TestAnswerDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TestAnswerDeleteMutation = { __typename?: 'Mutation', testAnswerDeleteById: { __typename?: 'Test', id: string } };

export type FaqStatusUpdateMutationVariables = Exact<{
  input: FaqInput;
}>;


export type FaqStatusUpdateMutation = { __typename?: 'Mutation', faqStatusUpdate: { __typename?: 'FAQ', id: string, status: boolean } };

export type SetFaqOrderMutationVariables = Exact<{
  faqIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type SetFaqOrderMutation = { __typename?: 'Mutation', setFaqOrder: { __typename?: 'FAQ', order: number, status: boolean, question: string, answer: string } };

export type DigitalSatQuestionStatusUpdateMutationVariables = Exact<{
  input: DigitalSatQuestionStatusUpdateInput;
}>;


export type DigitalSatQuestionStatusUpdateMutation = { __typename?: 'Mutation', digitalSatQuestionStatusUpdate: { __typename?: 'TestAnswer', id: string, status?: string | null } };

export type DigitalSatImportQuestionCreateMutationVariables = Exact<{
  testId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
  uploadFile: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type DigitalSatImportQuestionCreateMutation = { __typename?: 'Mutation', digitalSatImportQuestionCreate: { __typename?: 'TestAnswer', id: string } };

export type DigitalSatPracticeSetCreateMutationVariables = Exact<{
  title: Scalars['String']['input'];
  mode: Scalars['String']['input'];
  lessonId: Scalars['ID']['input'];
  vimeoId: Scalars['Int']['input'];
  difficultyLevel: Scalars['String']['input'];
  isCompulsion: Scalars['Boolean']['input'];
}>;


export type DigitalSatPracticeSetCreateMutation = { __typename?: 'Mutation', digitalSatPracticeSetCreate: { __typename?: 'Lesson', id: string } };

export type DigitalSatPracticeSetCreateQuestionMutationVariables = Exact<{
  lessonId: Scalars['ID']['input'];
  videoId: Scalars['ID']['input'];
  vimeoId?: InputMaybe<Scalars['Int']['input']>;
  question: Scalars['String']['input'];
  answer: Scalars['String']['input'];
  optionA: Scalars['String']['input'];
  optionB: Scalars['String']['input'];
  optionC: Scalars['String']['input'];
  optionD: Scalars['String']['input'];
  explanation?: InputMaybe<Scalars['String']['input']>;
  questionType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isSaveToQuestionbank: Scalars['Boolean']['input'];
  questionBankId?: InputMaybe<Scalars['Int']['input']>;
  tagIds?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
}>;


export type DigitalSatPracticeSetCreateQuestionMutation = { __typename?: 'Mutation', digitalSatPracticeSetCreateQuestion: { __typename?: 'Lesson', id: string } };

export type DigitalSatPracticeSetDeleteQuestionMutationVariables = Exact<{
  practiceSetId: Scalars['ID']['input'];
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type DigitalSatPracticeSetDeleteQuestionMutation = { __typename?: 'Mutation', digitalSatPracticeSetDeleteQuestion?: { __typename?: 'Lesson', id: string } | null };

export type DigitalSatPracticeSetIsPulishedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isPublished: Scalars['Boolean']['input'];
}>;


export type DigitalSatPracticeSetIsPulishedMutation = { __typename?: 'Mutation', digitalSatPracticeSetIsPulished?: boolean | null };

export type PracticeSetImportQuestionMutationVariables = Exact<{
  videoId: Scalars['ID']['input'];
  uploadFile: Scalars['String']['input'];
}>;


export type PracticeSetImportQuestionMutation = { __typename?: 'Mutation', practiceSetImportQuestion?: { __typename?: 'Lesson', id: string } | null };

export type CriteriaManuallyEntryUpdateMutationVariables = Exact<{
  input: CriteriaManuallyEntryUpdateInput;
}>;


export type CriteriaManuallyEntryUpdateMutation = { __typename?: 'Mutation', criteriaManuallyEntryUpdate: { __typename?: 'Test', id: string } };

export type QuestionBankCreateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  question: Scalars['String']['input'];
  answer: Scalars['String']['input'];
  questionType: Scalars['String']['input'];
  testTitle: Scalars['String']['input'];
  optionA: Scalars['String']['input'];
  optionB: Scalars['String']['input'];
  optionC: Scalars['String']['input'];
  optionD: Scalars['String']['input'];
  difficultyLevel: Scalars['String']['input'];
  referenceDetails?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  vimeoId?: InputMaybe<Scalars['Int']['input']>;
  questionTags: Scalars['String']['input'];
  tagIds?: InputMaybe<Scalars['String']['input']>;
}>;


export type QuestionBankCreateMutation = { __typename?: 'Mutation', questionBankCreate: { __typename?: 'QuestionBank', id: string, questionTags: string, question: string, answer: string, testTitle: string, questionType: string, optionA: string, optionB: string, optionC: string, optionD: string, difficultyLevel: string, referenceDetails: string, explanation: string, status: string, serialNumber: string } };

export type QuestionBankDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type QuestionBankDeleteMutation = { __typename?: 'Mutation', questionBankDelete?: { __typename?: 'QuestionBank', id: string } | null };

export type QuestionBankStatusUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
}>;


export type QuestionBankStatusUpdateMutation = { __typename?: 'Mutation', questionBankStatusUpdate: boolean };

export type QuestionBankSearchMutationVariables = Exact<{
  searchText: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type QuestionBankSearchMutation = { __typename?: 'Mutation', questionBankSearch: Array<{ __typename?: 'QuestionBank', id: string, question: string, answer: string, type?: string | null, testTitle: string, questionType: string, optionA: string, optionB: string, optionC: string, optionD: string, difficultyLevel: string, referenceDetails: string, explanation: string, status: string, serialNumber: string, questionTags: string, vimeoId?: number | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null }> };

export type ToggleQuestionBankTagMutationVariables = Exact<{
  questionbankId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
}>;


export type ToggleQuestionBankTagMutation = { __typename?: 'Mutation', tagToggleAssignQuestionBank: { __typename?: 'QuestionBank', id: string, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null } };

export type LessonDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LessonDeleteMutation = { __typename?: 'Mutation', lessonDelete: { __typename?: 'Lesson', id: string } };

export type PlaylistDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PlaylistDeleteMutation = { __typename?: 'Mutation', playlistDelete: { __typename?: 'Lesson', id: string } };

export type LessonCopyCreateMutationVariables = Exact<{
  lessonId: Scalars['ID']['input'];
  playlistId: Scalars['ID']['input'];
}>;


export type LessonCopyCreateMutation = { __typename?: 'Mutation', lessonCopyCreate: { __typename?: 'Lesson', id: string } };

export type ToggleParcticeSetTagMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
}>;


export type ToggleParcticeSetTagMutation = { __typename?: 'Mutation', toggleParcticeSetTag?: boolean | null };

export type UpdateLessonVideoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateLessonVideoMutation = { __typename?: 'Mutation', updateLessonVideo: { __typename?: 'Lesson', id: string } };

export type QuestionScoreUpdateMutationVariables = Exact<{
  input: QuestionScoreUpdateInput;
}>;


export type QuestionScoreUpdateMutation = { __typename?: 'Mutation', questionScoreUpdate: { __typename?: 'TestAnswer', id: string } };

export type ClassCreateMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  tutorIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  studentIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ClassCreateMutation = { __typename?: 'Mutation', classCreate: { __typename?: 'Classes', id: string } };

export type DeleteClassMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteClassMutation = { __typename?: 'Mutation', deleteClass?: boolean | null };

export type SetTestAnswerOrderMutationVariables = Exact<{
  questionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type SetTestAnswerOrderMutation = { __typename?: 'Mutation', setTestAnswerOrder: { __typename?: 'Test', id: string } };

export type SetPracticeSetOrderMutationVariables = Exact<{
  questionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type SetPracticeSetOrderMutation = { __typename?: 'Mutation', setPracticeSetOrder: { __typename?: 'PracticeSet', id: string } };

export type AssignTutorToStudentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  tutorIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  classIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type AssignTutorToStudentMutation = { __typename?: 'Mutation', assignTutorToStudent: { __typename?: 'User', id: string } };

export type HomeworkStatusUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
}>;


export type HomeworkStatusUpdateMutation = { __typename?: 'Mutation', homeworkStatusUpdate?: boolean | null };

export type HomeworkCreateMutationVariables = Exact<{
  status: Scalars['Boolean']['input'];
  startDate: Scalars['String']['input'];
  dueDate: Scalars['String']['input'];
  title: Scalars['String']['input'];
  students: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  classes: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  lessonIds?: InputMaybe<Scalars['String']['input']>;
  quizIds?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  assignments?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  testIds?: InputMaybe<Scalars['String']['input']>;
  bluebookIds?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type HomeworkCreateMutation = { __typename?: 'Mutation', homeworkCreate: { __typename?: 'Homework', id: string } };

export type DeleteHomeworkMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteHomeworkMutation = { __typename?: 'Mutation', deleteHomework?: { __typename?: 'Homework', id: string } | null };

export type TagCategoryCreateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type TagCategoryCreateMutation = { __typename?: 'Mutation', tagCategoryCreate: { __typename?: 'TagCategory', id: string } };

export type TagToggleAssignCategoryMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
  tagCategoryId: Scalars['ID']['input'];
}>;


export type TagToggleAssignCategoryMutation = { __typename?: 'Mutation', tagToggleAssignCategory: { __typename?: 'TagCategory', id: string } };

export type TagCategoryDeleteMutationVariables = Exact<{
  tagCategoryId: Scalars['ID']['input'];
}>;


export type TagCategoryDeleteMutation = { __typename?: 'Mutation', tagCategoryDelete?: boolean | null };

export type NotificationCreateMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type NotificationCreateMutation = { __typename?: 'Mutation', notificationCreate: { __typename?: 'Notifications', id: string } };

export type NotificationUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: Scalars['Boolean']['input'];
}>;


export type NotificationUpdateMutation = { __typename?: 'Mutation', notificationUpdate: boolean };

export type NotificationDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type NotificationDeleteMutation = { __typename?: 'Mutation', notificationDelete: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, role?: UserRoleEnum | null, email: string, firstName: string, lastName: string } | null };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', id: string, name: string, hasStates: boolean }> };

export type CoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type CoursesQuery = { __typename?: 'Query', courses: Array<{ __typename?: 'Course', id: string, title: string, subjects: Array<{ __typename?: 'Subject', id: string, title: string, order: number }>, testBooks?: Array<{ __typename?: 'TestBook', id: string, title: string, order: number }> | null }> };

export type SubjectQueryVariables = Exact<{
  subjectId: Scalars['ID']['input'];
}>;


export type SubjectQuery = { __typename?: 'Query', subject?: { __typename?: 'Subject', id: string, title: string, lessonCount: number, playlists: Array<{ __typename?: 'Playlist', id: string, title: string, order: number }>, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> } | null };

export type PlaylistQueryVariables = Exact<{
  playlistId: Scalars['ID']['input'];
}>;


export type PlaylistQuery = { __typename?: 'Query', playlist?: { __typename?: 'Playlist', id: string, title: string, isPublished: boolean, lessons: Array<{ __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> }> } | null };

export type StatesQueryVariables = Exact<{ [key: string]: never; }>;


export type StatesQuery = { __typename?: 'Query', states: Array<{ __typename?: 'State', id: string, name: string, abbreviation?: string | null }> };

export type TagsQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;


export type TagsQuery = { __typename?: 'Query', tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null };

export type LessonQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LessonQuery = { __typename?: 'Query', lesson?: { __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, playlist?: { __typename?: 'Playlist', id: string, title: string } | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }>, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null } | null };

export type ResourceGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ResourceGroupQuery = { __typename?: 'Query', resourceGroup?: { __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null } | null };

export type TestBookQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TestBookQuery = { __typename?: 'Query', testBook?: { __typename?: 'TestBook', id: string, title: string, tests: Array<{ __typename?: 'Test', id: string, title: string }>, digitalSatTests: Array<{ __typename?: 'TestBook', id: string, title: string }>, importTests: Array<{ __typename?: 'TestBook', id: string, title: string }> } | null };

export type UserTestBooksQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserTestBooksQuery = { __typename?: 'Query', testBooksAll: Array<{ __typename?: 'TestBook', id: string, title: string, userHasBook: boolean }> };

export type TestQueryVariables = Exact<{
  testId: Scalars['ID']['input'];
}>;


export type TestQuery = { __typename?: 'Query', test?: { __typename?: 'Test', id: string, title: string, isPublished: boolean, isBluebookPublished: boolean, isTrialAccess: boolean, totalQuestions: number, instructionStep1?: string | null, instructionStep2?: string | null, instructionStep3?: string | null, instructionStep4?: string | null, subjects: Array<{ __typename?: 'Subject', id: string, title: string, order: number }>, answers: Array<{ __typename?: 'TestAnswer', id: string, isMathCalc: boolean, questionNumber?: number | null, answer: string, status?: string | null, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, questionType?: string | null, difficultyLevel?: string | null, score?: string | null, referenceDetails?: string | null, explanation?: string | null, questionMarks?: string | null, question?: string | null, type?: string | null, serialNumber?: number | null, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, instructionLesson?: { __typename?: 'Lesson', id: string, title: string, subject: { __typename?: 'Subject', id: string, title: string, order: number } } | null }> } | null };

export type UsersQueryVariables = Exact<{
  orderBy: UserOrderByArg;
  filters?: InputMaybe<UserFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersWithCount', count: number, records: Array<{ __typename?: 'User', id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean, subscriptions: Array<{ __typename?: 'Subscription', id: string, title: string, status: SubscriptionStatusEnum }> }> } };

export type UserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', updatedAt?: string | null, cardLastFourDigits?: number | null, phoneNumber?: string | null, parentEmail?: string | null, id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean, address?: { __typename?: 'Address', id: string, street: string, postCode: string, city: string, state?: { __typename?: 'State', id: string, name: string, abbreviation?: string | null } | null, country: { __typename?: 'Country', id: string, name: string, hasStates: boolean } } | null, subscriptions: Array<{ __typename?: 'Subscription', shippedBooksAt?: string | null, id: string, title: string, createdAt?: string | null, canceledAt?: string | null, cancelAt: string, status: SubscriptionStatusEnum, stripeSubscriptionId: string, mailingMethod?: MailingMethodEnum | null }> } | null };

export type PendingUserShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingUserShipmentsQuery = { __typename?: 'Query', pendingUserShipments: Array<{ __typename?: 'PendingUserShipment', email: string, name: string, address: string, addressLine2: string, city: string, state: string, zipCode: string, country: string, orderId: string, orderItems: string, ounces: string, length: string, width: string, height: string }> };

export type UserTestActivityQueryVariables = Exact<{
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;


export type UserTestActivityQuery = { __typename?: 'Query', userTestSnippet: Array<{ __typename?: 'UserTestSnippet', email: string, testTitle?: string | null, testTakenDate?: string | null, actTotalScore?: string | null, actEnglishScore?: number | null, actMathScore?: number | null, actReadingScore?: number | null, actScienceScore?: number | null, actEssayScore?: number | null, actEnglishOmitted?: string | null, actEnglishMissed?: string | null, actMathOmitted?: string | null, actMathMissed?: string | null, actReadingOmitted?: string | null, actReadingMissed?: string | null, actScienceOmitted?: string | null, actScienceMissed?: string | null, satTotalScore?: string | null, satReadingScore?: number | null, satWritingLanguageScore?: number | null, satMathScore?: number | null, satReadingOmitted?: string | null, satReadingMissed?: string | null, satWritingLanguageOmitted?: string | null, satWritingLanguageMissed?: string | null, satMathOmitted?: string | null, satMathMissed?: string | null, satMathCalcOmitted?: string | null, satMathCalcMissed?: string | null }> };

export type UserTestDSatActivityQueryVariables = Exact<{
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;


export type UserTestDSatActivityQuery = { __typename?: 'Query', userTestDSatSnippet: Array<{ __typename?: 'UserTestDSatSnippet', email: string, testTitle?: string | null, testTakenDate?: string | null, digitalSatSection1ReadingAndWritingScore?: string | null, digitalSatSection2EasyReadingAndWritingScore?: string | null, digitalSatSection2HardReadingAndWritingScore?: string | null, digitalSatSection1MathScore?: string | null, digitalSatSection2EasyMathScore?: string | null, digitalSatSection2HardMathScore?: string | null, digitalSatSection1ReadingAndWritingMissed?: string | null, digitalSatSection1ReadingAndWritingOmmited?: string | null, digitalSatSection2EasyReadingAndWritingMissed?: string | null, digitalSatSection2EasyReadingAndWritingOmmited?: string | null, digitalSatSection2HardReadingAndWritingMissed?: string | null, digitalSatSection2HardReadingAndWritingOmmited?: string | null, digitalSatSection1MathMissed?: string | null, digitalSatSection1MathOmmited?: string | null, digitalSatSection2EasyMathMissed?: string | null, digitalSatSection2EasyMathOmmited?: string | null, digitalSatSection2HardMathMissed?: string | null, digitalSatSection2HardMathOmmited?: string | null, bluebookReadingAndWritingScore?: string | null, bluebookMathScore?: string | null, bluebookSection1ReadingAndWritingMissed?: string | null, bluebookSection1ReadingAndWritingOmmited?: string | null, bluebookSection2EasyReadingAndWritingMissed?: string | null, bluebookSection2EasyReadingAndWritingOmmited?: string | null, bluebookSection2HardReadingAndWritingMissed?: string | null, bluebookSection2HardReadingAndWritingOmmited?: string | null, bluebookSection1MathMissed?: string | null, bluebookSection1MathOmmited?: string | null, bluebookSection2EasyMathMissed?: string | null, bluebookSection2EasyMathOmmited?: string | null, bluebookSection2HardMathMissed?: string | null, bluebookSection2HardMathOmmited?: string | null }> };

export type FaqQueryVariables = Exact<{ [key: string]: never; }>;


export type FaqQuery = { __typename?: 'Query', FAQ: Array<{ __typename?: 'FAQ', id: string, question: string, answer: string, order: number, status: boolean, type: string }> };

export type TotalActiveQuestionsQueryVariables = Exact<{
  subjectId: Scalars['ID']['input'];
  testId: Scalars['ID']['input'];
}>;


export type TotalActiveQuestionsQuery = { __typename?: 'Query', totalActiveQuestions: Array<{ __typename?: 'TestAnswer', id: string }> };

export type DigitalSatFeedbackQueryVariables = Exact<{
  pagination: PaginationArg;
}>;


export type DigitalSatFeedbackQuery = { __typename?: 'Query', digitalSatFeedback: { __typename?: 'DigitalSatFeedbackCount', count: number, records: Array<{ __typename?: 'DigitalSatFeedback', examId: number, text: string, description: string, createdAt?: string | null, test?: { __typename?: 'UserTestScore', title?: string | null, testTitle?: string | null, testBookTitle?: string | null } | null, user?: { __typename?: 'User', email: string, firstName: string, lastName: string } | null }> } };

export type DigitalSatPracticeSetQueryVariables = Exact<{
  videoId: Scalars['ID']['input'];
  lessonId: Scalars['ID']['input'];
}>;


export type DigitalSatPracticeSetQuery = { __typename?: 'Query', digitalSatPracticeSet?: { __typename?: 'PracticeSet', practiceSettitle?: string | null, subjectId?: string | null, subjectTitle?: string | null, lessonTitle?: string | null, playlistId?: string | null, playlistTitle?: string | null, difficultyLevel?: string | null, allQuestions?: Array<{ __typename?: 'PracticeQue', id: string, question?: string | null, answer: string, questionType?: string | null, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, difficultyLevel?: string | null, explanation?: string | null, isPublished: boolean, vimeoId?: number | null, serialNumber?: number | null, order?: number | null, referenceDetails?: string | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null }> | null } | null };

export type CriteriaManuallyEntryQueryVariables = Exact<{
  testId: Scalars['ID']['input'];
  testBookId: Scalars['ID']['input'];
}>;


export type CriteriaManuallyEntryQuery = { __typename?: 'Query', criteriaManuallyEntry: { __typename?: 'TestBook', questionNumber?: number | null, isQuestionFor: string, answerKey: string, maxScore?: number | null, dsatMaxScore?: number | null, sectionQuestion?: string | null } };

export type QuestionBankQueryVariables = Exact<{
  orderBy: QuestionBankOrderByArg;
  filters?: InputMaybe<QuestionBankFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
  subject: Scalars['String']['input'];
}>;


export type QuestionBankQuery = { __typename?: 'Query', questionBank: { __typename?: 'QuestionBankWithCount', count: number, records: Array<{ __typename?: 'QuestionBank', id: string, question: string, answer: string, type?: string | null, testTitle: string, questionType: string, optionA: string, optionB: string, optionC: string, optionD: string, difficultyLevel: string, referenceDetails: string, explanation: string, status: string, serialNumber: string, questionTags: string, vimeoId?: number | null, practiceSetQuestion?: { __typename?: 'PracticeSet', videoId?: string | null, lessonId?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null }> } };

export type AllQuestionBankQueryVariables = Exact<{ [key: string]: never; }>;


export type AllQuestionBankQuery = { __typename?: 'Query', allQuestionBank: Array<{ __typename?: 'QuestionBank', id: string, question: string, answer: string, type?: string | null, testTitle: string, questionType: string, optionA: string, optionB: string, optionC: string, optionD: string, difficultyLevel: string, referenceDetails: string, explanation: string, status: string, serialNumber: string, questionTags: string, vimeoId?: number | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null }> };

export type PlaylistsQueryVariables = Exact<{
  subjectId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type PlaylistsQuery = { __typename?: 'Query', playlists?: Array<{ __typename?: 'Playlist', id: string, title: string, isPublished: boolean, lessons: Array<{ __typename?: 'Lesson', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, subject: { __typename?: 'Subject', id: string, title: string, order: number }, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null, videos: Array<{ __typename?: 'Video', id: string, title: string, vimeoId: number, isPublished: boolean, description?: string | null, mode?: string | null, lessonId: string, lengthInSeconds: number, hasSeen: boolean, hasUnderstood: boolean, thumbnails: Array<{ __typename?: 'Thumbnail', width: number, height: number, link: string, link_with_play_button: string }> }> }> }> | null };

export type QuestionCountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type QuestionCountQuery = { __typename?: 'Query', questionCount: { __typename?: 'QuestionCount', count: number } };

export type ClassesQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
}>;


export type ClassesQuery = { __typename?: 'Query', classes: { __typename?: 'ClassesWithCount', count: number, records: Array<{ __typename?: 'Classes', id: string, title: string, description?: string | null, userCreatedBy?: { __typename?: 'User', email: string, firstName: string, lastName: string, id: string } | null, classStudents?: Array<{ __typename?: 'Tutor', email: string, firstName: string, lastName: string, id: string, homework?: Array<{ __typename?: 'Homework', id: string, title: string }> | null }> | null, classTutors?: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string, tutorHomeworks?: Array<{ __typename?: 'Homework', title: string, id: string }> | null, tutorQuizzes?: Array<{ __typename?: 'Quiz', title: string, id: string }> | null }> | null }> } };

export type TutorsQueryVariables = Exact<{
  orderBy: TutorOrderByArg;
  filters?: InputMaybe<TutorFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
}>;


export type TutorsQuery = { __typename?: 'Query', tutors: { __typename?: 'TutorsWithCount', count: number, records: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string, createdAt?: string | null, role?: UserRoleEnum | null, lastActiveAt?: string | null, tutorStudents?: Array<{ __typename?: 'User', email: string, firstName: string, lastName: string }> | null, tutorClasses?: Array<{ __typename?: 'Classes', id: string, title: string, homeworks?: Array<{ __typename?: 'Homework', id: string, title: string }> | null }> | null, tutorHomeworks?: Array<{ __typename?: 'Homework', title: string, id: string }> | null, tutorQuizzes?: Array<{ __typename?: 'Quiz', title: string, id: string }> | null, subscriptions: Array<{ __typename?: 'Subscription', id: string, title: string, status: SubscriptionStatusEnum }> }> } };

export type StudentListQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
}>;


export type StudentListQuery = { __typename?: 'Query', studentList: { __typename?: 'StudentListWithCount', count: number, records: Array<{ __typename?: 'User', id: string, createdAt?: string | null, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, allBooksShipped: boolean }> } };

export type AllDigitalSatUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllDigitalSatUsersQuery = { __typename?: 'Query', allDigitalSatUsers?: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string, lastActiveAt?: string | null, studentTutors?: Array<{ __typename?: 'User', id: string, email: string }> | null, studentClasses?: Array<{ __typename?: 'Classes', id: string, title: string }> | null }> | null };

export type HomeworksQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<HomeworkFiltersArg>;
  status: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['String']['input']>;
  classId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type HomeworksQuery = { __typename?: 'Query', homeworks: { __typename?: 'HomeworksWithCount', count: number, completedHomeworkCount: number, pendingHomeworkCount: number, records: Array<{ __typename?: 'Homework', description?: string | null, startDate?: string | null, dueDate?: string | null, title: string, status: boolean, id: string, studentIds?: string | null, statusOfStudent?: string | null, isUsed: boolean, classes?: Array<{ __typename?: 'Classes', title: string, id: string, classStudents?: Array<{ __typename?: 'Tutor', firstName: string, id: string, email: string, lastName: string }> | null }> | null, students?: Array<{ __typename?: 'Tutor', firstName: string, id: string, email: string, lastName: string }> | null }> } };

export type HomeworkUpdateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type HomeworkUpdateQuery = { __typename?: 'Query', homeworkDetails?: { __typename?: 'Homework', isUsed: boolean, status: boolean, startDate?: string | null, dueDate?: string | null, description?: string | null, title: string, createdAt: string, id: string, tests?: Array<{ __typename?: 'DigitalSatTestSection', testBookTitle: string, testBookId: string, tutorTest: string }> | null, bluebookTest?: Array<{ __typename?: 'DigitalSatTestSection', testBookTitle: string, testBookId: string, tutorTest: string }> | null, quizzes?: { __typename?: 'Quiz', reading?: Array<{ __typename?: 'Quiz', id: string, title: string }> | null, math?: Array<{ __typename?: 'Quiz', id: string, title: string }> | null } | null, assignments?: Array<{ __typename?: 'Assignments', answerExplanation?: string | null, assignments?: string | null, id: string, answer?: string | null }> | null, lessons?: Array<{ __typename?: 'PlaylistLesson', id: string, title: string, lessons?: string | null }> | null, classes?: Array<{ __typename?: 'Classes', title: string, id: string }> | null, students?: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string }> | null } | null };

export type GetClassesQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<ClassesFiltersArg>;
}>;


export type GetClassesQuery = { __typename?: 'Query', classes: { __typename?: 'ClassesWithCount', count: number, records: Array<{ __typename?: 'Classes', id: string, title: string }> } };

export type AllQuizQueryVariables = Exact<{ [key: string]: never; }>;


export type AllQuizQuery = { __typename?: 'Query', allQuiz?: { __typename?: 'Quiz', allQuiz?: Array<{ __typename?: 'Quiz', id: string, title: string, subject: string, status: boolean, numberOfQuestion: number, difficultyLevel?: string | null, instructions?: string | null, statusOfStudent?: string | null, tagedQuestions?: Array<{ __typename?: 'TestAnswer', tagTitle?: string | null, tagId: string, question?: string | null }> | null }> | null, reading?: Array<{ __typename?: 'Quiz', id: string, title: string, subject: string, status: boolean, numberOfQuestion: number, difficultyLevel?: string | null, instructions?: string | null, statusOfStudent?: string | null, tagedQuestions?: Array<{ __typename?: 'TestAnswer', tagTitle?: string | null, tagId: string, question?: string | null }> | null }> | null, math?: Array<{ __typename?: 'Quiz', id: string, title: string, subject: string, status: boolean, numberOfQuestion: number, difficultyLevel?: string | null, instructions?: string | null, statusOfStudent?: string | null, tagedQuestions?: Array<{ __typename?: 'TestAnswer', tagTitle?: string | null, tagId: string, question?: string | null }> | null }> | null } | null };

export type DigitalSatSectionQueryVariables = Exact<{
  dbTestBooks?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
}>;


export type DigitalSatSectionQuery = { __typename?: 'Query', digitalSatSection?: { __typename?: 'TestSection', testSections?: Array<{ __typename?: 'DigitalSatTestSection', testId: string, courseId: string, testTitle: string, subjectTitle: string, subjectId: string, testBookId: string }> | null } | null };

export type DigitalSatTestBooksAllQueryVariables = Exact<{ [key: string]: never; }>;


export type DigitalSatTestBooksAllQuery = { __typename?: 'Query', digitalSatTestBooksAll: { __typename?: 'TestBook', id: string, title: string, tests: Array<{ __typename?: 'Test', id: string, title: string }>, digitalSatTests: Array<{ __typename?: 'TestBook', id: string, title: string }>, importTests: Array<{ __typename?: 'TestBook', id: string, title: string }> } };

export type SubjectsQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;


export type SubjectsQuery = { __typename?: 'Query', subjects: Array<{ __typename?: 'Subject', id: string, title: string, lessonCount: number, playlists: Array<{ __typename?: 'Playlist', id: string, title: string, order: number }>, resourceGroups: Array<{ __typename?: 'ResourceGroup', id: string, title: string, isTrialAccess: boolean, isPublished: boolean, files?: Array<{ __typename?: 'File', id: string, title: string, path?: string | null, link?: string | null, userHasCompleted: boolean, userHasDownloaded: boolean }> | null }> }> };

export type LessonStatusQueryVariables = Exact<{
  hid: Scalars['Int']['input'];
  lessonId: Scalars['Int']['input'];
}>;


export type LessonStatusQuery = { __typename?: 'Query', lessonStatus?: Array<{ __typename?: 'Homework', homeworkStatus?: string | null, lessonStudents?: { __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string } | null }> | null };

export type QuizScoreQueryVariables = Exact<{
  quizId: Scalars['ID']['input'];
  homeworkId: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
}>;


export type QuizScoreQuery = { __typename?: 'Query', quizScore: Array<{ __typename?: 'Quiz', questionReview?: string | null, updatedAt?: string | null, totalCorrectAnswer?: number | null, totalQuestion?: number | null, totalScore?: number | null }> };

export type QuizDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QuizDetailsQuery = { __typename?: 'Query', quizDetails?: { __typename?: 'Quiz', id: string, totalResponse?: number | null, createdAt: string, instructions?: string | null, title: string, subject: string, all?: Array<{ __typename?: 'Quiz', id: string, question?: string | null, answer: string, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, correctResponse?: string | null, wrongResponse?: string | null, attemptedStudents?: string | null, optionAResponse?: string | null, optionBResponse?: string | null, optionCResponse?: string | null, optionDResponse?: string | null, explanation?: string | null, questionType?: string | null, referenceDetails?: string | null, questionLevel?: string | null }> | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null, students?: Array<{ __typename?: 'Tutor', id: string, firstName: string, lastName: string, email: string, totalCorrectAnswer: number }> | null } | null };

export type HomeworkDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type HomeworkDetailsQuery = { __typename?: 'Query', homeworkDetails?: { __typename?: 'Homework', description?: string | null, startDate?: string | null, dueDate?: string | null, title: string, createdAt: string, id: string, totalResponse?: Array<{ __typename?: 'User', id: string, email: string }> | null, tests?: Array<{ __typename?: 'DigitalSatTestSection', testBookTitle: string, testBookId: string, tutorTest: string }> | null, bluebookTest?: Array<{ __typename?: 'DigitalSatTestSection', testBookTitle: string, testBookId: string, tutorTest: string }> | null, quizzes?: { __typename?: 'Quiz', reading?: Array<{ __typename?: 'Quiz', id: string, title: string, students?: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string }> | null }> | null, math?: Array<{ __typename?: 'Quiz', id: string, title: string, students?: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string }> | null }> | null } | null, assignments?: Array<{ __typename?: 'Assignments', answerExplanation?: string | null, assignments?: string | null, id: string, answer?: string | null, homeworkStatus: string, updatedAt?: string | null, user?: { __typename?: 'User', firstName: string, id: string, email: string, lastName: string } | null }> | null, lessons?: Array<{ __typename?: 'PlaylistLesson', id: string, title: string, lessons?: string | null }> | null, classes?: Array<{ __typename?: 'Classes', title: string, id: string, description?: string | null, classStudents?: Array<{ __typename?: 'Tutor', firstName: string, id: string, email: string, lastName: string }> | null }> | null, students?: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string }> | null } | null };

export type TestStatusQueryVariables = Exact<{
  hid: Scalars['Int']['input'];
  testData: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type TestStatusQuery = { __typename?: 'Query', testStatus?: Array<{ __typename?: 'Tutor', email: string, firstName: string, lastName: string, status: string }> | null };

export type TutorHomeworkReportQueryVariables = Exact<{
  homeworkId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  filters?: InputMaybe<HomeworkReportFiltersArg>;
}>;


export type TutorHomeworkReportQuery = { __typename?: 'Query', tutorHomeworkReport?: { __typename?: 'tutorHomeworkReport', record: string } | null };

export type StudentAnalyticsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type StudentAnalyticsQuery = { __typename?: 'Query', studentAnalytics?: { __typename?: 'Quiz', studentAnalytics?: Array<{ __typename?: 'Quiz', id: string, question?: string | null, answer: string, optionA?: string | null, optionB?: string | null, optionC?: string | null, optionD?: string | null, questionType?: string | null, explanation?: string | null, questionId: string, studentAnswer?: string | null, studentResponses?: number | null, totalWrongAnswer?: number | null, totalCorrectAnswer?: number | null, difficultyLevel?: string | null }> | null } | null };

export type DigitalSatUsersQueryVariables = Exact<{
  orderBy: TutorOrderByArg;
  filters?: InputMaybe<TutorFiltersArg>;
  pagination?: InputMaybe<PaginationArg>;
}>;


export type DigitalSatUsersQuery = { __typename?: 'Query', digitalSatUsers: { __typename?: 'DigitalSatUsersWithCount', count: number, records: Array<{ __typename?: 'Tutor', id: string, email: string, firstName: string, lastName: string, role?: UserRoleEnum | null, lastActiveAt?: string | null, createdAt?: string | null, subscriptions: Array<{ __typename?: 'Subscription', id: string, title: string, status: SubscriptionStatusEnum }>, studentTutors?: Array<{ __typename?: 'User', id: string, email: string }> | null, studentClasses?: Array<{ __typename?: 'Classes', id: string, title: string }> | null }> } };

export type TagCategoryQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  subject: Scalars['String']['input'];
}>;


export type TagCategoryQuery = { __typename?: 'Query', tagCategory?: { __typename?: 'TagCategoryListWithCount', count: number, records: Array<{ __typename?: 'TagCategory', id: string, name: string, subject: string, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null }> } | null };

export type PracticeSetDataQueryVariables = Exact<{
  videoId: Scalars['ID']['input'];
  lessonId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  examId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PracticeSetDataQuery = { __typename?: 'Query', practiceSetData?: { __typename?: 'PracticeSet', currentQuestionNo?: string | null, totalQuestionNo?: string | null, currentQuestion?: string | null, allQuestion?: string | null } | null };

export type StudentBookmarkLessonsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type StudentBookmarkLessonsQuery = { __typename?: 'Query', studentBookmarkLessons: { __typename?: 'LessonHasUnderstood', lessonMarkedIds: Array<number>, videoUnderstoodIds: Array<number>, lessonUnderstoodIds: Array<number> } };

export type NotificationQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
}>;


export type NotificationQuery = { __typename?: 'Query', notification: { __typename?: 'NotificationsCount', count: number, records: Array<{ __typename?: 'Notifications', id: string, description?: string | null, endDate?: string | null, startDate?: string | null, status?: boolean | null, type?: string | null, title: string, userIds?: Array<string> | null }> } };

export const SubjectBaseDataFragmentDoc = gql`
    fragment SubjectBaseData on Subject {
  id
  title
  order
}
    `;
export const CourseDataFragmentDoc = gql`
    fragment CourseData on Course {
  id
  title
  subjects {
    ...SubjectBaseData
  }
  testBooks {
    id
    title
    order
  }
}
    ${SubjectBaseDataFragmentDoc}`;
export const TagDataFragmentDoc = gql`
    fragment TagData on Tag {
  id
  name
}
    `;
export const FileDataFragmentDoc = gql`
    fragment FileData on File {
  id
  title
  path
  link
  userHasCompleted
  userHasDownloaded
}
    `;
export const ThumbnailDataFragmentDoc = gql`
    fragment ThumbnailData on Thumbnail {
  width
  height
  link
  link_with_play_button
}
    `;
export const VideoDataFragmentDoc = gql`
    fragment VideoData on Video {
  id
  title
  vimeoId
  isPublished
  description
  mode
  lessonId
  lengthInSeconds
  thumbnails {
    ...ThumbnailData
  }
  hasSeen
  hasUnderstood
}
    ${ThumbnailDataFragmentDoc}`;
export const LessonDataFragmentDoc = gql`
    fragment LessonData on Lesson {
  id
  title
  isTrialAccess
  isPublished
  subject {
    ...SubjectBaseData
  }
  tags {
    ...TagData
  }
  files {
    ...FileData
  }
  videos {
    ...VideoData
  }
}
    ${SubjectBaseDataFragmentDoc}
${TagDataFragmentDoc}
${FileDataFragmentDoc}
${VideoDataFragmentDoc}`;
export const LessonDataExtendedFragmentDoc = gql`
    fragment LessonDataExtended on Lesson {
  ...LessonData
  playlist {
    id
    title
  }
  videos {
    ...VideoData
  }
}
    ${LessonDataFragmentDoc}
${VideoDataFragmentDoc}`;
export const PlaylistDataFragmentDoc = gql`
    fragment PlaylistData on Playlist {
  id
  title
  isPublished
  lessons {
    ...LessonData
  }
}
    ${LessonDataFragmentDoc}`;
export const PlaylistLessonsDataFragmentDoc = gql`
    fragment PlaylistLessonsData on Playlist {
  lessons {
    title
    isTrialAccess
    isBookmarked
  }
}
    `;
export const ResourceGroupDataFragmentDoc = gql`
    fragment ResourceGroupData on ResourceGroup {
  id
  title
  isTrialAccess
  isPublished
  files {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export const SubjectResourceGroupDataFragmentDoc = gql`
    fragment SubjectResourceGroupData on Subject {
  id
  resourceGroups {
    ...ResourceGroupData
  }
}
    ${ResourceGroupDataFragmentDoc}`;
export const SubjectDataFragmentDoc = gql`
    fragment SubjectData on Subject {
  id
  title
  lessonCount
  playlists {
    id
    title
    order
  }
  resourceGroups {
    ...ResourceGroupData
  }
}
    ${ResourceGroupDataFragmentDoc}`;
export const SubjectScoresDataFragmentDoc = gql`
    fragment SubjectScoresData on SubjectScores {
  subject {
    ...SubjectData
  }
  correct
  omitted
  missed
  score
}
    ${SubjectDataFragmentDoc}`;
export const TestAnswerDataFragmentDoc = gql`
    fragment TestAnswerData on TestAnswer {
  id
  subject {
    ...SubjectBaseData
  }
  tags {
    ...TagData
  }
  instructionLesson {
    id
    title
    subject {
      ...SubjectBaseData
    }
  }
  isMathCalc
  questionNumber
  answer
  status
  optionA
  optionB
  optionC
  optionD
  answer
  questionType
  difficultyLevel
  score
  referenceDetails
  explanation
  questionMarks
  question
  type
  serialNumber
}
    ${SubjectBaseDataFragmentDoc}
${TagDataFragmentDoc}`;
export const TestDataFragmentDoc = gql`
    fragment TestData on Test {
  id
  title
  isPublished
  isBluebookPublished
  isTrialAccess
  totalQuestions
  instructionStep1
  instructionStep2
  instructionStep3
  instructionStep4
  subjects {
    ...SubjectBaseData
  }
  answers {
    ...TestAnswerData
  }
}
    ${SubjectBaseDataFragmentDoc}
${TestAnswerDataFragmentDoc}`;
export const TestBookDataFragmentDoc = gql`
    fragment TestBookData on TestBook {
  id
  title
  tests {
    id
    title
  }
  digitalSatTests {
    id
    title
  }
  importTests {
    id
    title
  }
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  createdAt
  email
  firstName
  lastName
  role
  allBooksShipped
}
    `;
export const StateDataFragmentDoc = gql`
    fragment StateData on State {
  id
  name
  abbreviation
}
    `;
export const CountryDataFragmentDoc = gql`
    fragment CountryData on Country {
  id
  name
  hasStates
}
    `;
export const AddressDataFragmentDoc = gql`
    fragment AddressData on Address {
  id
  street
  postCode
  city
  state {
    ...StateData
  }
  country {
    ...CountryData
  }
}
    ${StateDataFragmentDoc}
${CountryDataFragmentDoc}`;
export const SubscriptionDataFragmentDoc = gql`
    fragment SubscriptionData on Subscription {
  id
  title
  createdAt
  canceledAt
  cancelAt
  status
  stripeSubscriptionId
  mailingMethod
}
    `;
export const UserDataExtendedFragmentDoc = gql`
    fragment UserDataExtended on User {
  ...UserData
  updatedAt
  cardLastFourDigits
  phoneNumber
  parentEmail
  address {
    ...AddressData
  }
  subscriptions {
    ...SubscriptionData
    shippedBooksAt
  }
}
    ${UserDataFragmentDoc}
${AddressDataFragmentDoc}
${SubscriptionDataFragmentDoc}`;
export const QuestionBankDataFragmentDoc = gql`
    fragment QuestionBankData on QuestionBank {
  id
  question
  answer
  type
  testTitle
  questionType
  optionA
  optionB
  optionC
  optionD
  difficultyLevel
  referenceDetails
  explanation
  status
  serialNumber
  questionTags
  vimeoId
  tags {
    ...TagData
  }
}
    ${TagDataFragmentDoc}`;
export const ToggleLessonTagDocument = gql`
    mutation ToggleLessonTag($lessonId: ID!, $tagId: ID!) {
  tagToggleAssignLesson(lessonId: $lessonId, tagId: $tagId) {
    id
    tags {
      ...TagData
    }
  }
}
    ${TagDataFragmentDoc}`;
export type ToggleLessonTagMutationFn = Apollo.MutationFunction<ToggleLessonTagMutation, ToggleLessonTagMutationVariables>;

/**
 * __useToggleLessonTagMutation__
 *
 * To run a mutation, you first call `useToggleLessonTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLessonTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLessonTagMutation, { data, loading, error }] = useToggleLessonTagMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useToggleLessonTagMutation(baseOptions?: Apollo.MutationHookOptions<ToggleLessonTagMutation, ToggleLessonTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleLessonTagMutation, ToggleLessonTagMutationVariables>(ToggleLessonTagDocument, options);
      }
export type ToggleLessonTagMutationHookResult = ReturnType<typeof useToggleLessonTagMutation>;
export type ToggleLessonTagMutationResult = Apollo.MutationResult<ToggleLessonTagMutation>;
export type ToggleLessonTagMutationOptions = Apollo.BaseMutationOptions<ToggleLessonTagMutation, ToggleLessonTagMutationVariables>;
export const LessonUpdateDocument = gql`
    mutation LessonUpdate($id: ID!, $title: String, $isTrialAccess: Boolean, $isPublished: Boolean) {
  lessonUpdate(
    id: $id
    title: $title
    isTrialAccess: $isTrialAccess
    isPublished: $isPublished
  ) {
    id
    title
    isTrialAccess
    isPublished
  }
}
    `;
export type LessonUpdateMutationFn = Apollo.MutationFunction<LessonUpdateMutation, LessonUpdateMutationVariables>;

/**
 * __useLessonUpdateMutation__
 *
 * To run a mutation, you first call `useLessonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonUpdateMutation, { data, loading, error }] = useLessonUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      isTrialAccess: // value for 'isTrialAccess'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useLessonUpdateMutation(baseOptions?: Apollo.MutationHookOptions<LessonUpdateMutation, LessonUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonUpdateMutation, LessonUpdateMutationVariables>(LessonUpdateDocument, options);
      }
export type LessonUpdateMutationHookResult = ReturnType<typeof useLessonUpdateMutation>;
export type LessonUpdateMutationResult = Apollo.MutationResult<LessonUpdateMutation>;
export type LessonUpdateMutationOptions = Apollo.BaseMutationOptions<LessonUpdateMutation, LessonUpdateMutationVariables>;
export const SubscriptionCancelDocument = gql`
    mutation SubscriptionCancel($stripeSubscriptionId: String!) {
  subscriptionCancel(stripeSubscriptionId: $stripeSubscriptionId) {
    ...SubscriptionData
  }
}
    ${SubscriptionDataFragmentDoc}`;
export type SubscriptionCancelMutationFn = Apollo.MutationFunction<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>;

/**
 * __useSubscriptionCancelMutation__
 *
 * To run a mutation, you first call `useSubscriptionCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionCancelMutation, { data, loading, error }] = useSubscriptionCancelMutation({
 *   variables: {
 *      stripeSubscriptionId: // value for 'stripeSubscriptionId'
 *   },
 * });
 */
export function useSubscriptionCancelMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>(SubscriptionCancelDocument, options);
      }
export type SubscriptionCancelMutationHookResult = ReturnType<typeof useSubscriptionCancelMutation>;
export type SubscriptionCancelMutationResult = Apollo.MutationResult<SubscriptionCancelMutation>;
export type SubscriptionCancelMutationOptions = Apollo.BaseMutationOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>;
export const SubscriptionUpdateDocument = gql`
    mutation SubscriptionUpdate($subscriptionId: ID!, $hasShippedBooks: Boolean, $newCancelAtDate: Int) {
  subscriptionUpdate(
    subscriptionId: $subscriptionId
    hasShippedBooks: $hasShippedBooks
    newCancelAtDate: $newCancelAtDate
  ) {
    id
    allBooksShipped
    subscriptions {
      id
      shippedBooksAt
      cancelAt
      status
      canceledAt
    }
  }
}
    `;
export type SubscriptionUpdateMutationFn = Apollo.MutationFunction<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>;

/**
 * __useSubscriptionUpdateMutation__
 *
 * To run a mutation, you first call `useSubscriptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionUpdateMutation, { data, loading, error }] = useSubscriptionUpdateMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      hasShippedBooks: // value for 'hasShippedBooks'
 *      newCancelAtDate: // value for 'newCancelAtDate'
 *   },
 * });
 */
export function useSubscriptionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>(SubscriptionUpdateDocument, options);
      }
export type SubscriptionUpdateMutationHookResult = ReturnType<typeof useSubscriptionUpdateMutation>;
export type SubscriptionUpdateMutationResult = Apollo.MutationResult<SubscriptionUpdateMutation>;
export type SubscriptionUpdateMutationOptions = Apollo.BaseMutationOptions<SubscriptionUpdateMutation, SubscriptionUpdateMutationVariables>;
export const TestUpdateDocument = gql`
    mutation testUpdate($id: ID!, $title: String, $isPublished: Boolean, $isBluebookPublished: Boolean, $isTrialAccess: Boolean, $instructionStep1: String, $instructionStep2: String, $instructionStep3: String, $instructionStep4: String) {
  testUpdate(
    id: $id
    title: $title
    isPublished: $isPublished
    isBluebookPublished: $isBluebookPublished
    isTrialAccess: $isTrialAccess
    instructionStep1: $instructionStep1
    instructionStep2: $instructionStep2
    instructionStep3: $instructionStep3
    instructionStep4: $instructionStep4
  ) {
    id
    title
    isPublished
    isBluebookPublished
    isTrialAccess
    instructionStep1
    instructionStep2
    instructionStep3
    instructionStep4
  }
}
    `;
export type TestUpdateMutationFn = Apollo.MutationFunction<TestUpdateMutation, TestUpdateMutationVariables>;

/**
 * __useTestUpdateMutation__
 *
 * To run a mutation, you first call `useTestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testUpdateMutation, { data, loading, error }] = useTestUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      isPublished: // value for 'isPublished'
 *      isBluebookPublished: // value for 'isBluebookPublished'
 *      isTrialAccess: // value for 'isTrialAccess'
 *      instructionStep1: // value for 'instructionStep1'
 *      instructionStep2: // value for 'instructionStep2'
 *      instructionStep3: // value for 'instructionStep3'
 *      instructionStep4: // value for 'instructionStep4'
 *   },
 * });
 */
export function useTestUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TestUpdateMutation, TestUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestUpdateMutation, TestUpdateMutationVariables>(TestUpdateDocument, options);
      }
export type TestUpdateMutationHookResult = ReturnType<typeof useTestUpdateMutation>;
export type TestUpdateMutationResult = Apollo.MutationResult<TestUpdateMutation>;
export type TestUpdateMutationOptions = Apollo.BaseMutationOptions<TestUpdateMutation, TestUpdateMutationVariables>;
export const PlaylistUpdateDocument = gql`
    mutation PlaylistUpdate($id: ID!, $title: String, $isPublished: Boolean) {
  playlistUpdate(id: $id, title: $title, isPublished: $isPublished) {
    id
    title
    isPublished
  }
}
    `;
export type PlaylistUpdateMutationFn = Apollo.MutationFunction<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>;

/**
 * __usePlaylistUpdateMutation__
 *
 * To run a mutation, you first call `usePlaylistUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaylistUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playlistUpdateMutation, { data, loading, error }] = usePlaylistUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function usePlaylistUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>(PlaylistUpdateDocument, options);
      }
export type PlaylistUpdateMutationHookResult = ReturnType<typeof usePlaylistUpdateMutation>;
export type PlaylistUpdateMutationResult = Apollo.MutationResult<PlaylistUpdateMutation>;
export type PlaylistUpdateMutationOptions = Apollo.BaseMutationOptions<PlaylistUpdateMutation, PlaylistUpdateMutationVariables>;
export const SetPlaylistLessonsDocument = gql`
    mutation SetPlaylistLessons($playlistId: ID!, $lessonIds: [ID!]!) {
  setPlaylistLessons(playlistId: $playlistId, lessonIds: $lessonIds) {
    id
    lessons {
      ...LessonData
    }
  }
}
    ${LessonDataFragmentDoc}`;
export type SetPlaylistLessonsMutationFn = Apollo.MutationFunction<SetPlaylistLessonsMutation, SetPlaylistLessonsMutationVariables>;

/**
 * __useSetPlaylistLessonsMutation__
 *
 * To run a mutation, you first call `useSetPlaylistLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlaylistLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlaylistLessonsMutation, { data, loading, error }] = useSetPlaylistLessonsMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      lessonIds: // value for 'lessonIds'
 *   },
 * });
 */
export function useSetPlaylistLessonsMutation(baseOptions?: Apollo.MutationHookOptions<SetPlaylistLessonsMutation, SetPlaylistLessonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlaylistLessonsMutation, SetPlaylistLessonsMutationVariables>(SetPlaylistLessonsDocument, options);
      }
export type SetPlaylistLessonsMutationHookResult = ReturnType<typeof useSetPlaylistLessonsMutation>;
export type SetPlaylistLessonsMutationResult = Apollo.MutationResult<SetPlaylistLessonsMutation>;
export type SetPlaylistLessonsMutationOptions = Apollo.BaseMutationOptions<SetPlaylistLessonsMutation, SetPlaylistLessonsMutationVariables>;
export const TagCreateDocument = gql`
    mutation TagCreate($courseId: ID!, $name: String!) {
  tagCreate(courseId: $courseId, name: $name) {
    id
    name
  }
}
    `;
export type TagCreateMutationFn = Apollo.MutationFunction<TagCreateMutation, TagCreateMutationVariables>;

/**
 * __useTagCreateMutation__
 *
 * To run a mutation, you first call `useTagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCreateMutation, { data, loading, error }] = useTagCreateMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTagCreateMutation(baseOptions?: Apollo.MutationHookOptions<TagCreateMutation, TagCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TagCreateMutation, TagCreateMutationVariables>(TagCreateDocument, options);
      }
export type TagCreateMutationHookResult = ReturnType<typeof useTagCreateMutation>;
export type TagCreateMutationResult = Apollo.MutationResult<TagCreateMutation>;
export type TagCreateMutationOptions = Apollo.BaseMutationOptions<TagCreateMutation, TagCreateMutationVariables>;
export const TagDeleteDocument = gql`
    mutation TagDelete($tagId: ID!) {
  tagDelete(tagId: $tagId)
}
    `;
export type TagDeleteMutationFn = Apollo.MutationFunction<TagDeleteMutation, TagDeleteMutationVariables>;

/**
 * __useTagDeleteMutation__
 *
 * To run a mutation, you first call `useTagDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagDeleteMutation, { data, loading, error }] = useTagDeleteMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useTagDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TagDeleteMutation, TagDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TagDeleteMutation, TagDeleteMutationVariables>(TagDeleteDocument, options);
      }
export type TagDeleteMutationHookResult = ReturnType<typeof useTagDeleteMutation>;
export type TagDeleteMutationResult = Apollo.MutationResult<TagDeleteMutation>;
export type TagDeleteMutationOptions = Apollo.BaseMutationOptions<TagDeleteMutation, TagDeleteMutationVariables>;
export const FileCreateLessonDocument = gql`
    mutation FileCreateLesson($title: String!, $link: String, $path: String, $lessonId: ID!) {
  fileCreateLesson(title: $title, link: $link, path: $path, lessonId: $lessonId) {
    id
    files {
      ...FileData
    }
  }
}
    ${FileDataFragmentDoc}`;
export type FileCreateLessonMutationFn = Apollo.MutationFunction<FileCreateLessonMutation, FileCreateLessonMutationVariables>;

/**
 * __useFileCreateLessonMutation__
 *
 * To run a mutation, you first call `useFileCreateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileCreateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileCreateLessonMutation, { data, loading, error }] = useFileCreateLessonMutation({
 *   variables: {
 *      title: // value for 'title'
 *      link: // value for 'link'
 *      path: // value for 'path'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useFileCreateLessonMutation(baseOptions?: Apollo.MutationHookOptions<FileCreateLessonMutation, FileCreateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileCreateLessonMutation, FileCreateLessonMutationVariables>(FileCreateLessonDocument, options);
      }
export type FileCreateLessonMutationHookResult = ReturnType<typeof useFileCreateLessonMutation>;
export type FileCreateLessonMutationResult = Apollo.MutationResult<FileCreateLessonMutation>;
export type FileCreateLessonMutationOptions = Apollo.BaseMutationOptions<FileCreateLessonMutation, FileCreateLessonMutationVariables>;
export const FileDeleteDocument = gql`
    mutation FileDelete($id: ID!) {
  fileDelete(id: $id) {
    ...FileData
  }
}
    ${FileDataFragmentDoc}`;
export type FileDeleteMutationFn = Apollo.MutationFunction<FileDeleteMutation, FileDeleteMutationVariables>;

/**
 * __useFileDeleteMutation__
 *
 * To run a mutation, you first call `useFileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileDeleteMutation, { data, loading, error }] = useFileDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileDeleteMutation(baseOptions?: Apollo.MutationHookOptions<FileDeleteMutation, FileDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileDeleteMutation, FileDeleteMutationVariables>(FileDeleteDocument, options);
      }
export type FileDeleteMutationHookResult = ReturnType<typeof useFileDeleteMutation>;
export type FileDeleteMutationResult = Apollo.MutationResult<FileDeleteMutation>;
export type FileDeleteMutationOptions = Apollo.BaseMutationOptions<FileDeleteMutation, FileDeleteMutationVariables>;
export const VideoCreateDocument = gql`
    mutation VideoCreate($vimeoId: Int!, $description: String, $lessonId: ID!) {
  videoCreate(vimeoId: $vimeoId, description: $description, lessonId: $lessonId) {
    id
    videos {
      ...VideoData
    }
  }
}
    ${VideoDataFragmentDoc}`;
export type VideoCreateMutationFn = Apollo.MutationFunction<VideoCreateMutation, VideoCreateMutationVariables>;

/**
 * __useVideoCreateMutation__
 *
 * To run a mutation, you first call `useVideoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCreateMutation, { data, loading, error }] = useVideoCreateMutation({
 *   variables: {
 *      vimeoId: // value for 'vimeoId'
 *      description: // value for 'description'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useVideoCreateMutation(baseOptions?: Apollo.MutationHookOptions<VideoCreateMutation, VideoCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCreateMutation, VideoCreateMutationVariables>(VideoCreateDocument, options);
      }
export type VideoCreateMutationHookResult = ReturnType<typeof useVideoCreateMutation>;
export type VideoCreateMutationResult = Apollo.MutationResult<VideoCreateMutation>;
export type VideoCreateMutationOptions = Apollo.BaseMutationOptions<VideoCreateMutation, VideoCreateMutationVariables>;
export const VideoUpdateDocument = gql`
    mutation VideoUpdate($id: ID!, $description: String, $isPublished: Boolean) {
  videoUpdate(id: $id, description: $description, isPublished: $isPublished) {
    ...LessonData
  }
}
    ${LessonDataFragmentDoc}`;
export type VideoUpdateMutationFn = Apollo.MutationFunction<VideoUpdateMutation, VideoUpdateMutationVariables>;

/**
 * __useVideoUpdateMutation__
 *
 * To run a mutation, you first call `useVideoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoUpdateMutation, { data, loading, error }] = useVideoUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useVideoUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VideoUpdateMutation, VideoUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoUpdateMutation, VideoUpdateMutationVariables>(VideoUpdateDocument, options);
      }
export type VideoUpdateMutationHookResult = ReturnType<typeof useVideoUpdateMutation>;
export type VideoUpdateMutationResult = Apollo.MutationResult<VideoUpdateMutation>;
export type VideoUpdateMutationOptions = Apollo.BaseMutationOptions<VideoUpdateMutation, VideoUpdateMutationVariables>;
export const VideoDeleteDocument = gql`
    mutation VideoDelete($id: ID!) {
  videoDelete(id: $id) {
    ...LessonData
  }
}
    ${LessonDataFragmentDoc}`;
export type VideoDeleteMutationFn = Apollo.MutationFunction<VideoDeleteMutation, VideoDeleteMutationVariables>;

/**
 * __useVideoDeleteMutation__
 *
 * To run a mutation, you first call `useVideoDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoDeleteMutation, { data, loading, error }] = useVideoDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoDeleteMutation(baseOptions?: Apollo.MutationHookOptions<VideoDeleteMutation, VideoDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoDeleteMutation, VideoDeleteMutationVariables>(VideoDeleteDocument, options);
      }
export type VideoDeleteMutationHookResult = ReturnType<typeof useVideoDeleteMutation>;
export type VideoDeleteMutationResult = Apollo.MutationResult<VideoDeleteMutation>;
export type VideoDeleteMutationOptions = Apollo.BaseMutationOptions<VideoDeleteMutation, VideoDeleteMutationVariables>;
export const VideosReorderDocument = gql`
    mutation VideosReorder($lessonId: ID!, $videoIds: [ID!]!) {
  lessonUpdateVideoOrder(lessonId: $lessonId, videoIds: $videoIds) {
    id
    videos {
      ...VideoData
    }
  }
}
    ${VideoDataFragmentDoc}`;
export type VideosReorderMutationFn = Apollo.MutationFunction<VideosReorderMutation, VideosReorderMutationVariables>;

/**
 * __useVideosReorderMutation__
 *
 * To run a mutation, you first call `useVideosReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideosReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videosReorderMutation, { data, loading, error }] = useVideosReorderMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      videoIds: // value for 'videoIds'
 *   },
 * });
 */
export function useVideosReorderMutation(baseOptions?: Apollo.MutationHookOptions<VideosReorderMutation, VideosReorderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideosReorderMutation, VideosReorderMutationVariables>(VideosReorderDocument, options);
      }
export type VideosReorderMutationHookResult = ReturnType<typeof useVideosReorderMutation>;
export type VideosReorderMutationResult = Apollo.MutationResult<VideosReorderMutation>;
export type VideosReorderMutationOptions = Apollo.BaseMutationOptions<VideosReorderMutation, VideosReorderMutationVariables>;
export const PlaylistCreateDocument = gql`
    mutation PlaylistCreate($playlistTitle: String!, $subjectId: ID!, $lessonIds: [ID!]!, $testId: ID) {
  playlistCreatePublic(
    playlistTitle: $playlistTitle
    subjectId: $subjectId
    lessonIds: $lessonIds
    testId: $testId
  ) {
    ...PlaylistData
  }
}
    ${PlaylistDataFragmentDoc}`;
export type PlaylistCreateMutationFn = Apollo.MutationFunction<PlaylistCreateMutation, PlaylistCreateMutationVariables>;

/**
 * __usePlaylistCreateMutation__
 *
 * To run a mutation, you first call `usePlaylistCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaylistCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playlistCreateMutation, { data, loading, error }] = usePlaylistCreateMutation({
 *   variables: {
 *      playlistTitle: // value for 'playlistTitle'
 *      subjectId: // value for 'subjectId'
 *      lessonIds: // value for 'lessonIds'
 *      testId: // value for 'testId'
 *   },
 * });
 */
export function usePlaylistCreateMutation(baseOptions?: Apollo.MutationHookOptions<PlaylistCreateMutation, PlaylistCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaylistCreateMutation, PlaylistCreateMutationVariables>(PlaylistCreateDocument, options);
      }
export type PlaylistCreateMutationHookResult = ReturnType<typeof usePlaylistCreateMutation>;
export type PlaylistCreateMutationResult = Apollo.MutationResult<PlaylistCreateMutation>;
export type PlaylistCreateMutationOptions = Apollo.BaseMutationOptions<PlaylistCreateMutation, PlaylistCreateMutationVariables>;
export const LessonCreateDocument = gql`
    mutation LessonCreate($lessonTitle: String!, $vimeoIds: [Int!], $playlistId: ID, $testAnswerId: ID, $isTrialAccess: Boolean!, $subjectId: ID!) {
  lessonCreate(
    lessonTitle: $lessonTitle
    vimeoIds: $vimeoIds
    playlistId: $playlistId
    testAnswerId: $testAnswerId
    isTrialAccess: $isTrialAccess
    subjectId: $subjectId
  ) {
    ...LessonData
  }
}
    ${LessonDataFragmentDoc}`;
export type LessonCreateMutationFn = Apollo.MutationFunction<LessonCreateMutation, LessonCreateMutationVariables>;

/**
 * __useLessonCreateMutation__
 *
 * To run a mutation, you first call `useLessonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonCreateMutation, { data, loading, error }] = useLessonCreateMutation({
 *   variables: {
 *      lessonTitle: // value for 'lessonTitle'
 *      vimeoIds: // value for 'vimeoIds'
 *      playlistId: // value for 'playlistId'
 *      testAnswerId: // value for 'testAnswerId'
 *      isTrialAccess: // value for 'isTrialAccess'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useLessonCreateMutation(baseOptions?: Apollo.MutationHookOptions<LessonCreateMutation, LessonCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonCreateMutation, LessonCreateMutationVariables>(LessonCreateDocument, options);
      }
export type LessonCreateMutationHookResult = ReturnType<typeof useLessonCreateMutation>;
export type LessonCreateMutationResult = Apollo.MutationResult<LessonCreateMutation>;
export type LessonCreateMutationOptions = Apollo.BaseMutationOptions<LessonCreateMutation, LessonCreateMutationVariables>;
export const ResourceGroupCreateDocument = gql`
    mutation ResourceGroupCreate($title: String!, $subjectId: ID!, $isTrialAccess: Boolean!) {
  resourceGroupCreate(
    title: $title
    subjectId: $subjectId
    isTrialAccess: $isTrialAccess
  ) {
    ...SubjectResourceGroupData
  }
}
    ${SubjectResourceGroupDataFragmentDoc}`;
export type ResourceGroupCreateMutationFn = Apollo.MutationFunction<ResourceGroupCreateMutation, ResourceGroupCreateMutationVariables>;

/**
 * __useResourceGroupCreateMutation__
 *
 * To run a mutation, you first call `useResourceGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResourceGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resourceGroupCreateMutation, { data, loading, error }] = useResourceGroupCreateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      subjectId: // value for 'subjectId'
 *      isTrialAccess: // value for 'isTrialAccess'
 *   },
 * });
 */
export function useResourceGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<ResourceGroupCreateMutation, ResourceGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResourceGroupCreateMutation, ResourceGroupCreateMutationVariables>(ResourceGroupCreateDocument, options);
      }
export type ResourceGroupCreateMutationHookResult = ReturnType<typeof useResourceGroupCreateMutation>;
export type ResourceGroupCreateMutationResult = Apollo.MutationResult<ResourceGroupCreateMutation>;
export type ResourceGroupCreateMutationOptions = Apollo.BaseMutationOptions<ResourceGroupCreateMutation, ResourceGroupCreateMutationVariables>;
export const ResourceGroupUpdateDocument = gql`
    mutation ResourceGroupUpdate($id: ID!, $title: String, $isPublished: Boolean, $isTrialAccess: Boolean) {
  resourceGroupUpdate(
    id: $id
    title: $title
    isPublished: $isPublished
    isTrialAccess: $isTrialAccess
  ) {
    ...ResourceGroupData
  }
}
    ${ResourceGroupDataFragmentDoc}`;
export type ResourceGroupUpdateMutationFn = Apollo.MutationFunction<ResourceGroupUpdateMutation, ResourceGroupUpdateMutationVariables>;

/**
 * __useResourceGroupUpdateMutation__
 *
 * To run a mutation, you first call `useResourceGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResourceGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resourceGroupUpdateMutation, { data, loading, error }] = useResourceGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      isPublished: // value for 'isPublished'
 *      isTrialAccess: // value for 'isTrialAccess'
 *   },
 * });
 */
export function useResourceGroupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ResourceGroupUpdateMutation, ResourceGroupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResourceGroupUpdateMutation, ResourceGroupUpdateMutationVariables>(ResourceGroupUpdateDocument, options);
      }
export type ResourceGroupUpdateMutationHookResult = ReturnType<typeof useResourceGroupUpdateMutation>;
export type ResourceGroupUpdateMutationResult = Apollo.MutationResult<ResourceGroupUpdateMutation>;
export type ResourceGroupUpdateMutationOptions = Apollo.BaseMutationOptions<ResourceGroupUpdateMutation, ResourceGroupUpdateMutationVariables>;
export const FileCreateResourceGroupDocument = gql`
    mutation FileCreateResourceGroup($title: String!, $resourceGroupId: ID!, $path: String, $link: String) {
  fileCreateResourceGroup(
    title: $title
    resourceGroupId: $resourceGroupId
    path: $path
    link: $link
  ) {
    id
    files {
      ...FileData
    }
  }
}
    ${FileDataFragmentDoc}`;
export type FileCreateResourceGroupMutationFn = Apollo.MutationFunction<FileCreateResourceGroupMutation, FileCreateResourceGroupMutationVariables>;

/**
 * __useFileCreateResourceGroupMutation__
 *
 * To run a mutation, you first call `useFileCreateResourceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileCreateResourceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileCreateResourceGroupMutation, { data, loading, error }] = useFileCreateResourceGroupMutation({
 *   variables: {
 *      title: // value for 'title'
 *      resourceGroupId: // value for 'resourceGroupId'
 *      path: // value for 'path'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useFileCreateResourceGroupMutation(baseOptions?: Apollo.MutationHookOptions<FileCreateResourceGroupMutation, FileCreateResourceGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileCreateResourceGroupMutation, FileCreateResourceGroupMutationVariables>(FileCreateResourceGroupDocument, options);
      }
export type FileCreateResourceGroupMutationHookResult = ReturnType<typeof useFileCreateResourceGroupMutation>;
export type FileCreateResourceGroupMutationResult = Apollo.MutationResult<FileCreateResourceGroupMutation>;
export type FileCreateResourceGroupMutationOptions = Apollo.BaseMutationOptions<FileCreateResourceGroupMutation, FileCreateResourceGroupMutationVariables>;
export const ToggleTestAnswerTagDocument = gql`
    mutation ToggleTestAnswerTag($testAnswerId: ID!, $tagId: ID!) {
  tagToggleAssignTestAnswer(testAnswerId: $testAnswerId, tagId: $tagId) {
    id
    tags {
      ...TagData
    }
  }
}
    ${TagDataFragmentDoc}`;
export type ToggleTestAnswerTagMutationFn = Apollo.MutationFunction<ToggleTestAnswerTagMutation, ToggleTestAnswerTagMutationVariables>;

/**
 * __useToggleTestAnswerTagMutation__
 *
 * To run a mutation, you first call `useToggleTestAnswerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTestAnswerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTestAnswerTagMutation, { data, loading, error }] = useToggleTestAnswerTagMutation({
 *   variables: {
 *      testAnswerId: // value for 'testAnswerId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useToggleTestAnswerTagMutation(baseOptions?: Apollo.MutationHookOptions<ToggleTestAnswerTagMutation, ToggleTestAnswerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleTestAnswerTagMutation, ToggleTestAnswerTagMutationVariables>(ToggleTestAnswerTagDocument, options);
      }
export type ToggleTestAnswerTagMutationHookResult = ReturnType<typeof useToggleTestAnswerTagMutation>;
export type ToggleTestAnswerTagMutationResult = Apollo.MutationResult<ToggleTestAnswerTagMutation>;
export type ToggleTestAnswerTagMutationOptions = Apollo.BaseMutationOptions<ToggleTestAnswerTagMutation, ToggleTestAnswerTagMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($input: UserUpdateInput!) {
  userUpdate(input: $input) {
    ...UserDataExtended
  }
}
    ${UserDataExtendedFragmentDoc}`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserSetPasswordDocument = gql`
    mutation UserSetPassword($userId: ID!, $newPassword: String!) {
  adminSetUserPassword(userId: $userId, newPassword: $newPassword)
}
    `;
export type UserSetPasswordMutationFn = Apollo.MutationFunction<UserSetPasswordMutation, UserSetPasswordMutationVariables>;

/**
 * __useUserSetPasswordMutation__
 *
 * To run a mutation, you first call `useUserSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetPasswordMutation, { data, loading, error }] = useUserSetPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserSetPasswordMutation, UserSetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSetPasswordMutation, UserSetPasswordMutationVariables>(UserSetPasswordDocument, options);
      }
export type UserSetPasswordMutationHookResult = ReturnType<typeof useUserSetPasswordMutation>;
export type UserSetPasswordMutationResult = Apollo.MutationResult<UserSetPasswordMutation>;
export type UserSetPasswordMutationOptions = Apollo.BaseMutationOptions<UserSetPasswordMutation, UserSetPasswordMutationVariables>;
export const UserDeleteDocument = gql`
    mutation UserDelete($id: ID!) {
  userDeleteById(id: $id)
}
    `;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserCreateDocument = gql`
    mutation UserCreate($input: UserCreateAdminInput!) {
  userCreateAdmin(input: $input) {
    ...UserDataExtended
  }
}
    ${UserDataExtendedFragmentDoc}`;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
      }
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const DigitalSatCreateDocument = gql`
    mutation DigitalSatCreate($input: DigitalSatCreateInput!) {
  digitalSatCreate(input: $input) {
    title
  }
}
    `;
export type DigitalSatCreateMutationFn = Apollo.MutationFunction<DigitalSatCreateMutation, DigitalSatCreateMutationVariables>;

/**
 * __useDigitalSatCreateMutation__
 *
 * To run a mutation, you first call `useDigitalSatCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatCreateMutation, { data, loading, error }] = useDigitalSatCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDigitalSatCreateMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatCreateMutation, DigitalSatCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatCreateMutation, DigitalSatCreateMutationVariables>(DigitalSatCreateDocument, options);
      }
export type DigitalSatCreateMutationHookResult = ReturnType<typeof useDigitalSatCreateMutation>;
export type DigitalSatCreateMutationResult = Apollo.MutationResult<DigitalSatCreateMutation>;
export type DigitalSatCreateMutationOptions = Apollo.BaseMutationOptions<DigitalSatCreateMutation, DigitalSatCreateMutationVariables>;
export const TestBookUserToggleDocument = gql`
    mutation TestBookUserToggle($userId: ID!, $testBookId: ID!) {
  testBookUserToggle(userId: $userId, testBookId: $testBookId) {
    id
    title
    userHasBook(userId: $userId)
  }
}
    `;
export type TestBookUserToggleMutationFn = Apollo.MutationFunction<TestBookUserToggleMutation, TestBookUserToggleMutationVariables>;

/**
 * __useTestBookUserToggleMutation__
 *
 * To run a mutation, you first call `useTestBookUserToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestBookUserToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testBookUserToggleMutation, { data, loading, error }] = useTestBookUserToggleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      testBookId: // value for 'testBookId'
 *   },
 * });
 */
export function useTestBookUserToggleMutation(baseOptions?: Apollo.MutationHookOptions<TestBookUserToggleMutation, TestBookUserToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestBookUserToggleMutation, TestBookUserToggleMutationVariables>(TestBookUserToggleDocument, options);
      }
export type TestBookUserToggleMutationHookResult = ReturnType<typeof useTestBookUserToggleMutation>;
export type TestBookUserToggleMutationResult = Apollo.MutationResult<TestBookUserToggleMutation>;
export type TestBookUserToggleMutationOptions = Apollo.BaseMutationOptions<TestBookUserToggleMutation, TestBookUserToggleMutationVariables>;
export const SetPlaylistOrderDocument = gql`
    mutation SetPlaylistOrder($playlistIds: [ID!]!, $subjectId: ID!) {
  setPlaylistOrder(playlistIds: $playlistIds, subjectId: $subjectId) {
    ...SubjectData
  }
}
    ${SubjectDataFragmentDoc}`;
export type SetPlaylistOrderMutationFn = Apollo.MutationFunction<SetPlaylistOrderMutation, SetPlaylistOrderMutationVariables>;

/**
 * __useSetPlaylistOrderMutation__
 *
 * To run a mutation, you first call `useSetPlaylistOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlaylistOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlaylistOrderMutation, { data, loading, error }] = useSetPlaylistOrderMutation({
 *   variables: {
 *      playlistIds: // value for 'playlistIds'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useSetPlaylistOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetPlaylistOrderMutation, SetPlaylistOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlaylistOrderMutation, SetPlaylistOrderMutationVariables>(SetPlaylistOrderDocument, options);
      }
export type SetPlaylistOrderMutationHookResult = ReturnType<typeof useSetPlaylistOrderMutation>;
export type SetPlaylistOrderMutationResult = Apollo.MutationResult<SetPlaylistOrderMutation>;
export type SetPlaylistOrderMutationOptions = Apollo.BaseMutationOptions<SetPlaylistOrderMutation, SetPlaylistOrderMutationVariables>;
export const SetSubjectOrderDocument = gql`
    mutation SetSubjectOrder($courseId: ID!, $subjectIds: [ID!]!) {
  setSubjectOrder(courseId: $courseId, subjectIds: $subjectIds) {
    ...CourseData
  }
}
    ${CourseDataFragmentDoc}`;
export type SetSubjectOrderMutationFn = Apollo.MutationFunction<SetSubjectOrderMutation, SetSubjectOrderMutationVariables>;

/**
 * __useSetSubjectOrderMutation__
 *
 * To run a mutation, you first call `useSetSubjectOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubjectOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubjectOrderMutation, { data, loading, error }] = useSetSubjectOrderMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      subjectIds: // value for 'subjectIds'
 *   },
 * });
 */
export function useSetSubjectOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetSubjectOrderMutation, SetSubjectOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSubjectOrderMutation, SetSubjectOrderMutationVariables>(SetSubjectOrderDocument, options);
      }
export type SetSubjectOrderMutationHookResult = ReturnType<typeof useSetSubjectOrderMutation>;
export type SetSubjectOrderMutationResult = Apollo.MutationResult<SetSubjectOrderMutation>;
export type SetSubjectOrderMutationOptions = Apollo.BaseMutationOptions<SetSubjectOrderMutation, SetSubjectOrderMutationVariables>;
export const SetTestOrderDocument = gql`
    mutation SetTestOrder($courseId: ID!, $testBookIds: [ID!]!) {
  setTestOrder(courseId: $courseId, testBookIds: $testBookIds) {
    ...CourseData
  }
}
    ${CourseDataFragmentDoc}`;
export type SetTestOrderMutationFn = Apollo.MutationFunction<SetTestOrderMutation, SetTestOrderMutationVariables>;

/**
 * __useSetTestOrderMutation__
 *
 * To run a mutation, you first call `useSetTestOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTestOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTestOrderMutation, { data, loading, error }] = useSetTestOrderMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      testBookIds: // value for 'testBookIds'
 *   },
 * });
 */
export function useSetTestOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetTestOrderMutation, SetTestOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTestOrderMutation, SetTestOrderMutationVariables>(SetTestOrderDocument, options);
      }
export type SetTestOrderMutationHookResult = ReturnType<typeof useSetTestOrderMutation>;
export type SetTestOrderMutationResult = Apollo.MutationResult<SetTestOrderMutation>;
export type SetTestOrderMutationOptions = Apollo.BaseMutationOptions<SetTestOrderMutation, SetTestOrderMutationVariables>;
export const DigitalSatTestAnswerCreateDocument = gql`
    mutation DigitalSatTestAnswerCreate($id: ID, $testId: ID!, $question: String!, $answer: String!, $questionType: String!, $subjectId: ID!, $optionA: String!, $optionB: String!, $optionC: String!, $optionD: String!, $difficultyLevel: String!, $score: String!, $referenceDetails: String, $explanation: String, $questionMarks: String!, $status: String!, $tagIds: String, $type: String!, $vimeoId: Int, $isSaveToQuestionbank: Boolean!, $questionBankId: Int) {
  digitalSatTestAnswerCreate(
    isSaveToQuestionbank: $isSaveToQuestionbank
    id: $id
    testId: $testId
    question: $question
    answer: $answer
    questionType: $questionType
    subjectId: $subjectId
    optionA: $optionA
    optionB: $optionB
    optionC: $optionC
    optionD: $optionD
    difficultyLevel: $difficultyLevel
    score: $score
    referenceDetails: $referenceDetails
    explanation: $explanation
    questionMarks: $questionMarks
    status: $status
    tagIds: $tagIds
    type: $type
    vimeoId: $vimeoId
    questionBankId: $questionBankId
  ) {
    id
    testId
    question
    answer
    subjectId
    questionType
    optionA
    optionB
    optionC
    optionD
    difficultyLevel
    score
    referenceDetails
    explanation
    questionMarks
    status
  }
}
    `;
export type DigitalSatTestAnswerCreateMutationFn = Apollo.MutationFunction<DigitalSatTestAnswerCreateMutation, DigitalSatTestAnswerCreateMutationVariables>;

/**
 * __useDigitalSatTestAnswerCreateMutation__
 *
 * To run a mutation, you first call `useDigitalSatTestAnswerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatTestAnswerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatTestAnswerCreateMutation, { data, loading, error }] = useDigitalSatTestAnswerCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      testId: // value for 'testId'
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      questionType: // value for 'questionType'
 *      subjectId: // value for 'subjectId'
 *      optionA: // value for 'optionA'
 *      optionB: // value for 'optionB'
 *      optionC: // value for 'optionC'
 *      optionD: // value for 'optionD'
 *      difficultyLevel: // value for 'difficultyLevel'
 *      score: // value for 'score'
 *      referenceDetails: // value for 'referenceDetails'
 *      explanation: // value for 'explanation'
 *      questionMarks: // value for 'questionMarks'
 *      status: // value for 'status'
 *      tagIds: // value for 'tagIds'
 *      type: // value for 'type'
 *      vimeoId: // value for 'vimeoId'
 *      isSaveToQuestionbank: // value for 'isSaveToQuestionbank'
 *      questionBankId: // value for 'questionBankId'
 *   },
 * });
 */
export function useDigitalSatTestAnswerCreateMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatTestAnswerCreateMutation, DigitalSatTestAnswerCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatTestAnswerCreateMutation, DigitalSatTestAnswerCreateMutationVariables>(DigitalSatTestAnswerCreateDocument, options);
      }
export type DigitalSatTestAnswerCreateMutationHookResult = ReturnType<typeof useDigitalSatTestAnswerCreateMutation>;
export type DigitalSatTestAnswerCreateMutationResult = Apollo.MutationResult<DigitalSatTestAnswerCreateMutation>;
export type DigitalSatTestAnswerCreateMutationOptions = Apollo.BaseMutationOptions<DigitalSatTestAnswerCreateMutation, DigitalSatTestAnswerCreateMutationVariables>;
export const FaqCreateDocument = gql`
    mutation faqCreate($input: FaqCreateInput!) {
  faqCreate(input: $input) {
    id
    question
    answer
    order
    status
  }
}
    `;
export type FaqCreateMutationFn = Apollo.MutationFunction<FaqCreateMutation, FaqCreateMutationVariables>;

/**
 * __useFaqCreateMutation__
 *
 * To run a mutation, you first call `useFaqCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqCreateMutation, { data, loading, error }] = useFaqCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqCreateMutation(baseOptions?: Apollo.MutationHookOptions<FaqCreateMutation, FaqCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FaqCreateMutation, FaqCreateMutationVariables>(FaqCreateDocument, options);
      }
export type FaqCreateMutationHookResult = ReturnType<typeof useFaqCreateMutation>;
export type FaqCreateMutationResult = Apollo.MutationResult<FaqCreateMutation>;
export type FaqCreateMutationOptions = Apollo.BaseMutationOptions<FaqCreateMutation, FaqCreateMutationVariables>;
export const FaqsDeleteDocument = gql`
    mutation FaqsDelete($id: ID!) {
  faqDeleteById(id: $id)
}
    `;
export type FaqsDeleteMutationFn = Apollo.MutationFunction<FaqsDeleteMutation, FaqsDeleteMutationVariables>;

/**
 * __useFaqsDeleteMutation__
 *
 * To run a mutation, you first call `useFaqsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqsDeleteMutation, { data, loading, error }] = useFaqsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFaqsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<FaqsDeleteMutation, FaqsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FaqsDeleteMutation, FaqsDeleteMutationVariables>(FaqsDeleteDocument, options);
      }
export type FaqsDeleteMutationHookResult = ReturnType<typeof useFaqsDeleteMutation>;
export type FaqsDeleteMutationResult = Apollo.MutationResult<FaqsDeleteMutation>;
export type FaqsDeleteMutationOptions = Apollo.BaseMutationOptions<FaqsDeleteMutation, FaqsDeleteMutationVariables>;
export const TestAnswerDeleteDocument = gql`
    mutation TestAnswerDelete($id: ID!, $isChecked: Boolean) {
  testAnswerDeleteById(id: $id, isChecked: $isChecked) {
    id
  }
}
    `;
export type TestAnswerDeleteMutationFn = Apollo.MutationFunction<TestAnswerDeleteMutation, TestAnswerDeleteMutationVariables>;

/**
 * __useTestAnswerDeleteMutation__
 *
 * To run a mutation, you first call `useTestAnswerDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestAnswerDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testAnswerDeleteMutation, { data, loading, error }] = useTestAnswerDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isChecked: // value for 'isChecked'
 *   },
 * });
 */
export function useTestAnswerDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TestAnswerDeleteMutation, TestAnswerDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestAnswerDeleteMutation, TestAnswerDeleteMutationVariables>(TestAnswerDeleteDocument, options);
      }
export type TestAnswerDeleteMutationHookResult = ReturnType<typeof useTestAnswerDeleteMutation>;
export type TestAnswerDeleteMutationResult = Apollo.MutationResult<TestAnswerDeleteMutation>;
export type TestAnswerDeleteMutationOptions = Apollo.BaseMutationOptions<TestAnswerDeleteMutation, TestAnswerDeleteMutationVariables>;
export const FaqStatusUpdateDocument = gql`
    mutation faqStatusUpdate($input: FaqInput!) {
  faqStatusUpdate(input: $input) {
    id
    status
  }
}
    `;
export type FaqStatusUpdateMutationFn = Apollo.MutationFunction<FaqStatusUpdateMutation, FaqStatusUpdateMutationVariables>;

/**
 * __useFaqStatusUpdateMutation__
 *
 * To run a mutation, you first call `useFaqStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqStatusUpdateMutation, { data, loading, error }] = useFaqStatusUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<FaqStatusUpdateMutation, FaqStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FaqStatusUpdateMutation, FaqStatusUpdateMutationVariables>(FaqStatusUpdateDocument, options);
      }
export type FaqStatusUpdateMutationHookResult = ReturnType<typeof useFaqStatusUpdateMutation>;
export type FaqStatusUpdateMutationResult = Apollo.MutationResult<FaqStatusUpdateMutation>;
export type FaqStatusUpdateMutationOptions = Apollo.BaseMutationOptions<FaqStatusUpdateMutation, FaqStatusUpdateMutationVariables>;
export const SetFaqOrderDocument = gql`
    mutation setFaqOrder($faqIds: [ID!]!) {
  setFaqOrder(faqIds: $faqIds) {
    order
    status
    question
    answer
  }
}
    `;
export type SetFaqOrderMutationFn = Apollo.MutationFunction<SetFaqOrderMutation, SetFaqOrderMutationVariables>;

/**
 * __useSetFaqOrderMutation__
 *
 * To run a mutation, you first call `useSetFaqOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFaqOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFaqOrderMutation, { data, loading, error }] = useSetFaqOrderMutation({
 *   variables: {
 *      faqIds: // value for 'faqIds'
 *   },
 * });
 */
export function useSetFaqOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetFaqOrderMutation, SetFaqOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFaqOrderMutation, SetFaqOrderMutationVariables>(SetFaqOrderDocument, options);
      }
export type SetFaqOrderMutationHookResult = ReturnType<typeof useSetFaqOrderMutation>;
export type SetFaqOrderMutationResult = Apollo.MutationResult<SetFaqOrderMutation>;
export type SetFaqOrderMutationOptions = Apollo.BaseMutationOptions<SetFaqOrderMutation, SetFaqOrderMutationVariables>;
export const DigitalSatQuestionStatusUpdateDocument = gql`
    mutation digitalSatQuestionStatusUpdate($input: DigitalSatQuestionStatusUpdateInput!) {
  digitalSatQuestionStatusUpdate(input: $input) {
    id
    status
  }
}
    `;
export type DigitalSatQuestionStatusUpdateMutationFn = Apollo.MutationFunction<DigitalSatQuestionStatusUpdateMutation, DigitalSatQuestionStatusUpdateMutationVariables>;

/**
 * __useDigitalSatQuestionStatusUpdateMutation__
 *
 * To run a mutation, you first call `useDigitalSatQuestionStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatQuestionStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatQuestionStatusUpdateMutation, { data, loading, error }] = useDigitalSatQuestionStatusUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDigitalSatQuestionStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatQuestionStatusUpdateMutation, DigitalSatQuestionStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatQuestionStatusUpdateMutation, DigitalSatQuestionStatusUpdateMutationVariables>(DigitalSatQuestionStatusUpdateDocument, options);
      }
export type DigitalSatQuestionStatusUpdateMutationHookResult = ReturnType<typeof useDigitalSatQuestionStatusUpdateMutation>;
export type DigitalSatQuestionStatusUpdateMutationResult = Apollo.MutationResult<DigitalSatQuestionStatusUpdateMutation>;
export type DigitalSatQuestionStatusUpdateMutationOptions = Apollo.BaseMutationOptions<DigitalSatQuestionStatusUpdateMutation, DigitalSatQuestionStatusUpdateMutationVariables>;
export const DigitalSatImportQuestionCreateDocument = gql`
    mutation digitalSatImportQuestionCreate($testId: ID!, $subjectId: ID!, $uploadFile: String!, $type: String!) {
  digitalSatImportQuestionCreate(
    testId: $testId
    subjectId: $subjectId
    uploadFile: $uploadFile
    type: $type
  ) {
    id
  }
}
    `;
export type DigitalSatImportQuestionCreateMutationFn = Apollo.MutationFunction<DigitalSatImportQuestionCreateMutation, DigitalSatImportQuestionCreateMutationVariables>;

/**
 * __useDigitalSatImportQuestionCreateMutation__
 *
 * To run a mutation, you first call `useDigitalSatImportQuestionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatImportQuestionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatImportQuestionCreateMutation, { data, loading, error }] = useDigitalSatImportQuestionCreateMutation({
 *   variables: {
 *      testId: // value for 'testId'
 *      subjectId: // value for 'subjectId'
 *      uploadFile: // value for 'uploadFile'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDigitalSatImportQuestionCreateMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatImportQuestionCreateMutation, DigitalSatImportQuestionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatImportQuestionCreateMutation, DigitalSatImportQuestionCreateMutationVariables>(DigitalSatImportQuestionCreateDocument, options);
      }
export type DigitalSatImportQuestionCreateMutationHookResult = ReturnType<typeof useDigitalSatImportQuestionCreateMutation>;
export type DigitalSatImportQuestionCreateMutationResult = Apollo.MutationResult<DigitalSatImportQuestionCreateMutation>;
export type DigitalSatImportQuestionCreateMutationOptions = Apollo.BaseMutationOptions<DigitalSatImportQuestionCreateMutation, DigitalSatImportQuestionCreateMutationVariables>;
export const DigitalSatPracticeSetCreateDocument = gql`
    mutation digitalSatPracticeSetCreate($title: String!, $mode: String!, $lessonId: ID!, $vimeoId: Int!, $difficultyLevel: String!, $isCompulsion: Boolean!) {
  digitalSatPracticeSetCreate(
    title: $title
    mode: $mode
    lessonId: $lessonId
    vimeoId: $vimeoId
    isCompulsion: $isCompulsion
    difficultyLevel: $difficultyLevel
  ) {
    id
  }
}
    `;
export type DigitalSatPracticeSetCreateMutationFn = Apollo.MutationFunction<DigitalSatPracticeSetCreateMutation, DigitalSatPracticeSetCreateMutationVariables>;

/**
 * __useDigitalSatPracticeSetCreateMutation__
 *
 * To run a mutation, you first call `useDigitalSatPracticeSetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatPracticeSetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatPracticeSetCreateMutation, { data, loading, error }] = useDigitalSatPracticeSetCreateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      mode: // value for 'mode'
 *      lessonId: // value for 'lessonId'
 *      vimeoId: // value for 'vimeoId'
 *      difficultyLevel: // value for 'difficultyLevel'
 *      isCompulsion: // value for 'isCompulsion'
 *   },
 * });
 */
export function useDigitalSatPracticeSetCreateMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatPracticeSetCreateMutation, DigitalSatPracticeSetCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatPracticeSetCreateMutation, DigitalSatPracticeSetCreateMutationVariables>(DigitalSatPracticeSetCreateDocument, options);
      }
export type DigitalSatPracticeSetCreateMutationHookResult = ReturnType<typeof useDigitalSatPracticeSetCreateMutation>;
export type DigitalSatPracticeSetCreateMutationResult = Apollo.MutationResult<DigitalSatPracticeSetCreateMutation>;
export type DigitalSatPracticeSetCreateMutationOptions = Apollo.BaseMutationOptions<DigitalSatPracticeSetCreateMutation, DigitalSatPracticeSetCreateMutationVariables>;
export const DigitalSatPracticeSetCreateQuestionDocument = gql`
    mutation digitalSatPracticeSetCreateQuestion($lessonId: ID!, $videoId: ID!, $vimeoId: Int, $question: String!, $answer: String!, $optionA: String!, $optionB: String!, $optionC: String!, $optionD: String!, $explanation: String, $questionType: String!, $id: ID, $isSaveToQuestionbank: Boolean!, $questionBankId: Int, $tagIds: String, $difficultyLevel: String!, $referenceDetails: String) {
  digitalSatPracticeSetCreateQuestion(
    isSaveToQuestionbank: $isSaveToQuestionbank
    questionBankId: $questionBankId
    lessonId: $lessonId
    id: $id
    videoId: $videoId
    vimeoId: $vimeoId
    optionA: $optionA
    optionB: $optionB
    optionC: $optionC
    optionD: $optionD
    question: $question
    answer: $answer
    questionType: $questionType
    explanation: $explanation
    tagIds: $tagIds
    difficultyLevel: $difficultyLevel
    referenceDetails: $referenceDetails
  ) {
    id
  }
}
    `;
export type DigitalSatPracticeSetCreateQuestionMutationFn = Apollo.MutationFunction<DigitalSatPracticeSetCreateQuestionMutation, DigitalSatPracticeSetCreateQuestionMutationVariables>;

/**
 * __useDigitalSatPracticeSetCreateQuestionMutation__
 *
 * To run a mutation, you first call `useDigitalSatPracticeSetCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatPracticeSetCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatPracticeSetCreateQuestionMutation, { data, loading, error }] = useDigitalSatPracticeSetCreateQuestionMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      videoId: // value for 'videoId'
 *      vimeoId: // value for 'vimeoId'
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      optionA: // value for 'optionA'
 *      optionB: // value for 'optionB'
 *      optionC: // value for 'optionC'
 *      optionD: // value for 'optionD'
 *      explanation: // value for 'explanation'
 *      questionType: // value for 'questionType'
 *      id: // value for 'id'
 *      isSaveToQuestionbank: // value for 'isSaveToQuestionbank'
 *      questionBankId: // value for 'questionBankId'
 *      tagIds: // value for 'tagIds'
 *      difficultyLevel: // value for 'difficultyLevel'
 *      referenceDetails: // value for 'referenceDetails'
 *   },
 * });
 */
export function useDigitalSatPracticeSetCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatPracticeSetCreateQuestionMutation, DigitalSatPracticeSetCreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatPracticeSetCreateQuestionMutation, DigitalSatPracticeSetCreateQuestionMutationVariables>(DigitalSatPracticeSetCreateQuestionDocument, options);
      }
export type DigitalSatPracticeSetCreateQuestionMutationHookResult = ReturnType<typeof useDigitalSatPracticeSetCreateQuestionMutation>;
export type DigitalSatPracticeSetCreateQuestionMutationResult = Apollo.MutationResult<DigitalSatPracticeSetCreateQuestionMutation>;
export type DigitalSatPracticeSetCreateQuestionMutationOptions = Apollo.BaseMutationOptions<DigitalSatPracticeSetCreateQuestionMutation, DigitalSatPracticeSetCreateQuestionMutationVariables>;
export const DigitalSatPracticeSetDeleteQuestionDocument = gql`
    mutation digitalSatPracticeSetDeleteQuestion($practiceSetId: ID!, $isChecked: Boolean) {
  digitalSatPracticeSetDeleteQuestion(
    practiceSetId: $practiceSetId
    isChecked: $isChecked
  ) {
    id
  }
}
    `;
export type DigitalSatPracticeSetDeleteQuestionMutationFn = Apollo.MutationFunction<DigitalSatPracticeSetDeleteQuestionMutation, DigitalSatPracticeSetDeleteQuestionMutationVariables>;

/**
 * __useDigitalSatPracticeSetDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDigitalSatPracticeSetDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatPracticeSetDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatPracticeSetDeleteQuestionMutation, { data, loading, error }] = useDigitalSatPracticeSetDeleteQuestionMutation({
 *   variables: {
 *      practiceSetId: // value for 'practiceSetId'
 *      isChecked: // value for 'isChecked'
 *   },
 * });
 */
export function useDigitalSatPracticeSetDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatPracticeSetDeleteQuestionMutation, DigitalSatPracticeSetDeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatPracticeSetDeleteQuestionMutation, DigitalSatPracticeSetDeleteQuestionMutationVariables>(DigitalSatPracticeSetDeleteQuestionDocument, options);
      }
export type DigitalSatPracticeSetDeleteQuestionMutationHookResult = ReturnType<typeof useDigitalSatPracticeSetDeleteQuestionMutation>;
export type DigitalSatPracticeSetDeleteQuestionMutationResult = Apollo.MutationResult<DigitalSatPracticeSetDeleteQuestionMutation>;
export type DigitalSatPracticeSetDeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DigitalSatPracticeSetDeleteQuestionMutation, DigitalSatPracticeSetDeleteQuestionMutationVariables>;
export const DigitalSatPracticeSetIsPulishedDocument = gql`
    mutation digitalSatPracticeSetIsPulished($id: ID!, $isPublished: Boolean!) {
  digitalSatPracticeSetIsPulished(id: $id, isPublished: $isPublished)
}
    `;
export type DigitalSatPracticeSetIsPulishedMutationFn = Apollo.MutationFunction<DigitalSatPracticeSetIsPulishedMutation, DigitalSatPracticeSetIsPulishedMutationVariables>;

/**
 * __useDigitalSatPracticeSetIsPulishedMutation__
 *
 * To run a mutation, you first call `useDigitalSatPracticeSetIsPulishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatPracticeSetIsPulishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalSatPracticeSetIsPulishedMutation, { data, loading, error }] = useDigitalSatPracticeSetIsPulishedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useDigitalSatPracticeSetIsPulishedMutation(baseOptions?: Apollo.MutationHookOptions<DigitalSatPracticeSetIsPulishedMutation, DigitalSatPracticeSetIsPulishedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DigitalSatPracticeSetIsPulishedMutation, DigitalSatPracticeSetIsPulishedMutationVariables>(DigitalSatPracticeSetIsPulishedDocument, options);
      }
export type DigitalSatPracticeSetIsPulishedMutationHookResult = ReturnType<typeof useDigitalSatPracticeSetIsPulishedMutation>;
export type DigitalSatPracticeSetIsPulishedMutationResult = Apollo.MutationResult<DigitalSatPracticeSetIsPulishedMutation>;
export type DigitalSatPracticeSetIsPulishedMutationOptions = Apollo.BaseMutationOptions<DigitalSatPracticeSetIsPulishedMutation, DigitalSatPracticeSetIsPulishedMutationVariables>;
export const PracticeSetImportQuestionDocument = gql`
    mutation practiceSetImportQuestion($videoId: ID!, $uploadFile: String!) {
  practiceSetImportQuestion(videoId: $videoId, uploadFile: $uploadFile) {
    id
  }
}
    `;
export type PracticeSetImportQuestionMutationFn = Apollo.MutationFunction<PracticeSetImportQuestionMutation, PracticeSetImportQuestionMutationVariables>;

/**
 * __usePracticeSetImportQuestionMutation__
 *
 * To run a mutation, you first call `usePracticeSetImportQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePracticeSetImportQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [practiceSetImportQuestionMutation, { data, loading, error }] = usePracticeSetImportQuestionMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      uploadFile: // value for 'uploadFile'
 *   },
 * });
 */
export function usePracticeSetImportQuestionMutation(baseOptions?: Apollo.MutationHookOptions<PracticeSetImportQuestionMutation, PracticeSetImportQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PracticeSetImportQuestionMutation, PracticeSetImportQuestionMutationVariables>(PracticeSetImportQuestionDocument, options);
      }
export type PracticeSetImportQuestionMutationHookResult = ReturnType<typeof usePracticeSetImportQuestionMutation>;
export type PracticeSetImportQuestionMutationResult = Apollo.MutationResult<PracticeSetImportQuestionMutation>;
export type PracticeSetImportQuestionMutationOptions = Apollo.BaseMutationOptions<PracticeSetImportQuestionMutation, PracticeSetImportQuestionMutationVariables>;
export const CriteriaManuallyEntryUpdateDocument = gql`
    mutation criteriaManuallyEntryUpdate($input: CriteriaManuallyEntryUpdateInput!) {
  criteriaManuallyEntryUpdate(input: $input) {
    id
  }
}
    `;
export type CriteriaManuallyEntryUpdateMutationFn = Apollo.MutationFunction<CriteriaManuallyEntryUpdateMutation, CriteriaManuallyEntryUpdateMutationVariables>;

/**
 * __useCriteriaManuallyEntryUpdateMutation__
 *
 * To run a mutation, you first call `useCriteriaManuallyEntryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCriteriaManuallyEntryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [criteriaManuallyEntryUpdateMutation, { data, loading, error }] = useCriteriaManuallyEntryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCriteriaManuallyEntryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CriteriaManuallyEntryUpdateMutation, CriteriaManuallyEntryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CriteriaManuallyEntryUpdateMutation, CriteriaManuallyEntryUpdateMutationVariables>(CriteriaManuallyEntryUpdateDocument, options);
      }
export type CriteriaManuallyEntryUpdateMutationHookResult = ReturnType<typeof useCriteriaManuallyEntryUpdateMutation>;
export type CriteriaManuallyEntryUpdateMutationResult = Apollo.MutationResult<CriteriaManuallyEntryUpdateMutation>;
export type CriteriaManuallyEntryUpdateMutationOptions = Apollo.BaseMutationOptions<CriteriaManuallyEntryUpdateMutation, CriteriaManuallyEntryUpdateMutationVariables>;
export const QuestionBankCreateDocument = gql`
    mutation questionBankCreate($id: ID, $question: String!, $answer: String!, $questionType: String!, $testTitle: String!, $optionA: String!, $optionB: String!, $optionC: String!, $optionD: String!, $difficultyLevel: String!, $referenceDetails: String, $explanation: String, $status: String!, $vimeoId: Int, $questionTags: String!, $tagIds: String) {
  questionBankCreate(
    tagIds: $tagIds
    questionTags: $questionTags
    id: $id
    question: $question
    answer: $answer
    questionType: $questionType
    testTitle: $testTitle
    optionA: $optionA
    optionB: $optionB
    optionC: $optionC
    optionD: $optionD
    difficultyLevel: $difficultyLevel
    referenceDetails: $referenceDetails
    explanation: $explanation
    status: $status
    vimeoId: $vimeoId
  ) {
    id
    questionTags
    question
    answer
    testTitle
    questionType
    optionA
    optionB
    optionC
    optionD
    difficultyLevel
    referenceDetails
    explanation
    status
    serialNumber
  }
}
    `;
export type QuestionBankCreateMutationFn = Apollo.MutationFunction<QuestionBankCreateMutation, QuestionBankCreateMutationVariables>;

/**
 * __useQuestionBankCreateMutation__
 *
 * To run a mutation, you first call `useQuestionBankCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionBankCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionBankCreateMutation, { data, loading, error }] = useQuestionBankCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      questionType: // value for 'questionType'
 *      testTitle: // value for 'testTitle'
 *      optionA: // value for 'optionA'
 *      optionB: // value for 'optionB'
 *      optionC: // value for 'optionC'
 *      optionD: // value for 'optionD'
 *      difficultyLevel: // value for 'difficultyLevel'
 *      referenceDetails: // value for 'referenceDetails'
 *      explanation: // value for 'explanation'
 *      status: // value for 'status'
 *      vimeoId: // value for 'vimeoId'
 *      questionTags: // value for 'questionTags'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useQuestionBankCreateMutation(baseOptions?: Apollo.MutationHookOptions<QuestionBankCreateMutation, QuestionBankCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuestionBankCreateMutation, QuestionBankCreateMutationVariables>(QuestionBankCreateDocument, options);
      }
export type QuestionBankCreateMutationHookResult = ReturnType<typeof useQuestionBankCreateMutation>;
export type QuestionBankCreateMutationResult = Apollo.MutationResult<QuestionBankCreateMutation>;
export type QuestionBankCreateMutationOptions = Apollo.BaseMutationOptions<QuestionBankCreateMutation, QuestionBankCreateMutationVariables>;
export const QuestionBankDeleteDocument = gql`
    mutation questionBankDelete($id: ID!, $isChecked: Boolean) {
  questionBankDelete(id: $id, isChecked: $isChecked) {
    id
  }
}
    `;
export type QuestionBankDeleteMutationFn = Apollo.MutationFunction<QuestionBankDeleteMutation, QuestionBankDeleteMutationVariables>;

/**
 * __useQuestionBankDeleteMutation__
 *
 * To run a mutation, you first call `useQuestionBankDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionBankDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionBankDeleteMutation, { data, loading, error }] = useQuestionBankDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isChecked: // value for 'isChecked'
 *   },
 * });
 */
export function useQuestionBankDeleteMutation(baseOptions?: Apollo.MutationHookOptions<QuestionBankDeleteMutation, QuestionBankDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuestionBankDeleteMutation, QuestionBankDeleteMutationVariables>(QuestionBankDeleteDocument, options);
      }
export type QuestionBankDeleteMutationHookResult = ReturnType<typeof useQuestionBankDeleteMutation>;
export type QuestionBankDeleteMutationResult = Apollo.MutationResult<QuestionBankDeleteMutation>;
export type QuestionBankDeleteMutationOptions = Apollo.BaseMutationOptions<QuestionBankDeleteMutation, QuestionBankDeleteMutationVariables>;
export const QuestionBankStatusUpdateDocument = gql`
    mutation questionBankStatusUpdate($id: ID!, $status: Boolean!) {
  questionBankStatusUpdate(id: $id, status: $status)
}
    `;
export type QuestionBankStatusUpdateMutationFn = Apollo.MutationFunction<QuestionBankStatusUpdateMutation, QuestionBankStatusUpdateMutationVariables>;

/**
 * __useQuestionBankStatusUpdateMutation__
 *
 * To run a mutation, you first call `useQuestionBankStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionBankStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionBankStatusUpdateMutation, { data, loading, error }] = useQuestionBankStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useQuestionBankStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<QuestionBankStatusUpdateMutation, QuestionBankStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuestionBankStatusUpdateMutation, QuestionBankStatusUpdateMutationVariables>(QuestionBankStatusUpdateDocument, options);
      }
export type QuestionBankStatusUpdateMutationHookResult = ReturnType<typeof useQuestionBankStatusUpdateMutation>;
export type QuestionBankStatusUpdateMutationResult = Apollo.MutationResult<QuestionBankStatusUpdateMutation>;
export type QuestionBankStatusUpdateMutationOptions = Apollo.BaseMutationOptions<QuestionBankStatusUpdateMutation, QuestionBankStatusUpdateMutationVariables>;
export const QuestionBankSearchDocument = gql`
    mutation questionBankSearch($searchText: String!, $type: String!) {
  questionBankSearch(searchText: $searchText, type: $type) {
    ...QuestionBankData
  }
}
    ${QuestionBankDataFragmentDoc}`;
export type QuestionBankSearchMutationFn = Apollo.MutationFunction<QuestionBankSearchMutation, QuestionBankSearchMutationVariables>;

/**
 * __useQuestionBankSearchMutation__
 *
 * To run a mutation, you first call `useQuestionBankSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionBankSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionBankSearchMutation, { data, loading, error }] = useQuestionBankSearchMutation({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useQuestionBankSearchMutation(baseOptions?: Apollo.MutationHookOptions<QuestionBankSearchMutation, QuestionBankSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuestionBankSearchMutation, QuestionBankSearchMutationVariables>(QuestionBankSearchDocument, options);
      }
export type QuestionBankSearchMutationHookResult = ReturnType<typeof useQuestionBankSearchMutation>;
export type QuestionBankSearchMutationResult = Apollo.MutationResult<QuestionBankSearchMutation>;
export type QuestionBankSearchMutationOptions = Apollo.BaseMutationOptions<QuestionBankSearchMutation, QuestionBankSearchMutationVariables>;
export const ToggleQuestionBankTagDocument = gql`
    mutation ToggleQuestionBankTag($questionbankId: ID!, $tagId: ID!) {
  tagToggleAssignQuestionBank(questionbankId: $questionbankId, tagId: $tagId) {
    id
    tags {
      ...TagData
    }
  }
}
    ${TagDataFragmentDoc}`;
export type ToggleQuestionBankTagMutationFn = Apollo.MutationFunction<ToggleQuestionBankTagMutation, ToggleQuestionBankTagMutationVariables>;

/**
 * __useToggleQuestionBankTagMutation__
 *
 * To run a mutation, you first call `useToggleQuestionBankTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleQuestionBankTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleQuestionBankTagMutation, { data, loading, error }] = useToggleQuestionBankTagMutation({
 *   variables: {
 *      questionbankId: // value for 'questionbankId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useToggleQuestionBankTagMutation(baseOptions?: Apollo.MutationHookOptions<ToggleQuestionBankTagMutation, ToggleQuestionBankTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleQuestionBankTagMutation, ToggleQuestionBankTagMutationVariables>(ToggleQuestionBankTagDocument, options);
      }
export type ToggleQuestionBankTagMutationHookResult = ReturnType<typeof useToggleQuestionBankTagMutation>;
export type ToggleQuestionBankTagMutationResult = Apollo.MutationResult<ToggleQuestionBankTagMutation>;
export type ToggleQuestionBankTagMutationOptions = Apollo.BaseMutationOptions<ToggleQuestionBankTagMutation, ToggleQuestionBankTagMutationVariables>;
export const LessonDeleteDocument = gql`
    mutation lessonDelete($id: ID!) {
  lessonDelete(id: $id) {
    id
  }
}
    `;
export type LessonDeleteMutationFn = Apollo.MutationFunction<LessonDeleteMutation, LessonDeleteMutationVariables>;

/**
 * __useLessonDeleteMutation__
 *
 * To run a mutation, you first call `useLessonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonDeleteMutation, { data, loading, error }] = useLessonDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonDeleteMutation(baseOptions?: Apollo.MutationHookOptions<LessonDeleteMutation, LessonDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonDeleteMutation, LessonDeleteMutationVariables>(LessonDeleteDocument, options);
      }
export type LessonDeleteMutationHookResult = ReturnType<typeof useLessonDeleteMutation>;
export type LessonDeleteMutationResult = Apollo.MutationResult<LessonDeleteMutation>;
export type LessonDeleteMutationOptions = Apollo.BaseMutationOptions<LessonDeleteMutation, LessonDeleteMutationVariables>;
export const PlaylistDeleteDocument = gql`
    mutation playlistDelete($id: ID!) {
  playlistDelete(id: $id) {
    id
  }
}
    `;
export type PlaylistDeleteMutationFn = Apollo.MutationFunction<PlaylistDeleteMutation, PlaylistDeleteMutationVariables>;

/**
 * __usePlaylistDeleteMutation__
 *
 * To run a mutation, you first call `usePlaylistDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaylistDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playlistDeleteMutation, { data, loading, error }] = usePlaylistDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistDeleteMutation(baseOptions?: Apollo.MutationHookOptions<PlaylistDeleteMutation, PlaylistDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaylistDeleteMutation, PlaylistDeleteMutationVariables>(PlaylistDeleteDocument, options);
      }
export type PlaylistDeleteMutationHookResult = ReturnType<typeof usePlaylistDeleteMutation>;
export type PlaylistDeleteMutationResult = Apollo.MutationResult<PlaylistDeleteMutation>;
export type PlaylistDeleteMutationOptions = Apollo.BaseMutationOptions<PlaylistDeleteMutation, PlaylistDeleteMutationVariables>;
export const LessonCopyCreateDocument = gql`
    mutation lessonCopyCreate($lessonId: ID!, $playlistId: ID!) {
  lessonCopyCreate(lessonId: $lessonId, playlistId: $playlistId) {
    id
  }
}
    `;
export type LessonCopyCreateMutationFn = Apollo.MutationFunction<LessonCopyCreateMutation, LessonCopyCreateMutationVariables>;

/**
 * __useLessonCopyCreateMutation__
 *
 * To run a mutation, you first call `useLessonCopyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonCopyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonCopyCreateMutation, { data, loading, error }] = useLessonCopyCreateMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useLessonCopyCreateMutation(baseOptions?: Apollo.MutationHookOptions<LessonCopyCreateMutation, LessonCopyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonCopyCreateMutation, LessonCopyCreateMutationVariables>(LessonCopyCreateDocument, options);
      }
export type LessonCopyCreateMutationHookResult = ReturnType<typeof useLessonCopyCreateMutation>;
export type LessonCopyCreateMutationResult = Apollo.MutationResult<LessonCopyCreateMutation>;
export type LessonCopyCreateMutationOptions = Apollo.BaseMutationOptions<LessonCopyCreateMutation, LessonCopyCreateMutationVariables>;
export const ToggleParcticeSetTagDocument = gql`
    mutation toggleParcticeSetTag($tagId: ID!, $questionId: ID!) {
  toggleParcticeSetTag(tagId: $tagId, questionId: $questionId)
}
    `;
export type ToggleParcticeSetTagMutationFn = Apollo.MutationFunction<ToggleParcticeSetTagMutation, ToggleParcticeSetTagMutationVariables>;

/**
 * __useToggleParcticeSetTagMutation__
 *
 * To run a mutation, you first call `useToggleParcticeSetTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleParcticeSetTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleParcticeSetTagMutation, { data, loading, error }] = useToggleParcticeSetTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useToggleParcticeSetTagMutation(baseOptions?: Apollo.MutationHookOptions<ToggleParcticeSetTagMutation, ToggleParcticeSetTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleParcticeSetTagMutation, ToggleParcticeSetTagMutationVariables>(ToggleParcticeSetTagDocument, options);
      }
export type ToggleParcticeSetTagMutationHookResult = ReturnType<typeof useToggleParcticeSetTagMutation>;
export type ToggleParcticeSetTagMutationResult = Apollo.MutationResult<ToggleParcticeSetTagMutation>;
export type ToggleParcticeSetTagMutationOptions = Apollo.BaseMutationOptions<ToggleParcticeSetTagMutation, ToggleParcticeSetTagMutationVariables>;
export const UpdateLessonVideoDocument = gql`
    mutation updateLessonVideo($id: ID!, $title: String, $mode: String) {
  updateLessonVideo(id: $id, title: $title, mode: $mode) {
    id
  }
}
    `;
export type UpdateLessonVideoMutationFn = Apollo.MutationFunction<UpdateLessonVideoMutation, UpdateLessonVideoMutationVariables>;

/**
 * __useUpdateLessonVideoMutation__
 *
 * To run a mutation, you first call `useUpdateLessonVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonVideoMutation, { data, loading, error }] = useUpdateLessonVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useUpdateLessonVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonVideoMutation, UpdateLessonVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonVideoMutation, UpdateLessonVideoMutationVariables>(UpdateLessonVideoDocument, options);
      }
export type UpdateLessonVideoMutationHookResult = ReturnType<typeof useUpdateLessonVideoMutation>;
export type UpdateLessonVideoMutationResult = Apollo.MutationResult<UpdateLessonVideoMutation>;
export type UpdateLessonVideoMutationOptions = Apollo.BaseMutationOptions<UpdateLessonVideoMutation, UpdateLessonVideoMutationVariables>;
export const QuestionScoreUpdateDocument = gql`
    mutation questionScoreUpdate($input: QuestionScoreUpdateInput!) {
  questionScoreUpdate(input: $input) {
    id
  }
}
    `;
export type QuestionScoreUpdateMutationFn = Apollo.MutationFunction<QuestionScoreUpdateMutation, QuestionScoreUpdateMutationVariables>;

/**
 * __useQuestionScoreUpdateMutation__
 *
 * To run a mutation, you first call `useQuestionScoreUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionScoreUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionScoreUpdateMutation, { data, loading, error }] = useQuestionScoreUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionScoreUpdateMutation(baseOptions?: Apollo.MutationHookOptions<QuestionScoreUpdateMutation, QuestionScoreUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuestionScoreUpdateMutation, QuestionScoreUpdateMutationVariables>(QuestionScoreUpdateDocument, options);
      }
export type QuestionScoreUpdateMutationHookResult = ReturnType<typeof useQuestionScoreUpdateMutation>;
export type QuestionScoreUpdateMutationResult = Apollo.MutationResult<QuestionScoreUpdateMutation>;
export type QuestionScoreUpdateMutationOptions = Apollo.BaseMutationOptions<QuestionScoreUpdateMutation, QuestionScoreUpdateMutationVariables>;
export const ClassCreateDocument = gql`
    mutation classCreate($title: String!, $description: String, $tutorIds: [ID!]!, $studentIds: [ID!]!, $id: ID) {
  classCreate(
    title: $title
    tutorIds: $tutorIds
    studentIds: $studentIds
    description: $description
    id: $id
  ) {
    id
  }
}
    `;
export type ClassCreateMutationFn = Apollo.MutationFunction<ClassCreateMutation, ClassCreateMutationVariables>;

/**
 * __useClassCreateMutation__
 *
 * To run a mutation, you first call `useClassCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classCreateMutation, { data, loading, error }] = useClassCreateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      tutorIds: // value for 'tutorIds'
 *      studentIds: // value for 'studentIds'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassCreateMutation(baseOptions?: Apollo.MutationHookOptions<ClassCreateMutation, ClassCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClassCreateMutation, ClassCreateMutationVariables>(ClassCreateDocument, options);
      }
export type ClassCreateMutationHookResult = ReturnType<typeof useClassCreateMutation>;
export type ClassCreateMutationResult = Apollo.MutationResult<ClassCreateMutation>;
export type ClassCreateMutationOptions = Apollo.BaseMutationOptions<ClassCreateMutation, ClassCreateMutationVariables>;
export const DeleteClassDocument = gql`
    mutation deleteClass($id: ID!) {
  deleteClass(id: $id)
}
    `;
export type DeleteClassMutationFn = Apollo.MutationFunction<DeleteClassMutation, DeleteClassMutationVariables>;

/**
 * __useDeleteClassMutation__
 *
 * To run a mutation, you first call `useDeleteClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassMutation, { data, loading, error }] = useDeleteClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassMutation, DeleteClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassMutation, DeleteClassMutationVariables>(DeleteClassDocument, options);
      }
export type DeleteClassMutationHookResult = ReturnType<typeof useDeleteClassMutation>;
export type DeleteClassMutationResult = Apollo.MutationResult<DeleteClassMutation>;
export type DeleteClassMutationOptions = Apollo.BaseMutationOptions<DeleteClassMutation, DeleteClassMutationVariables>;
export const SetTestAnswerOrderDocument = gql`
    mutation setTestAnswerOrder($questionIds: [ID!]!) {
  setTestAnswerOrder(questionIds: $questionIds) {
    id
  }
}
    `;
export type SetTestAnswerOrderMutationFn = Apollo.MutationFunction<SetTestAnswerOrderMutation, SetTestAnswerOrderMutationVariables>;

/**
 * __useSetTestAnswerOrderMutation__
 *
 * To run a mutation, you first call `useSetTestAnswerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTestAnswerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTestAnswerOrderMutation, { data, loading, error }] = useSetTestAnswerOrderMutation({
 *   variables: {
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useSetTestAnswerOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetTestAnswerOrderMutation, SetTestAnswerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTestAnswerOrderMutation, SetTestAnswerOrderMutationVariables>(SetTestAnswerOrderDocument, options);
      }
export type SetTestAnswerOrderMutationHookResult = ReturnType<typeof useSetTestAnswerOrderMutation>;
export type SetTestAnswerOrderMutationResult = Apollo.MutationResult<SetTestAnswerOrderMutation>;
export type SetTestAnswerOrderMutationOptions = Apollo.BaseMutationOptions<SetTestAnswerOrderMutation, SetTestAnswerOrderMutationVariables>;
export const SetPracticeSetOrderDocument = gql`
    mutation setPracticeSetOrder($questionIds: [ID!]!) {
  setPracticeSetOrder(questionIds: $questionIds) {
    id
  }
}
    `;
export type SetPracticeSetOrderMutationFn = Apollo.MutationFunction<SetPracticeSetOrderMutation, SetPracticeSetOrderMutationVariables>;

/**
 * __useSetPracticeSetOrderMutation__
 *
 * To run a mutation, you first call `useSetPracticeSetOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPracticeSetOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPracticeSetOrderMutation, { data, loading, error }] = useSetPracticeSetOrderMutation({
 *   variables: {
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useSetPracticeSetOrderMutation(baseOptions?: Apollo.MutationHookOptions<SetPracticeSetOrderMutation, SetPracticeSetOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPracticeSetOrderMutation, SetPracticeSetOrderMutationVariables>(SetPracticeSetOrderDocument, options);
      }
export type SetPracticeSetOrderMutationHookResult = ReturnType<typeof useSetPracticeSetOrderMutation>;
export type SetPracticeSetOrderMutationResult = Apollo.MutationResult<SetPracticeSetOrderMutation>;
export type SetPracticeSetOrderMutationOptions = Apollo.BaseMutationOptions<SetPracticeSetOrderMutation, SetPracticeSetOrderMutationVariables>;
export const AssignTutorToStudentDocument = gql`
    mutation assignTutorToStudent($id: ID!, $tutorIds: [ID!]!, $classIds: [ID!]!) {
  assignTutorToStudent(id: $id, tutorIds: $tutorIds, classIds: $classIds) {
    id
  }
}
    `;
export type AssignTutorToStudentMutationFn = Apollo.MutationFunction<AssignTutorToStudentMutation, AssignTutorToStudentMutationVariables>;

/**
 * __useAssignTutorToStudentMutation__
 *
 * To run a mutation, you first call `useAssignTutorToStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTutorToStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTutorToStudentMutation, { data, loading, error }] = useAssignTutorToStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tutorIds: // value for 'tutorIds'
 *      classIds: // value for 'classIds'
 *   },
 * });
 */
export function useAssignTutorToStudentMutation(baseOptions?: Apollo.MutationHookOptions<AssignTutorToStudentMutation, AssignTutorToStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTutorToStudentMutation, AssignTutorToStudentMutationVariables>(AssignTutorToStudentDocument, options);
      }
export type AssignTutorToStudentMutationHookResult = ReturnType<typeof useAssignTutorToStudentMutation>;
export type AssignTutorToStudentMutationResult = Apollo.MutationResult<AssignTutorToStudentMutation>;
export type AssignTutorToStudentMutationOptions = Apollo.BaseMutationOptions<AssignTutorToStudentMutation, AssignTutorToStudentMutationVariables>;
export const HomeworkStatusUpdateDocument = gql`
    mutation homeworkStatusUpdate($id: ID!, $status: Boolean!) {
  homeworkStatusUpdate(id: $id, status: $status)
}
    `;
export type HomeworkStatusUpdateMutationFn = Apollo.MutationFunction<HomeworkStatusUpdateMutation, HomeworkStatusUpdateMutationVariables>;

/**
 * __useHomeworkStatusUpdateMutation__
 *
 * To run a mutation, you first call `useHomeworkStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeworkStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeworkStatusUpdateMutation, { data, loading, error }] = useHomeworkStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useHomeworkStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HomeworkStatusUpdateMutation, HomeworkStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HomeworkStatusUpdateMutation, HomeworkStatusUpdateMutationVariables>(HomeworkStatusUpdateDocument, options);
      }
export type HomeworkStatusUpdateMutationHookResult = ReturnType<typeof useHomeworkStatusUpdateMutation>;
export type HomeworkStatusUpdateMutationResult = Apollo.MutationResult<HomeworkStatusUpdateMutation>;
export type HomeworkStatusUpdateMutationOptions = Apollo.BaseMutationOptions<HomeworkStatusUpdateMutation, HomeworkStatusUpdateMutationVariables>;
export const HomeworkCreateDocument = gql`
    mutation homeworkCreate($status: Boolean!, $startDate: String!, $dueDate: String!, $title: String!, $students: [ID!]!, $classes: [ID!]!, $lessonIds: String, $quizIds: String, $explanation: String, $assignments: String, $description: String, $testIds: String, $bluebookIds: String, $mode: String, $id: String) {
  homeworkCreate(
    status: $status
    startDate: $startDate
    dueDate: $dueDate
    title: $title
    students: $students
    classes: $classes
    description: $description
    lessonIds: $lessonIds
    quizIds: $quizIds
    explanation: $explanation
    assignments: $assignments
    testIds: $testIds
    bluebookIds: $bluebookIds
    mode: $mode
    id: $id
  ) {
    id
  }
}
    `;
export type HomeworkCreateMutationFn = Apollo.MutationFunction<HomeworkCreateMutation, HomeworkCreateMutationVariables>;

/**
 * __useHomeworkCreateMutation__
 *
 * To run a mutation, you first call `useHomeworkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeworkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeworkCreateMutation, { data, loading, error }] = useHomeworkCreateMutation({
 *   variables: {
 *      status: // value for 'status'
 *      startDate: // value for 'startDate'
 *      dueDate: // value for 'dueDate'
 *      title: // value for 'title'
 *      students: // value for 'students'
 *      classes: // value for 'classes'
 *      lessonIds: // value for 'lessonIds'
 *      quizIds: // value for 'quizIds'
 *      explanation: // value for 'explanation'
 *      assignments: // value for 'assignments'
 *      description: // value for 'description'
 *      testIds: // value for 'testIds'
 *      bluebookIds: // value for 'bluebookIds'
 *      mode: // value for 'mode'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeworkCreateMutation(baseOptions?: Apollo.MutationHookOptions<HomeworkCreateMutation, HomeworkCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HomeworkCreateMutation, HomeworkCreateMutationVariables>(HomeworkCreateDocument, options);
      }
export type HomeworkCreateMutationHookResult = ReturnType<typeof useHomeworkCreateMutation>;
export type HomeworkCreateMutationResult = Apollo.MutationResult<HomeworkCreateMutation>;
export type HomeworkCreateMutationOptions = Apollo.BaseMutationOptions<HomeworkCreateMutation, HomeworkCreateMutationVariables>;
export const DeleteHomeworkDocument = gql`
    mutation deleteHomework($id: Int!) {
  deleteHomework(id: $id) {
    id
  }
}
    `;
export type DeleteHomeworkMutationFn = Apollo.MutationFunction<DeleteHomeworkMutation, DeleteHomeworkMutationVariables>;

/**
 * __useDeleteHomeworkMutation__
 *
 * To run a mutation, you first call `useDeleteHomeworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHomeworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHomeworkMutation, { data, loading, error }] = useDeleteHomeworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHomeworkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHomeworkMutation, DeleteHomeworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHomeworkMutation, DeleteHomeworkMutationVariables>(DeleteHomeworkDocument, options);
      }
export type DeleteHomeworkMutationHookResult = ReturnType<typeof useDeleteHomeworkMutation>;
export type DeleteHomeworkMutationResult = Apollo.MutationResult<DeleteHomeworkMutation>;
export type DeleteHomeworkMutationOptions = Apollo.BaseMutationOptions<DeleteHomeworkMutation, DeleteHomeworkMutationVariables>;
export const TagCategoryCreateDocument = gql`
    mutation tagCategoryCreate($name: String!, $tagIds: [ID!]!, $subject: String!, $id: ID) {
  tagCategoryCreate(name: $name, tagIds: $tagIds, subject: $subject, id: $id) {
    id
  }
}
    `;
export type TagCategoryCreateMutationFn = Apollo.MutationFunction<TagCategoryCreateMutation, TagCategoryCreateMutationVariables>;

/**
 * __useTagCategoryCreateMutation__
 *
 * To run a mutation, you first call `useTagCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCategoryCreateMutation, { data, loading, error }] = useTagCategoryCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tagIds: // value for 'tagIds'
 *      subject: // value for 'subject'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<TagCategoryCreateMutation, TagCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TagCategoryCreateMutation, TagCategoryCreateMutationVariables>(TagCategoryCreateDocument, options);
      }
export type TagCategoryCreateMutationHookResult = ReturnType<typeof useTagCategoryCreateMutation>;
export type TagCategoryCreateMutationResult = Apollo.MutationResult<TagCategoryCreateMutation>;
export type TagCategoryCreateMutationOptions = Apollo.BaseMutationOptions<TagCategoryCreateMutation, TagCategoryCreateMutationVariables>;
export const TagToggleAssignCategoryDocument = gql`
    mutation tagToggleAssignCategory($tagId: ID!, $tagCategoryId: ID!) {
  tagToggleAssignCategory(tagId: $tagId, tagCategoryId: $tagCategoryId) {
    id
  }
}
    `;
export type TagToggleAssignCategoryMutationFn = Apollo.MutationFunction<TagToggleAssignCategoryMutation, TagToggleAssignCategoryMutationVariables>;

/**
 * __useTagToggleAssignCategoryMutation__
 *
 * To run a mutation, you first call `useTagToggleAssignCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagToggleAssignCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagToggleAssignCategoryMutation, { data, loading, error }] = useTagToggleAssignCategoryMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      tagCategoryId: // value for 'tagCategoryId'
 *   },
 * });
 */
export function useTagToggleAssignCategoryMutation(baseOptions?: Apollo.MutationHookOptions<TagToggleAssignCategoryMutation, TagToggleAssignCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TagToggleAssignCategoryMutation, TagToggleAssignCategoryMutationVariables>(TagToggleAssignCategoryDocument, options);
      }
export type TagToggleAssignCategoryMutationHookResult = ReturnType<typeof useTagToggleAssignCategoryMutation>;
export type TagToggleAssignCategoryMutationResult = Apollo.MutationResult<TagToggleAssignCategoryMutation>;
export type TagToggleAssignCategoryMutationOptions = Apollo.BaseMutationOptions<TagToggleAssignCategoryMutation, TagToggleAssignCategoryMutationVariables>;
export const TagCategoryDeleteDocument = gql`
    mutation tagCategoryDelete($tagCategoryId: ID!) {
  tagCategoryDelete(tagCategoryId: $tagCategoryId)
}
    `;
export type TagCategoryDeleteMutationFn = Apollo.MutationFunction<TagCategoryDeleteMutation, TagCategoryDeleteMutationVariables>;

/**
 * __useTagCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useTagCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCategoryDeleteMutation, { data, loading, error }] = useTagCategoryDeleteMutation({
 *   variables: {
 *      tagCategoryId: // value for 'tagCategoryId'
 *   },
 * });
 */
export function useTagCategoryDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TagCategoryDeleteMutation, TagCategoryDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TagCategoryDeleteMutation, TagCategoryDeleteMutationVariables>(TagCategoryDeleteDocument, options);
      }
export type TagCategoryDeleteMutationHookResult = ReturnType<typeof useTagCategoryDeleteMutation>;
export type TagCategoryDeleteMutationResult = Apollo.MutationResult<TagCategoryDeleteMutation>;
export type TagCategoryDeleteMutationOptions = Apollo.BaseMutationOptions<TagCategoryDeleteMutation, TagCategoryDeleteMutationVariables>;
export const NotificationCreateDocument = gql`
    mutation notificationCreate($title: String!, $description: String!, $status: Boolean!, $userIds: [ID!], $id: ID, $startDate: String!, $endDate: String!, $type: String!) {
  notificationCreate(
    title: $title
    description: $description
    status: $status
    userIds: $userIds
    id: $id
    startDate: $startDate
    endDate: $endDate
    type: $type
  ) {
    id
  }
}
    `;
export type NotificationCreateMutationFn = Apollo.MutationFunction<NotificationCreateMutation, NotificationCreateMutationVariables>;

/**
 * __useNotificationCreateMutation__
 *
 * To run a mutation, you first call `useNotificationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationCreateMutation, { data, loading, error }] = useNotificationCreateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      userIds: // value for 'userIds'
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useNotificationCreateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationCreateMutation, NotificationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationCreateMutation, NotificationCreateMutationVariables>(NotificationCreateDocument, options);
      }
export type NotificationCreateMutationHookResult = ReturnType<typeof useNotificationCreateMutation>;
export type NotificationCreateMutationResult = Apollo.MutationResult<NotificationCreateMutation>;
export type NotificationCreateMutationOptions = Apollo.BaseMutationOptions<NotificationCreateMutation, NotificationCreateMutationVariables>;
export const NotificationUpdateDocument = gql`
    mutation notificationUpdate($id: ID!, $status: Boolean!) {
  notificationUpdate(id: $id, status: $status)
}
    `;
export type NotificationUpdateMutationFn = Apollo.MutationFunction<NotificationUpdateMutation, NotificationUpdateMutationVariables>;

/**
 * __useNotificationUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationUpdateMutation, { data, loading, error }] = useNotificationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useNotificationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationUpdateMutation, NotificationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationUpdateMutation, NotificationUpdateMutationVariables>(NotificationUpdateDocument, options);
      }
export type NotificationUpdateMutationHookResult = ReturnType<typeof useNotificationUpdateMutation>;
export type NotificationUpdateMutationResult = Apollo.MutationResult<NotificationUpdateMutation>;
export type NotificationUpdateMutationOptions = Apollo.BaseMutationOptions<NotificationUpdateMutation, NotificationUpdateMutationVariables>;
export const NotificationDeleteDocument = gql`
    mutation notificationDelete($id: ID!) {
  notificationDelete(id: $id)
}
    `;
export type NotificationDeleteMutationFn = Apollo.MutationFunction<NotificationDeleteMutation, NotificationDeleteMutationVariables>;

/**
 * __useNotificationDeleteMutation__
 *
 * To run a mutation, you first call `useNotificationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationDeleteMutation, { data, loading, error }] = useNotificationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationDeleteMutation(baseOptions?: Apollo.MutationHookOptions<NotificationDeleteMutation, NotificationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationDeleteMutation, NotificationDeleteMutationVariables>(NotificationDeleteDocument, options);
      }
export type NotificationDeleteMutationHookResult = ReturnType<typeof useNotificationDeleteMutation>;
export type NotificationDeleteMutationResult = Apollo.MutationResult<NotificationDeleteMutation>;
export type NotificationDeleteMutationOptions = Apollo.BaseMutationOptions<NotificationDeleteMutation, NotificationDeleteMutationVariables>;
export const MeDocument = gql`
    query Me {
  user {
    id
    role
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    ...CountryData
  }
}
    ${CountryDataFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export function useCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CoursesDocument = gql`
    query Courses {
  courses {
    ...CourseData
  }
}
    ${CourseDataFragmentDoc}`;

/**
 * __useCoursesQuery__
 *
 * To run a query within a React component, call `useCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesQuery(baseOptions?: Apollo.QueryHookOptions<CoursesQuery, CoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, options);
      }
export function useCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoursesQuery, CoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, options);
        }
export function useCoursesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CoursesQuery, CoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CoursesQuery, CoursesQueryVariables>(CoursesDocument, options);
        }
export type CoursesQueryHookResult = ReturnType<typeof useCoursesQuery>;
export type CoursesLazyQueryHookResult = ReturnType<typeof useCoursesLazyQuery>;
export type CoursesSuspenseQueryHookResult = ReturnType<typeof useCoursesSuspenseQuery>;
export type CoursesQueryResult = Apollo.QueryResult<CoursesQuery, CoursesQueryVariables>;
export const SubjectDocument = gql`
    query Subject($subjectId: ID!) {
  subject(subjectId: $subjectId) {
    ...SubjectData
  }
}
    ${SubjectDataFragmentDoc}`;

/**
 * __useSubjectQuery__
 *
 * To run a query within a React component, call `useSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useSubjectQuery(baseOptions: Apollo.QueryHookOptions<SubjectQuery, SubjectQueryVariables> & ({ variables: SubjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, options);
      }
export function useSubjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectQuery, SubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, options);
        }
export function useSubjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubjectQuery, SubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, options);
        }
export type SubjectQueryHookResult = ReturnType<typeof useSubjectQuery>;
export type SubjectLazyQueryHookResult = ReturnType<typeof useSubjectLazyQuery>;
export type SubjectSuspenseQueryHookResult = ReturnType<typeof useSubjectSuspenseQuery>;
export type SubjectQueryResult = Apollo.QueryResult<SubjectQuery, SubjectQueryVariables>;
export const PlaylistDocument = gql`
    query Playlist($playlistId: ID!) {
  playlist(id: $playlistId) {
    ...PlaylistData
  }
}
    ${PlaylistDataFragmentDoc}`;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistQuery(baseOptions: Apollo.QueryHookOptions<PlaylistQuery, PlaylistQueryVariables> & ({ variables: PlaylistQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
      }
export function usePlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
        }
export function usePlaylistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
        }
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<typeof usePlaylistLazyQuery>;
export type PlaylistSuspenseQueryHookResult = ReturnType<typeof usePlaylistSuspenseQuery>;
export type PlaylistQueryResult = Apollo.QueryResult<PlaylistQuery, PlaylistQueryVariables>;
export const StatesDocument = gql`
    query States {
  states {
    ...StateData
  }
}
    ${StateDataFragmentDoc}`;

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<StatesQuery, StatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
      }
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatesQuery, StatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
        }
export function useStatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StatesQuery, StatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
        }
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesSuspenseQueryHookResult = ReturnType<typeof useStatesSuspenseQuery>;
export type StatesQueryResult = Apollo.QueryResult<StatesQuery, StatesQueryVariables>;
export const TagsDocument = gql`
    query Tags($courseId: ID!) {
  tags(courseId: $courseId) {
    ...TagData
  }
}
    ${TagDataFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables> & ({ variables: TagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export function useTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const LessonDocument = gql`
    query Lesson($id: ID!) {
  lesson(id: $id) {
    ...LessonDataExtended
  }
}
    ${LessonDataExtendedFragmentDoc}`;

/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonQuery(baseOptions: Apollo.QueryHookOptions<LessonQuery, LessonQueryVariables> & ({ variables: LessonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
      }
export function useLessonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonQuery, LessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
        }
export function useLessonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LessonQuery, LessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
        }
export type LessonQueryHookResult = ReturnType<typeof useLessonQuery>;
export type LessonLazyQueryHookResult = ReturnType<typeof useLessonLazyQuery>;
export type LessonSuspenseQueryHookResult = ReturnType<typeof useLessonSuspenseQuery>;
export type LessonQueryResult = Apollo.QueryResult<LessonQuery, LessonQueryVariables>;
export const ResourceGroupDocument = gql`
    query ResourceGroup($id: ID!) {
  resourceGroup(id: $id) {
    ...ResourceGroupData
  }
}
    ${ResourceGroupDataFragmentDoc}`;

/**
 * __useResourceGroupQuery__
 *
 * To run a query within a React component, call `useResourceGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceGroupQuery(baseOptions: Apollo.QueryHookOptions<ResourceGroupQuery, ResourceGroupQueryVariables> & ({ variables: ResourceGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceGroupQuery, ResourceGroupQueryVariables>(ResourceGroupDocument, options);
      }
export function useResourceGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceGroupQuery, ResourceGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceGroupQuery, ResourceGroupQueryVariables>(ResourceGroupDocument, options);
        }
export function useResourceGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ResourceGroupQuery, ResourceGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ResourceGroupQuery, ResourceGroupQueryVariables>(ResourceGroupDocument, options);
        }
export type ResourceGroupQueryHookResult = ReturnType<typeof useResourceGroupQuery>;
export type ResourceGroupLazyQueryHookResult = ReturnType<typeof useResourceGroupLazyQuery>;
export type ResourceGroupSuspenseQueryHookResult = ReturnType<typeof useResourceGroupSuspenseQuery>;
export type ResourceGroupQueryResult = Apollo.QueryResult<ResourceGroupQuery, ResourceGroupQueryVariables>;
export const TestBookDocument = gql`
    query TestBook($id: ID!) {
  testBook(id: $id) {
    ...TestBookData
  }
}
    ${TestBookDataFragmentDoc}`;

/**
 * __useTestBookQuery__
 *
 * To run a query within a React component, call `useTestBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestBookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestBookQuery(baseOptions: Apollo.QueryHookOptions<TestBookQuery, TestBookQueryVariables> & ({ variables: TestBookQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestBookQuery, TestBookQueryVariables>(TestBookDocument, options);
      }
export function useTestBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestBookQuery, TestBookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestBookQuery, TestBookQueryVariables>(TestBookDocument, options);
        }
export function useTestBookSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestBookQuery, TestBookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestBookQuery, TestBookQueryVariables>(TestBookDocument, options);
        }
export type TestBookQueryHookResult = ReturnType<typeof useTestBookQuery>;
export type TestBookLazyQueryHookResult = ReturnType<typeof useTestBookLazyQuery>;
export type TestBookSuspenseQueryHookResult = ReturnType<typeof useTestBookSuspenseQuery>;
export type TestBookQueryResult = Apollo.QueryResult<TestBookQuery, TestBookQueryVariables>;
export const UserTestBooksDocument = gql`
    query UserTestBooks($userId: ID!) {
  testBooksAll {
    id
    title
    userHasBook(userId: $userId)
  }
}
    `;

/**
 * __useUserTestBooksQuery__
 *
 * To run a query within a React component, call `useUserTestBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTestBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTestBooksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTestBooksQuery(baseOptions: Apollo.QueryHookOptions<UserTestBooksQuery, UserTestBooksQueryVariables> & ({ variables: UserTestBooksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTestBooksQuery, UserTestBooksQueryVariables>(UserTestBooksDocument, options);
      }
export function useUserTestBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTestBooksQuery, UserTestBooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTestBooksQuery, UserTestBooksQueryVariables>(UserTestBooksDocument, options);
        }
export function useUserTestBooksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserTestBooksQuery, UserTestBooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTestBooksQuery, UserTestBooksQueryVariables>(UserTestBooksDocument, options);
        }
export type UserTestBooksQueryHookResult = ReturnType<typeof useUserTestBooksQuery>;
export type UserTestBooksLazyQueryHookResult = ReturnType<typeof useUserTestBooksLazyQuery>;
export type UserTestBooksSuspenseQueryHookResult = ReturnType<typeof useUserTestBooksSuspenseQuery>;
export type UserTestBooksQueryResult = Apollo.QueryResult<UserTestBooksQuery, UserTestBooksQueryVariables>;
export const TestDocument = gql`
    query Test($testId: ID!) {
  test(testId: $testId) {
    ...TestData
  }
}
    ${TestDataFragmentDoc}`;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *      testId: // value for 'testId'
 *   },
 * });
 */
export function useTestQuery(baseOptions: Apollo.QueryHookOptions<TestQuery, TestQueryVariables> & ({ variables: TestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestQuery, TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestQuery, TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestQuery, TestQueryVariables>(TestDocument, options);
        }
export function useTestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestQuery, TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestQuery, TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestSuspenseQueryHookResult = ReturnType<typeof useTestSuspenseQuery>;
export type TestQueryResult = Apollo.QueryResult<TestQuery, TestQueryVariables>;
export const UsersDocument = gql`
    query Users($orderBy: UserOrderByArg!, $filters: UserFiltersArg, $pagination: PaginationArg) {
  users(orderBy: $orderBy, filters: $filters, pagination: $pagination) {
    count
    records {
      ...UserData
      subscriptions(filter: {status: [Active, Trialing]}) {
        id
        title
        status
      }
    }
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> & ({ variables: UsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($userId: ID) {
  user(userId: $userId) {
    ...UserDataExtended
  }
}
    ${UserDataExtendedFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const PendingUserShipmentsDocument = gql`
    query PendingUserShipments {
  pendingUserShipments {
    email
    name
    address
    addressLine2
    city
    state
    zipCode
    country
    orderId
    orderItems
    ounces
    length
    width
    height
  }
}
    `;

/**
 * __usePendingUserShipmentsQuery__
 *
 * To run a query within a React component, call `usePendingUserShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingUserShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingUserShipmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingUserShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>(PendingUserShipmentsDocument, options);
      }
export function usePendingUserShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>(PendingUserShipmentsDocument, options);
        }
export function usePendingUserShipmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>(PendingUserShipmentsDocument, options);
        }
export type PendingUserShipmentsQueryHookResult = ReturnType<typeof usePendingUserShipmentsQuery>;
export type PendingUserShipmentsLazyQueryHookResult = ReturnType<typeof usePendingUserShipmentsLazyQuery>;
export type PendingUserShipmentsSuspenseQueryHookResult = ReturnType<typeof usePendingUserShipmentsSuspenseQuery>;
export type PendingUserShipmentsQueryResult = Apollo.QueryResult<PendingUserShipmentsQuery, PendingUserShipmentsQueryVariables>;
export const UserTestActivityDocument = gql`
    query UserTestActivity($userIds: [ID!]!, $startDate: String!, $endDate: String!) {
  userTestSnippet(userIds: $userIds, startDate: $startDate, endDate: $endDate) {
    email
    testTitle
    testTakenDate
    actTotalScore
    actEnglishScore
    actMathScore
    actReadingScore
    actScienceScore
    actEssayScore
    actEnglishOmitted
    actEnglishMissed
    actMathOmitted
    actMathMissed
    actReadingOmitted
    actReadingMissed
    actScienceOmitted
    actScienceMissed
    satTotalScore
    satReadingScore
    satWritingLanguageScore
    satMathScore
    satReadingOmitted
    satReadingMissed
    satWritingLanguageOmitted
    satWritingLanguageMissed
    satMathOmitted
    satMathMissed
    satMathCalcOmitted
    satMathCalcMissed
  }
}
    `;

/**
 * __useUserTestActivityQuery__
 *
 * To run a query within a React component, call `useUserTestActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTestActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTestActivityQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUserTestActivityQuery(baseOptions: Apollo.QueryHookOptions<UserTestActivityQuery, UserTestActivityQueryVariables> & ({ variables: UserTestActivityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTestActivityQuery, UserTestActivityQueryVariables>(UserTestActivityDocument, options);
      }
export function useUserTestActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTestActivityQuery, UserTestActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTestActivityQuery, UserTestActivityQueryVariables>(UserTestActivityDocument, options);
        }
export function useUserTestActivitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserTestActivityQuery, UserTestActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTestActivityQuery, UserTestActivityQueryVariables>(UserTestActivityDocument, options);
        }
export type UserTestActivityQueryHookResult = ReturnType<typeof useUserTestActivityQuery>;
export type UserTestActivityLazyQueryHookResult = ReturnType<typeof useUserTestActivityLazyQuery>;
export type UserTestActivitySuspenseQueryHookResult = ReturnType<typeof useUserTestActivitySuspenseQuery>;
export type UserTestActivityQueryResult = Apollo.QueryResult<UserTestActivityQuery, UserTestActivityQueryVariables>;
export const UserTestDSatActivityDocument = gql`
    query UserTestDSatActivity($userIds: [ID!]!, $startDate: String!, $endDate: String!) {
  userTestDSatSnippet(userIds: $userIds, startDate: $startDate, endDate: $endDate) {
    email
    testTitle
    testTakenDate
    digitalSatSection1ReadingAndWritingScore
    digitalSatSection2EasyReadingAndWritingScore
    digitalSatSection2HardReadingAndWritingScore
    digitalSatSection1MathScore
    digitalSatSection2EasyMathScore
    digitalSatSection2HardMathScore
    digitalSatSection1ReadingAndWritingMissed
    digitalSatSection1ReadingAndWritingOmmited
    digitalSatSection2EasyReadingAndWritingMissed
    digitalSatSection2EasyReadingAndWritingOmmited
    digitalSatSection2HardReadingAndWritingMissed
    digitalSatSection2HardReadingAndWritingOmmited
    digitalSatSection1MathMissed
    digitalSatSection1MathOmmited
    digitalSatSection2EasyMathMissed
    digitalSatSection2EasyMathOmmited
    digitalSatSection2HardMathMissed
    digitalSatSection2HardMathOmmited
    bluebookReadingAndWritingScore
    bluebookMathScore
    bluebookSection1ReadingAndWritingMissed
    bluebookSection1ReadingAndWritingOmmited
    bluebookSection2EasyReadingAndWritingMissed
    bluebookSection2EasyReadingAndWritingOmmited
    bluebookSection2HardReadingAndWritingMissed
    bluebookSection2HardReadingAndWritingOmmited
    bluebookSection1MathMissed
    bluebookSection1MathOmmited
    bluebookSection2EasyMathMissed
    bluebookSection2EasyMathOmmited
    bluebookSection2HardMathMissed
    bluebookSection2HardMathOmmited
  }
}
    `;

/**
 * __useUserTestDSatActivityQuery__
 *
 * To run a query within a React component, call `useUserTestDSatActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTestDSatActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTestDSatActivityQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUserTestDSatActivityQuery(baseOptions: Apollo.QueryHookOptions<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables> & ({ variables: UserTestDSatActivityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables>(UserTestDSatActivityDocument, options);
      }
export function useUserTestDSatActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables>(UserTestDSatActivityDocument, options);
        }
export function useUserTestDSatActivitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables>(UserTestDSatActivityDocument, options);
        }
export type UserTestDSatActivityQueryHookResult = ReturnType<typeof useUserTestDSatActivityQuery>;
export type UserTestDSatActivityLazyQueryHookResult = ReturnType<typeof useUserTestDSatActivityLazyQuery>;
export type UserTestDSatActivitySuspenseQueryHookResult = ReturnType<typeof useUserTestDSatActivitySuspenseQuery>;
export type UserTestDSatActivityQueryResult = Apollo.QueryResult<UserTestDSatActivityQuery, UserTestDSatActivityQueryVariables>;
export const FaqDocument = gql`
    query FAQ {
  FAQ {
    id
    question
    answer
    order
    status
    type
  }
}
    `;

/**
 * __useFaqQuery__
 *
 * To run a query within a React component, call `useFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqQuery({
 *   variables: {
 *   },
 * });
 */
export function useFaqQuery(baseOptions?: Apollo.QueryHookOptions<FaqQuery, FaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
      }
export function useFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqQuery, FaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
        }
export function useFaqSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FaqQuery, FaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
        }
export type FaqQueryHookResult = ReturnType<typeof useFaqQuery>;
export type FaqLazyQueryHookResult = ReturnType<typeof useFaqLazyQuery>;
export type FaqSuspenseQueryHookResult = ReturnType<typeof useFaqSuspenseQuery>;
export type FaqQueryResult = Apollo.QueryResult<FaqQuery, FaqQueryVariables>;
export const TotalActiveQuestionsDocument = gql`
    query totalActiveQuestions($subjectId: ID!, $testId: ID!) {
  totalActiveQuestions(subjectId: $subjectId, testId: $testId) {
    id
  }
}
    `;

/**
 * __useTotalActiveQuestionsQuery__
 *
 * To run a query within a React component, call `useTotalActiveQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalActiveQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalActiveQuestionsQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *      testId: // value for 'testId'
 *   },
 * });
 */
export function useTotalActiveQuestionsQuery(baseOptions: Apollo.QueryHookOptions<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables> & ({ variables: TotalActiveQuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables>(TotalActiveQuestionsDocument, options);
      }
export function useTotalActiveQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables>(TotalActiveQuestionsDocument, options);
        }
export function useTotalActiveQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables>(TotalActiveQuestionsDocument, options);
        }
export type TotalActiveQuestionsQueryHookResult = ReturnType<typeof useTotalActiveQuestionsQuery>;
export type TotalActiveQuestionsLazyQueryHookResult = ReturnType<typeof useTotalActiveQuestionsLazyQuery>;
export type TotalActiveQuestionsSuspenseQueryHookResult = ReturnType<typeof useTotalActiveQuestionsSuspenseQuery>;
export type TotalActiveQuestionsQueryResult = Apollo.QueryResult<TotalActiveQuestionsQuery, TotalActiveQuestionsQueryVariables>;
export const DigitalSatFeedbackDocument = gql`
    query digitalSatFeedback($pagination: PaginationArg!) {
  digitalSatFeedback(pagination: $pagination) {
    records {
      examId
      text
      description
      createdAt
      test {
        title
        testTitle
        testBookTitle
      }
      user {
        email
        firstName
        lastName
      }
    }
    count
  }
}
    `;

/**
 * __useDigitalSatFeedbackQuery__
 *
 * To run a query within a React component, call `useDigitalSatFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalSatFeedbackQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDigitalSatFeedbackQuery(baseOptions: Apollo.QueryHookOptions<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables> & ({ variables: DigitalSatFeedbackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables>(DigitalSatFeedbackDocument, options);
      }
export function useDigitalSatFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables>(DigitalSatFeedbackDocument, options);
        }
export function useDigitalSatFeedbackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables>(DigitalSatFeedbackDocument, options);
        }
export type DigitalSatFeedbackQueryHookResult = ReturnType<typeof useDigitalSatFeedbackQuery>;
export type DigitalSatFeedbackLazyQueryHookResult = ReturnType<typeof useDigitalSatFeedbackLazyQuery>;
export type DigitalSatFeedbackSuspenseQueryHookResult = ReturnType<typeof useDigitalSatFeedbackSuspenseQuery>;
export type DigitalSatFeedbackQueryResult = Apollo.QueryResult<DigitalSatFeedbackQuery, DigitalSatFeedbackQueryVariables>;
export const DigitalSatPracticeSetDocument = gql`
    query digitalSatPracticeSet($videoId: ID!, $lessonId: ID!) {
  digitalSatPracticeSet(videoId: $videoId, lessonId: $lessonId) {
    allQuestions {
      id
      question
      answer
      questionType
      optionA
      optionB
      optionC
      optionD
      difficultyLevel
      explanation
      isPublished
      vimeoId
      serialNumber
      order
      referenceDetails
      tags {
        ...TagData
      }
    }
    practiceSettitle
    subjectId
    subjectTitle
    lessonTitle
    playlistId
    playlistTitle
    difficultyLevel
  }
}
    ${TagDataFragmentDoc}`;

/**
 * __useDigitalSatPracticeSetQuery__
 *
 * To run a query within a React component, call `useDigitalSatPracticeSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatPracticeSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalSatPracticeSetQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useDigitalSatPracticeSetQuery(baseOptions: Apollo.QueryHookOptions<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables> & ({ variables: DigitalSatPracticeSetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables>(DigitalSatPracticeSetDocument, options);
      }
export function useDigitalSatPracticeSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables>(DigitalSatPracticeSetDocument, options);
        }
export function useDigitalSatPracticeSetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables>(DigitalSatPracticeSetDocument, options);
        }
export type DigitalSatPracticeSetQueryHookResult = ReturnType<typeof useDigitalSatPracticeSetQuery>;
export type DigitalSatPracticeSetLazyQueryHookResult = ReturnType<typeof useDigitalSatPracticeSetLazyQuery>;
export type DigitalSatPracticeSetSuspenseQueryHookResult = ReturnType<typeof useDigitalSatPracticeSetSuspenseQuery>;
export type DigitalSatPracticeSetQueryResult = Apollo.QueryResult<DigitalSatPracticeSetQuery, DigitalSatPracticeSetQueryVariables>;
export const CriteriaManuallyEntryDocument = gql`
    query criteriaManuallyEntry($testId: ID!, $testBookId: ID!) {
  criteriaManuallyEntry(testId: $testId, testBookId: $testBookId) {
    questionNumber
    isQuestionFor
    answerKey
    maxScore
    dsatMaxScore
    sectionQuestion
  }
}
    `;

/**
 * __useCriteriaManuallyEntryQuery__
 *
 * To run a query within a React component, call `useCriteriaManuallyEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCriteriaManuallyEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCriteriaManuallyEntryQuery({
 *   variables: {
 *      testId: // value for 'testId'
 *      testBookId: // value for 'testBookId'
 *   },
 * });
 */
export function useCriteriaManuallyEntryQuery(baseOptions: Apollo.QueryHookOptions<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables> & ({ variables: CriteriaManuallyEntryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables>(CriteriaManuallyEntryDocument, options);
      }
export function useCriteriaManuallyEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables>(CriteriaManuallyEntryDocument, options);
        }
export function useCriteriaManuallyEntrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables>(CriteriaManuallyEntryDocument, options);
        }
export type CriteriaManuallyEntryQueryHookResult = ReturnType<typeof useCriteriaManuallyEntryQuery>;
export type CriteriaManuallyEntryLazyQueryHookResult = ReturnType<typeof useCriteriaManuallyEntryLazyQuery>;
export type CriteriaManuallyEntrySuspenseQueryHookResult = ReturnType<typeof useCriteriaManuallyEntrySuspenseQuery>;
export type CriteriaManuallyEntryQueryResult = Apollo.QueryResult<CriteriaManuallyEntryQuery, CriteriaManuallyEntryQueryVariables>;
export const QuestionBankDocument = gql`
    query questionBank($orderBy: QuestionBankOrderByArg!, $filters: QuestionBankFiltersArg, $pagination: PaginationArg, $subject: String!) {
  questionBank(
    orderBy: $orderBy
    filters: $filters
    pagination: $pagination
    subject: $subject
  ) {
    count
    records {
      ...QuestionBankData
      practiceSetQuestion {
        videoId
        lessonId
      }
    }
  }
}
    ${QuestionBankDataFragmentDoc}`;

/**
 * __useQuestionBankQuery__
 *
 * To run a query within a React component, call `useQuestionBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionBankQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useQuestionBankQuery(baseOptions: Apollo.QueryHookOptions<QuestionBankQuery, QuestionBankQueryVariables> & ({ variables: QuestionBankQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionBankQuery, QuestionBankQueryVariables>(QuestionBankDocument, options);
      }
export function useQuestionBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionBankQuery, QuestionBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionBankQuery, QuestionBankQueryVariables>(QuestionBankDocument, options);
        }
export function useQuestionBankSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuestionBankQuery, QuestionBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionBankQuery, QuestionBankQueryVariables>(QuestionBankDocument, options);
        }
export type QuestionBankQueryHookResult = ReturnType<typeof useQuestionBankQuery>;
export type QuestionBankLazyQueryHookResult = ReturnType<typeof useQuestionBankLazyQuery>;
export type QuestionBankSuspenseQueryHookResult = ReturnType<typeof useQuestionBankSuspenseQuery>;
export type QuestionBankQueryResult = Apollo.QueryResult<QuestionBankQuery, QuestionBankQueryVariables>;
export const AllQuestionBankDocument = gql`
    query allQuestionBank {
  allQuestionBank {
    ...QuestionBankData
  }
}
    ${QuestionBankDataFragmentDoc}`;

/**
 * __useAllQuestionBankQuery__
 *
 * To run a query within a React component, call `useAllQuestionBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQuestionBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQuestionBankQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllQuestionBankQuery(baseOptions?: Apollo.QueryHookOptions<AllQuestionBankQuery, AllQuestionBankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllQuestionBankQuery, AllQuestionBankQueryVariables>(AllQuestionBankDocument, options);
      }
export function useAllQuestionBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllQuestionBankQuery, AllQuestionBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllQuestionBankQuery, AllQuestionBankQueryVariables>(AllQuestionBankDocument, options);
        }
export function useAllQuestionBankSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllQuestionBankQuery, AllQuestionBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllQuestionBankQuery, AllQuestionBankQueryVariables>(AllQuestionBankDocument, options);
        }
export type AllQuestionBankQueryHookResult = ReturnType<typeof useAllQuestionBankQuery>;
export type AllQuestionBankLazyQueryHookResult = ReturnType<typeof useAllQuestionBankLazyQuery>;
export type AllQuestionBankSuspenseQueryHookResult = ReturnType<typeof useAllQuestionBankSuspenseQuery>;
export type AllQuestionBankQueryResult = Apollo.QueryResult<AllQuestionBankQuery, AllQuestionBankQueryVariables>;
export const PlaylistsDocument = gql`
    query playlists($subjectId: ID) {
  playlists(subjectId: $subjectId) {
    ...PlaylistData
  }
}
    ${PlaylistDataFragmentDoc}`;

/**
 * __usePlaylistsQuery__
 *
 * To run a query within a React component, call `usePlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistsQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function usePlaylistsQuery(baseOptions?: Apollo.QueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
      }
export function usePlaylistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
        }
export function usePlaylistsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
        }
export type PlaylistsQueryHookResult = ReturnType<typeof usePlaylistsQuery>;
export type PlaylistsLazyQueryHookResult = ReturnType<typeof usePlaylistsLazyQuery>;
export type PlaylistsSuspenseQueryHookResult = ReturnType<typeof usePlaylistsSuspenseQuery>;
export type PlaylistsQueryResult = Apollo.QueryResult<PlaylistsQuery, PlaylistsQueryVariables>;
export const QuestionCountDocument = gql`
    query questionCount($id: ID) {
  questionCount(id: $id) {
    count
  }
}
    `;

/**
 * __useQuestionCountQuery__
 *
 * To run a query within a React component, call `useQuestionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionCountQuery(baseOptions?: Apollo.QueryHookOptions<QuestionCountQuery, QuestionCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionCountQuery, QuestionCountQueryVariables>(QuestionCountDocument, options);
      }
export function useQuestionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionCountQuery, QuestionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionCountQuery, QuestionCountQueryVariables>(QuestionCountDocument, options);
        }
export function useQuestionCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuestionCountQuery, QuestionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionCountQuery, QuestionCountQueryVariables>(QuestionCountDocument, options);
        }
export type QuestionCountQueryHookResult = ReturnType<typeof useQuestionCountQuery>;
export type QuestionCountLazyQueryHookResult = ReturnType<typeof useQuestionCountLazyQuery>;
export type QuestionCountSuspenseQueryHookResult = ReturnType<typeof useQuestionCountSuspenseQuery>;
export type QuestionCountQueryResult = Apollo.QueryResult<QuestionCountQuery, QuestionCountQueryVariables>;
export const ClassesDocument = gql`
    query classes($pagination: PaginationArg) {
  classes(pagination: $pagination) {
    count
    records {
      id
      title
      description
      userCreatedBy {
        email
        firstName
        lastName
        id
      }
      classStudents {
        email
        firstName
        lastName
        id
        homework {
          id
          title
        }
      }
      classTutors {
        id
        email
        firstName
        lastName
        tutorHomeworks {
          title
          id
        }
        tutorQuizzes {
          title
          id
        }
      }
    }
  }
}
    `;

/**
 * __useClassesQuery__
 *
 * To run a query within a React component, call `useClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useClassesQuery(baseOptions?: Apollo.QueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
      }
export function useClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export function useClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export type ClassesQueryHookResult = ReturnType<typeof useClassesQuery>;
export type ClassesLazyQueryHookResult = ReturnType<typeof useClassesLazyQuery>;
export type ClassesSuspenseQueryHookResult = ReturnType<typeof useClassesSuspenseQuery>;
export type ClassesQueryResult = Apollo.QueryResult<ClassesQuery, ClassesQueryVariables>;
export const TutorsDocument = gql`
    query tutors($orderBy: TutorOrderByArg!, $filters: TutorFiltersArg, $pagination: PaginationArg) {
  tutors(orderBy: $orderBy, filters: $filters, pagination: $pagination) {
    count
    records {
      id
      email
      firstName
      lastName
      createdAt
      role
      lastActiveAt
      tutorStudents {
        email
        firstName
        lastName
      }
      tutorClasses {
        id
        title
        homeworks {
          id
          title
        }
      }
      tutorHomeworks {
        title
        id
      }
      tutorQuizzes {
        title
        id
      }
      subscriptions(filter: {status: [Active, Trialing]}) {
        id
        title
        status
      }
    }
  }
}
    `;

/**
 * __useTutorsQuery__
 *
 * To run a query within a React component, call `useTutorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTutorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTutorsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTutorsQuery(baseOptions: Apollo.QueryHookOptions<TutorsQuery, TutorsQueryVariables> & ({ variables: TutorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TutorsQuery, TutorsQueryVariables>(TutorsDocument, options);
      }
export function useTutorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TutorsQuery, TutorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TutorsQuery, TutorsQueryVariables>(TutorsDocument, options);
        }
export function useTutorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TutorsQuery, TutorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TutorsQuery, TutorsQueryVariables>(TutorsDocument, options);
        }
export type TutorsQueryHookResult = ReturnType<typeof useTutorsQuery>;
export type TutorsLazyQueryHookResult = ReturnType<typeof useTutorsLazyQuery>;
export type TutorsSuspenseQueryHookResult = ReturnType<typeof useTutorsSuspenseQuery>;
export type TutorsQueryResult = Apollo.QueryResult<TutorsQuery, TutorsQueryVariables>;
export const StudentListDocument = gql`
    query studentList($pagination: PaginationArg) {
  studentList(pagination: $pagination) {
    count
    records {
      ...UserData
    }
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useStudentListQuery__
 *
 * To run a query within a React component, call `useStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useStudentListQuery(baseOptions?: Apollo.QueryHookOptions<StudentListQuery, StudentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentListQuery, StudentListQueryVariables>(StudentListDocument, options);
      }
export function useStudentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentListQuery, StudentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentListQuery, StudentListQueryVariables>(StudentListDocument, options);
        }
export function useStudentListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StudentListQuery, StudentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StudentListQuery, StudentListQueryVariables>(StudentListDocument, options);
        }
export type StudentListQueryHookResult = ReturnType<typeof useStudentListQuery>;
export type StudentListLazyQueryHookResult = ReturnType<typeof useStudentListLazyQuery>;
export type StudentListSuspenseQueryHookResult = ReturnType<typeof useStudentListSuspenseQuery>;
export type StudentListQueryResult = Apollo.QueryResult<StudentListQuery, StudentListQueryVariables>;
export const AllDigitalSatUsersDocument = gql`
    query allDigitalSatUsers {
  allDigitalSatUsers {
    id
    email
    firstName
    lastName
    lastActiveAt
    studentTutors {
      id
      email
    }
    studentClasses {
      id
      title
    }
  }
}
    `;

/**
 * __useAllDigitalSatUsersQuery__
 *
 * To run a query within a React component, call `useAllDigitalSatUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDigitalSatUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDigitalSatUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllDigitalSatUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>(AllDigitalSatUsersDocument, options);
      }
export function useAllDigitalSatUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>(AllDigitalSatUsersDocument, options);
        }
export function useAllDigitalSatUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>(AllDigitalSatUsersDocument, options);
        }
export type AllDigitalSatUsersQueryHookResult = ReturnType<typeof useAllDigitalSatUsersQuery>;
export type AllDigitalSatUsersLazyQueryHookResult = ReturnType<typeof useAllDigitalSatUsersLazyQuery>;
export type AllDigitalSatUsersSuspenseQueryHookResult = ReturnType<typeof useAllDigitalSatUsersSuspenseQuery>;
export type AllDigitalSatUsersQueryResult = Apollo.QueryResult<AllDigitalSatUsersQuery, AllDigitalSatUsersQueryVariables>;
export const HomeworksDocument = gql`
    query homeworks($pagination: PaginationArg, $filters: HomeworkFiltersArg, $status: Int!, $title: String, $days: String, $classId: Int) {
  homeworks(
    pagination: $pagination
    filters: $filters
    status: $status
    title: $title
    days: $days
    classId: $classId
  ) {
    count
    completedHomeworkCount
    pendingHomeworkCount
    records {
      description
      startDate
      dueDate
      title
      status
      id
      studentIds
      statusOfStudent
      classes {
        title
        id
        classStudents {
          firstName
          id
          email
          lastName
        }
      }
      students {
        firstName
        id
        email
        lastName
      }
      isUsed
    }
  }
}
    `;

/**
 * __useHomeworksQuery__
 *
 * To run a query within a React component, call `useHomeworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeworksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      status: // value for 'status'
 *      title: // value for 'title'
 *      days: // value for 'days'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useHomeworksQuery(baseOptions: Apollo.QueryHookOptions<HomeworksQuery, HomeworksQueryVariables> & ({ variables: HomeworksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeworksQuery, HomeworksQueryVariables>(HomeworksDocument, options);
      }
export function useHomeworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeworksQuery, HomeworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeworksQuery, HomeworksQueryVariables>(HomeworksDocument, options);
        }
export function useHomeworksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeworksQuery, HomeworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeworksQuery, HomeworksQueryVariables>(HomeworksDocument, options);
        }
export type HomeworksQueryHookResult = ReturnType<typeof useHomeworksQuery>;
export type HomeworksLazyQueryHookResult = ReturnType<typeof useHomeworksLazyQuery>;
export type HomeworksSuspenseQueryHookResult = ReturnType<typeof useHomeworksSuspenseQuery>;
export type HomeworksQueryResult = Apollo.QueryResult<HomeworksQuery, HomeworksQueryVariables>;
export const HomeworkUpdateDocument = gql`
    query homeworkUpdate($id: ID!) {
  homeworkDetails(id: $id) {
    isUsed
    status
    tests {
      testBookTitle
      testBookId
      tutorTest
    }
    bluebookTest {
      testBookTitle
      testBookId
      tutorTest
    }
    quizzes {
      reading {
        id
        title
      }
      math {
        id
        title
      }
    }
    assignments {
      answerExplanation
      assignments
      id
      answer
    }
    startDate
    dueDate
    description
    lessons {
      id
      title
      lessons
    }
    classes {
      title
      id
    }
    students {
      id
      email
      firstName
      lastName
    }
    title
    createdAt
    id
  }
}
    `;

/**
 * __useHomeworkUpdateQuery__
 *
 * To run a query within a React component, call `useHomeworkUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeworkUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeworkUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeworkUpdateQuery(baseOptions: Apollo.QueryHookOptions<HomeworkUpdateQuery, HomeworkUpdateQueryVariables> & ({ variables: HomeworkUpdateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeworkUpdateQuery, HomeworkUpdateQueryVariables>(HomeworkUpdateDocument, options);
      }
export function useHomeworkUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeworkUpdateQuery, HomeworkUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeworkUpdateQuery, HomeworkUpdateQueryVariables>(HomeworkUpdateDocument, options);
        }
export function useHomeworkUpdateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeworkUpdateQuery, HomeworkUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeworkUpdateQuery, HomeworkUpdateQueryVariables>(HomeworkUpdateDocument, options);
        }
export type HomeworkUpdateQueryHookResult = ReturnType<typeof useHomeworkUpdateQuery>;
export type HomeworkUpdateLazyQueryHookResult = ReturnType<typeof useHomeworkUpdateLazyQuery>;
export type HomeworkUpdateSuspenseQueryHookResult = ReturnType<typeof useHomeworkUpdateSuspenseQuery>;
export type HomeworkUpdateQueryResult = Apollo.QueryResult<HomeworkUpdateQuery, HomeworkUpdateQueryVariables>;
export const GetClassesDocument = gql`
    query getClasses($pagination: PaginationArg, $filters: ClassesFiltersArg) {
  classes(pagination: $pagination, filters: $filters) {
    count
    records {
      id
      title
    }
  }
}
    `;

/**
 * __useGetClassesQuery__
 *
 * To run a query within a React component, call `useGetClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetClassesQuery(baseOptions?: Apollo.QueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
      }
export function useGetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
        }
export function useGetClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
        }
export type GetClassesQueryHookResult = ReturnType<typeof useGetClassesQuery>;
export type GetClassesLazyQueryHookResult = ReturnType<typeof useGetClassesLazyQuery>;
export type GetClassesSuspenseQueryHookResult = ReturnType<typeof useGetClassesSuspenseQuery>;
export type GetClassesQueryResult = Apollo.QueryResult<GetClassesQuery, GetClassesQueryVariables>;
export const AllQuizDocument = gql`
    query allQuiz {
  allQuiz {
    allQuiz {
      id
      title
      subject
      status
      numberOfQuestion
      difficultyLevel
      instructions
      statusOfStudent
      tagedQuestions {
        tagTitle
        tagId
        question
      }
    }
    reading {
      id
      title
      subject
      status
      numberOfQuestion
      difficultyLevel
      instructions
      statusOfStudent
      tagedQuestions {
        tagTitle
        tagId
        question
      }
    }
    math {
      id
      title
      subject
      status
      numberOfQuestion
      difficultyLevel
      instructions
      statusOfStudent
      tagedQuestions {
        tagTitle
        tagId
        question
      }
    }
  }
}
    `;

/**
 * __useAllQuizQuery__
 *
 * To run a query within a React component, call `useAllQuizQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQuizQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQuizQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllQuizQuery(baseOptions?: Apollo.QueryHookOptions<AllQuizQuery, AllQuizQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllQuizQuery, AllQuizQueryVariables>(AllQuizDocument, options);
      }
export function useAllQuizLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllQuizQuery, AllQuizQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllQuizQuery, AllQuizQueryVariables>(AllQuizDocument, options);
        }
export function useAllQuizSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllQuizQuery, AllQuizQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllQuizQuery, AllQuizQueryVariables>(AllQuizDocument, options);
        }
export type AllQuizQueryHookResult = ReturnType<typeof useAllQuizQuery>;
export type AllQuizLazyQueryHookResult = ReturnType<typeof useAllQuizLazyQuery>;
export type AllQuizSuspenseQueryHookResult = ReturnType<typeof useAllQuizSuspenseQuery>;
export type AllQuizQueryResult = Apollo.QueryResult<AllQuizQuery, AllQuizQueryVariables>;
export const DigitalSatSectionDocument = gql`
    query DigitalSatSection($dbTestBooks: ID, $title: String!) {
  digitalSatSection(dbTestBooks: $dbTestBooks, title: $title) {
    testSections(title: $title) {
      testId
      courseId
      testTitle
      subjectTitle
      subjectId
      testBookId
    }
  }
}
    `;

/**
 * __useDigitalSatSectionQuery__
 *
 * To run a query within a React component, call `useDigitalSatSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalSatSectionQuery({
 *   variables: {
 *      dbTestBooks: // value for 'dbTestBooks'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useDigitalSatSectionQuery(baseOptions: Apollo.QueryHookOptions<DigitalSatSectionQuery, DigitalSatSectionQueryVariables> & ({ variables: DigitalSatSectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalSatSectionQuery, DigitalSatSectionQueryVariables>(DigitalSatSectionDocument, options);
      }
export function useDigitalSatSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalSatSectionQuery, DigitalSatSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalSatSectionQuery, DigitalSatSectionQueryVariables>(DigitalSatSectionDocument, options);
        }
export function useDigitalSatSectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DigitalSatSectionQuery, DigitalSatSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalSatSectionQuery, DigitalSatSectionQueryVariables>(DigitalSatSectionDocument, options);
        }
export type DigitalSatSectionQueryHookResult = ReturnType<typeof useDigitalSatSectionQuery>;
export type DigitalSatSectionLazyQueryHookResult = ReturnType<typeof useDigitalSatSectionLazyQuery>;
export type DigitalSatSectionSuspenseQueryHookResult = ReturnType<typeof useDigitalSatSectionSuspenseQuery>;
export type DigitalSatSectionQueryResult = Apollo.QueryResult<DigitalSatSectionQuery, DigitalSatSectionQueryVariables>;
export const DigitalSatTestBooksAllDocument = gql`
    query digitalSatTestBooksAll {
  digitalSatTestBooksAll {
    ...TestBookData
  }
}
    ${TestBookDataFragmentDoc}`;

/**
 * __useDigitalSatTestBooksAllQuery__
 *
 * To run a query within a React component, call `useDigitalSatTestBooksAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatTestBooksAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalSatTestBooksAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useDigitalSatTestBooksAllQuery(baseOptions?: Apollo.QueryHookOptions<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>(DigitalSatTestBooksAllDocument, options);
      }
export function useDigitalSatTestBooksAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>(DigitalSatTestBooksAllDocument, options);
        }
export function useDigitalSatTestBooksAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>(DigitalSatTestBooksAllDocument, options);
        }
export type DigitalSatTestBooksAllQueryHookResult = ReturnType<typeof useDigitalSatTestBooksAllQuery>;
export type DigitalSatTestBooksAllLazyQueryHookResult = ReturnType<typeof useDigitalSatTestBooksAllLazyQuery>;
export type DigitalSatTestBooksAllSuspenseQueryHookResult = ReturnType<typeof useDigitalSatTestBooksAllSuspenseQuery>;
export type DigitalSatTestBooksAllQueryResult = Apollo.QueryResult<DigitalSatTestBooksAllQuery, DigitalSatTestBooksAllQueryVariables>;
export const SubjectsDocument = gql`
    query Subjects($courseId: ID!) {
  subjects(courseId: $courseId) {
    ...SubjectData
  }
}
    ${SubjectDataFragmentDoc}`;

/**
 * __useSubjectsQuery__
 *
 * To run a query within a React component, call `useSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useSubjectsQuery(baseOptions: Apollo.QueryHookOptions<SubjectsQuery, SubjectsQueryVariables> & ({ variables: SubjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
      }
export function useSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectsQuery, SubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
        }
export function useSubjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubjectsQuery, SubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectsQuery, SubjectsQueryVariables>(SubjectsDocument, options);
        }
export type SubjectsQueryHookResult = ReturnType<typeof useSubjectsQuery>;
export type SubjectsLazyQueryHookResult = ReturnType<typeof useSubjectsLazyQuery>;
export type SubjectsSuspenseQueryHookResult = ReturnType<typeof useSubjectsSuspenseQuery>;
export type SubjectsQueryResult = Apollo.QueryResult<SubjectsQuery, SubjectsQueryVariables>;
export const LessonStatusDocument = gql`
    query lessonStatus($hid: Int!, $lessonId: Int!) {
  lessonStatus(hid: $hid, lessonId: $lessonId) {
    lessonStudents {
      id
      email
      firstName
      lastName
    }
    homeworkStatus
  }
}
    `;

/**
 * __useLessonStatusQuery__
 *
 * To run a query within a React component, call `useLessonStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonStatusQuery({
 *   variables: {
 *      hid: // value for 'hid'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useLessonStatusQuery(baseOptions: Apollo.QueryHookOptions<LessonStatusQuery, LessonStatusQueryVariables> & ({ variables: LessonStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonStatusQuery, LessonStatusQueryVariables>(LessonStatusDocument, options);
      }
export function useLessonStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonStatusQuery, LessonStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonStatusQuery, LessonStatusQueryVariables>(LessonStatusDocument, options);
        }
export function useLessonStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LessonStatusQuery, LessonStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LessonStatusQuery, LessonStatusQueryVariables>(LessonStatusDocument, options);
        }
export type LessonStatusQueryHookResult = ReturnType<typeof useLessonStatusQuery>;
export type LessonStatusLazyQueryHookResult = ReturnType<typeof useLessonStatusLazyQuery>;
export type LessonStatusSuspenseQueryHookResult = ReturnType<typeof useLessonStatusSuspenseQuery>;
export type LessonStatusQueryResult = Apollo.QueryResult<LessonStatusQuery, LessonStatusQueryVariables>;
export const QuizScoreDocument = gql`
    query quizScore($quizId: ID!, $homeworkId: Int!, $userId: ID!) {
  quizScore(quizId: $quizId, homeworkId: $homeworkId, userId: $userId) {
    questionReview
    updatedAt
    totalCorrectAnswer
    totalQuestion
    totalScore
  }
}
    `;

/**
 * __useQuizScoreQuery__
 *
 * To run a query within a React component, call `useQuizScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizScoreQuery({
 *   variables: {
 *      quizId: // value for 'quizId'
 *      homeworkId: // value for 'homeworkId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useQuizScoreQuery(baseOptions: Apollo.QueryHookOptions<QuizScoreQuery, QuizScoreQueryVariables> & ({ variables: QuizScoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizScoreQuery, QuizScoreQueryVariables>(QuizScoreDocument, options);
      }
export function useQuizScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizScoreQuery, QuizScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizScoreQuery, QuizScoreQueryVariables>(QuizScoreDocument, options);
        }
export function useQuizScoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuizScoreQuery, QuizScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuizScoreQuery, QuizScoreQueryVariables>(QuizScoreDocument, options);
        }
export type QuizScoreQueryHookResult = ReturnType<typeof useQuizScoreQuery>;
export type QuizScoreLazyQueryHookResult = ReturnType<typeof useQuizScoreLazyQuery>;
export type QuizScoreSuspenseQueryHookResult = ReturnType<typeof useQuizScoreSuspenseQuery>;
export type QuizScoreQueryResult = Apollo.QueryResult<QuizScoreQuery, QuizScoreQueryVariables>;
export const QuizDetailsDocument = gql`
    query quizDetails($id: ID!) {
  quizDetails(id: $id) {
    id
    all {
      id
      question
      answer
      optionA
      optionB
      optionC
      optionD
      correctResponse
      wrongResponse
      attemptedStudents
      optionAResponse
      optionBResponse
      optionCResponse
      optionDResponse
      explanation
      questionType
      referenceDetails
      questionLevel
    }
    totalResponse
    tags {
      ...TagData
    }
    createdAt
    instructions
    title
    subject
    students {
      id
      firstName
      lastName
      email
      totalCorrectAnswer
    }
  }
}
    ${TagDataFragmentDoc}`;

/**
 * __useQuizDetailsQuery__
 *
 * To run a query within a React component, call `useQuizDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuizDetailsQuery(baseOptions: Apollo.QueryHookOptions<QuizDetailsQuery, QuizDetailsQueryVariables> & ({ variables: QuizDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizDetailsQuery, QuizDetailsQueryVariables>(QuizDetailsDocument, options);
      }
export function useQuizDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizDetailsQuery, QuizDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizDetailsQuery, QuizDetailsQueryVariables>(QuizDetailsDocument, options);
        }
export function useQuizDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuizDetailsQuery, QuizDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuizDetailsQuery, QuizDetailsQueryVariables>(QuizDetailsDocument, options);
        }
export type QuizDetailsQueryHookResult = ReturnType<typeof useQuizDetailsQuery>;
export type QuizDetailsLazyQueryHookResult = ReturnType<typeof useQuizDetailsLazyQuery>;
export type QuizDetailsSuspenseQueryHookResult = ReturnType<typeof useQuizDetailsSuspenseQuery>;
export type QuizDetailsQueryResult = Apollo.QueryResult<QuizDetailsQuery, QuizDetailsQueryVariables>;
export const HomeworkDetailsDocument = gql`
    query homeworkDetails($id: ID!) {
  homeworkDetails(id: $id) {
    description
    totalResponse {
      id
      email
    }
    tests {
      testBookTitle
      testBookId
      tutorTest
    }
    bluebookTest {
      testBookTitle
      testBookId
      tutorTest
    }
    quizzes {
      reading {
        id
        title
        students {
          id
          email
          firstName
          lastName
        }
      }
      math {
        id
        title
        students {
          id
          email
          firstName
          lastName
        }
      }
    }
    assignments {
      answerExplanation
      assignments
      id
      answer
      homeworkStatus
      updatedAt
      user {
        firstName
        id
        email
        lastName
      }
    }
    startDate
    dueDate
    lessons {
      id
      title
      lessons
    }
    classes {
      title
      id
      description
      classStudents {
        firstName
        id
        email
        lastName
      }
    }
    students {
      id
      email
      firstName
      lastName
    }
    title
    createdAt
    id
  }
}
    `;

/**
 * __useHomeworkDetailsQuery__
 *
 * To run a query within a React component, call `useHomeworkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeworkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeworkDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeworkDetailsQuery(baseOptions: Apollo.QueryHookOptions<HomeworkDetailsQuery, HomeworkDetailsQueryVariables> & ({ variables: HomeworkDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeworkDetailsQuery, HomeworkDetailsQueryVariables>(HomeworkDetailsDocument, options);
      }
export function useHomeworkDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeworkDetailsQuery, HomeworkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeworkDetailsQuery, HomeworkDetailsQueryVariables>(HomeworkDetailsDocument, options);
        }
export function useHomeworkDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeworkDetailsQuery, HomeworkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeworkDetailsQuery, HomeworkDetailsQueryVariables>(HomeworkDetailsDocument, options);
        }
export type HomeworkDetailsQueryHookResult = ReturnType<typeof useHomeworkDetailsQuery>;
export type HomeworkDetailsLazyQueryHookResult = ReturnType<typeof useHomeworkDetailsLazyQuery>;
export type HomeworkDetailsSuspenseQueryHookResult = ReturnType<typeof useHomeworkDetailsSuspenseQuery>;
export type HomeworkDetailsQueryResult = Apollo.QueryResult<HomeworkDetailsQuery, HomeworkDetailsQueryVariables>;
export const TestStatusDocument = gql`
    query testStatus($hid: Int!, $testData: String!, $type: String!) {
  testStatus(hid: $hid, testData: $testData, type: $type) {
    email
    firstName
    lastName
    status
  }
}
    `;

/**
 * __useTestStatusQuery__
 *
 * To run a query within a React component, call `useTestStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestStatusQuery({
 *   variables: {
 *      hid: // value for 'hid'
 *      testData: // value for 'testData'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTestStatusQuery(baseOptions: Apollo.QueryHookOptions<TestStatusQuery, TestStatusQueryVariables> & ({ variables: TestStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestStatusQuery, TestStatusQueryVariables>(TestStatusDocument, options);
      }
export function useTestStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestStatusQuery, TestStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestStatusQuery, TestStatusQueryVariables>(TestStatusDocument, options);
        }
export function useTestStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestStatusQuery, TestStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestStatusQuery, TestStatusQueryVariables>(TestStatusDocument, options);
        }
export type TestStatusQueryHookResult = ReturnType<typeof useTestStatusQuery>;
export type TestStatusLazyQueryHookResult = ReturnType<typeof useTestStatusLazyQuery>;
export type TestStatusSuspenseQueryHookResult = ReturnType<typeof useTestStatusSuspenseQuery>;
export type TestStatusQueryResult = Apollo.QueryResult<TestStatusQuery, TestStatusQueryVariables>;
export const TutorHomeworkReportDocument = gql`
    query tutorHomeworkReport($homeworkId: ID!, $type: String!, $filters: HomeworkReportFiltersArg) {
  tutorHomeworkReport(homeworkId: $homeworkId, type: $type, filters: $filters) {
    record
  }
}
    `;

/**
 * __useTutorHomeworkReportQuery__
 *
 * To run a query within a React component, call `useTutorHomeworkReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTutorHomeworkReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTutorHomeworkReportQuery({
 *   variables: {
 *      homeworkId: // value for 'homeworkId'
 *      type: // value for 'type'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTutorHomeworkReportQuery(baseOptions: Apollo.QueryHookOptions<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables> & ({ variables: TutorHomeworkReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables>(TutorHomeworkReportDocument, options);
      }
export function useTutorHomeworkReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables>(TutorHomeworkReportDocument, options);
        }
export function useTutorHomeworkReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables>(TutorHomeworkReportDocument, options);
        }
export type TutorHomeworkReportQueryHookResult = ReturnType<typeof useTutorHomeworkReportQuery>;
export type TutorHomeworkReportLazyQueryHookResult = ReturnType<typeof useTutorHomeworkReportLazyQuery>;
export type TutorHomeworkReportSuspenseQueryHookResult = ReturnType<typeof useTutorHomeworkReportSuspenseQuery>;
export type TutorHomeworkReportQueryResult = Apollo.QueryResult<TutorHomeworkReportQuery, TutorHomeworkReportQueryVariables>;
export const StudentAnalyticsDocument = gql`
    query studentAnalytics($id: ID!, $userId: ID!) {
  studentAnalytics(id: $id, userId: $userId) {
    studentAnalytics {
      id
      question
      answer
      optionA
      optionB
      optionC
      optionD
      questionType
      explanation
      questionId
      studentAnswer
      studentResponses
      totalWrongAnswer
      totalCorrectAnswer
      difficultyLevel
    }
  }
}
    `;

/**
 * __useStudentAnalyticsQuery__
 *
 * To run a query within a React component, call `useStudentAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAnalyticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStudentAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<StudentAnalyticsQuery, StudentAnalyticsQueryVariables> & ({ variables: StudentAnalyticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentAnalyticsQuery, StudentAnalyticsQueryVariables>(StudentAnalyticsDocument, options);
      }
export function useStudentAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentAnalyticsQuery, StudentAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentAnalyticsQuery, StudentAnalyticsQueryVariables>(StudentAnalyticsDocument, options);
        }
export function useStudentAnalyticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StudentAnalyticsQuery, StudentAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StudentAnalyticsQuery, StudentAnalyticsQueryVariables>(StudentAnalyticsDocument, options);
        }
export type StudentAnalyticsQueryHookResult = ReturnType<typeof useStudentAnalyticsQuery>;
export type StudentAnalyticsLazyQueryHookResult = ReturnType<typeof useStudentAnalyticsLazyQuery>;
export type StudentAnalyticsSuspenseQueryHookResult = ReturnType<typeof useStudentAnalyticsSuspenseQuery>;
export type StudentAnalyticsQueryResult = Apollo.QueryResult<StudentAnalyticsQuery, StudentAnalyticsQueryVariables>;
export const DigitalSatUsersDocument = gql`
    query digitalSatUsers($orderBy: TutorOrderByArg!, $filters: TutorFiltersArg, $pagination: PaginationArg) {
  digitalSatUsers(orderBy: $orderBy, filters: $filters, pagination: $pagination) {
    count
    records {
      id
      email
      firstName
      lastName
      role
      lastActiveAt
      createdAt
      subscriptions(filter: {status: [Active, Trialing]}) {
        id
        title
        status
      }
      studentTutors {
        id
        email
      }
      studentClasses {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useDigitalSatUsersQuery__
 *
 * To run a query within a React component, call `useDigitalSatUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalSatUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalSatUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDigitalSatUsersQuery(baseOptions: Apollo.QueryHookOptions<DigitalSatUsersQuery, DigitalSatUsersQueryVariables> & ({ variables: DigitalSatUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalSatUsersQuery, DigitalSatUsersQueryVariables>(DigitalSatUsersDocument, options);
      }
export function useDigitalSatUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalSatUsersQuery, DigitalSatUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalSatUsersQuery, DigitalSatUsersQueryVariables>(DigitalSatUsersDocument, options);
        }
export function useDigitalSatUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DigitalSatUsersQuery, DigitalSatUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalSatUsersQuery, DigitalSatUsersQueryVariables>(DigitalSatUsersDocument, options);
        }
export type DigitalSatUsersQueryHookResult = ReturnType<typeof useDigitalSatUsersQuery>;
export type DigitalSatUsersLazyQueryHookResult = ReturnType<typeof useDigitalSatUsersLazyQuery>;
export type DigitalSatUsersSuspenseQueryHookResult = ReturnType<typeof useDigitalSatUsersSuspenseQuery>;
export type DigitalSatUsersQueryResult = Apollo.QueryResult<DigitalSatUsersQuery, DigitalSatUsersQueryVariables>;
export const TagCategoryDocument = gql`
    query tagCategory($pagination: PaginationArg, $subject: String!) {
  tagCategory(pagination: $pagination, subject: $subject) {
    count
    records {
      id
      name
      subject
      tags {
        ...TagData
      }
    }
  }
}
    ${TagDataFragmentDoc}`;

/**
 * __useTagCategoryQuery__
 *
 * To run a query within a React component, call `useTagCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoryQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useTagCategoryQuery(baseOptions: Apollo.QueryHookOptions<TagCategoryQuery, TagCategoryQueryVariables> & ({ variables: TagCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagCategoryQuery, TagCategoryQueryVariables>(TagCategoryDocument, options);
      }
export function useTagCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagCategoryQuery, TagCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagCategoryQuery, TagCategoryQueryVariables>(TagCategoryDocument, options);
        }
export function useTagCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagCategoryQuery, TagCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagCategoryQuery, TagCategoryQueryVariables>(TagCategoryDocument, options);
        }
export type TagCategoryQueryHookResult = ReturnType<typeof useTagCategoryQuery>;
export type TagCategoryLazyQueryHookResult = ReturnType<typeof useTagCategoryLazyQuery>;
export type TagCategorySuspenseQueryHookResult = ReturnType<typeof useTagCategorySuspenseQuery>;
export type TagCategoryQueryResult = Apollo.QueryResult<TagCategoryQuery, TagCategoryQueryVariables>;
export const PracticeSetDataDocument = gql`
    query practiceSetData($videoId: ID!, $lessonId: ID!, $type: String, $examId: Int) {
  practiceSetData(
    videoId: $videoId
    lessonId: $lessonId
    type: $type
    examId: $examId
  ) {
    currentQuestionNo
    totalQuestionNo
    currentQuestion
    allQuestion
  }
}
    `;

/**
 * __usePracticeSetDataQuery__
 *
 * To run a query within a React component, call `usePracticeSetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeSetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeSetDataQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      lessonId: // value for 'lessonId'
 *      type: // value for 'type'
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function usePracticeSetDataQuery(baseOptions: Apollo.QueryHookOptions<PracticeSetDataQuery, PracticeSetDataQueryVariables> & ({ variables: PracticeSetDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PracticeSetDataQuery, PracticeSetDataQueryVariables>(PracticeSetDataDocument, options);
      }
export function usePracticeSetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PracticeSetDataQuery, PracticeSetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PracticeSetDataQuery, PracticeSetDataQueryVariables>(PracticeSetDataDocument, options);
        }
export function usePracticeSetDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PracticeSetDataQuery, PracticeSetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PracticeSetDataQuery, PracticeSetDataQueryVariables>(PracticeSetDataDocument, options);
        }
export type PracticeSetDataQueryHookResult = ReturnType<typeof usePracticeSetDataQuery>;
export type PracticeSetDataLazyQueryHookResult = ReturnType<typeof usePracticeSetDataLazyQuery>;
export type PracticeSetDataSuspenseQueryHookResult = ReturnType<typeof usePracticeSetDataSuspenseQuery>;
export type PracticeSetDataQueryResult = Apollo.QueryResult<PracticeSetDataQuery, PracticeSetDataQueryVariables>;
export const StudentBookmarkLessonsDocument = gql`
    query studentBookmarkLessons($id: ID!) {
  studentBookmarkLessons(id: $id) {
    lessonMarkedIds
    videoUnderstoodIds
    lessonUnderstoodIds
  }
}
    `;

/**
 * __useStudentBookmarkLessonsQuery__
 *
 * To run a query within a React component, call `useStudentBookmarkLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentBookmarkLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentBookmarkLessonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentBookmarkLessonsQuery(baseOptions: Apollo.QueryHookOptions<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables> & ({ variables: StudentBookmarkLessonsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables>(StudentBookmarkLessonsDocument, options);
      }
export function useStudentBookmarkLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables>(StudentBookmarkLessonsDocument, options);
        }
export function useStudentBookmarkLessonsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables>(StudentBookmarkLessonsDocument, options);
        }
export type StudentBookmarkLessonsQueryHookResult = ReturnType<typeof useStudentBookmarkLessonsQuery>;
export type StudentBookmarkLessonsLazyQueryHookResult = ReturnType<typeof useStudentBookmarkLessonsLazyQuery>;
export type StudentBookmarkLessonsSuspenseQueryHookResult = ReturnType<typeof useStudentBookmarkLessonsSuspenseQuery>;
export type StudentBookmarkLessonsQueryResult = Apollo.QueryResult<StudentBookmarkLessonsQuery, StudentBookmarkLessonsQueryVariables>;
export const NotificationDocument = gql`
    query notification($pagination: PaginationArg) {
  notification(pagination: $pagination) {
    count
    records {
      id
      description
      endDate
      startDate
      status
      type
      title
      userIds
    }
  }
}
    `;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useNotificationQuery(baseOptions?: Apollo.QueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
      }
export function useNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
        }
export function useNotificationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
        }
export type NotificationQueryHookResult = ReturnType<typeof useNotificationQuery>;
export type NotificationLazyQueryHookResult = ReturnType<typeof useNotificationLazyQuery>;
export type NotificationSuspenseQueryHookResult = ReturnType<typeof useNotificationSuspenseQuery>;
export type NotificationQueryResult = Apollo.QueryResult<NotificationQuery, NotificationQueryVariables>;